import React from 'react';

type Props = {
  children?: React.ReactNode;
  classes?: ((pressed: boolean) => string) | string;
  style?: ((pressed: boolean) => React.CSSProperties) | React.CSSProperties;
  render?: (props: { pressed: boolean }) => React.ReactNode;
  type?: string;
  activeElementIndex?: string | number;
} & React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>;

const Pressable: React.FC<Props> = ({
  children,
  classes,
  style,
  render,
  ...rest
}) => {
  const [isPressed, setIsPressed] = React.useState(false);

  const onMouseDown = (e: any) => {
    setIsPressed(true);
  };
  const onMouseUp = (e: any) => {
    setIsPressed(false);
  };

  return (
    <div
      {...rest}
      className={`${
        typeof classes === 'function' ? classes(isPressed) : classes
      } ${isPressed ? 'pressed' : ''}`}
      onMouseLeave={onMouseUp}
      onMouseUpCapture={onMouseUp}
      onMouseDownCapture={onMouseDown}
      style={typeof style === 'function' ? style(isPressed) : style}
    >
      {render ? render({ pressed: isPressed }) : children}
    </div>
  );
};

export default Pressable;
