import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';

import { useForm } from 'react-hook-form';
import { ModalCloseButton } from 'components/common/Buttons';
import classNames from 'classnames/bind';

import styles from './style.module.scss';

import { useNavigate } from 'react-router-dom';
import { useGetModalDataQuery } from 'store/modals/api';
import CountdownTimer from '@components/CountdownTimer';
import { deleteAllComparisonCar } from '@api/comparisons';
import { useDispatch, useSelector } from 'react-redux';
import { selectedDealer, selectedZone } from '@store/dealers/slice';
import { setNewCarsModels } from '@store/newCars/slice';
import { resetFilters } from '@store/usedCars/slice';
import { initGTMforButtons } from '@utils/initGTMforButtons';
const cx = classNames.bind(styles);

type ConsultationModalProps = {
  closeModal: () => void;
};

const TimeoutModal = ({ closeModal }: ConsultationModalProps) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const dealer = useSelector(selectedDealer);
  // const sessionId = useSelector(selectedZone);
  const sessionId = localStorage.getItem('user_session_id') || '';

  const navigate = useNavigate();

  const { isLoading, data: timeoutModalData } = useGetModalDataQuery({});

  const answerAction = (answer: string) => {
    switch (answer) {
      case 'no': {
        deleteAllComparisonCar(sessionId, dealer)
          .then((res: any) => {
            if (res && res.status === 200) {
              dispatch(resetFilters());
              dispatch(setNewCarsModels(null));
            }
          })
          .catch((error) => {
            console.log('error: ', error);
          });
        if (/MSIE \d|Trident.*rv:/.test(navigator.userAgent)) {
          // @ts-ignore
          window.location = 'microsoft-edge:' + window.location;
          setTimeout(function () {
            // @ts-ignore
            window.location = '/';
          }, 1);
        } else {
          navigate(`/`);
        }

        closeModal();
        break;
      }
      case 'yes': {
        closeModal();
      }
    }
  };

  const startVideo = () => {
    navigate(`/`);
    deleteAllComparisonCar(sessionId, dealer)
      .then((res: any) => {
        if (res && res.status === 200) {
          dispatch(resetFilters());
          dispatch(setNewCarsModels(null));
        }
      })
      .catch((error) => {
        console.log('error: ', error);
      });
    closeModal();
  };

  return (
    <Box className={cx('modal')}>
      <ModalCloseButton onClick={() => closeModal()} />
      {!isLoading ? (
        <div className={cx('modal__content')}>
          <div className={cx('modal__title')}>
            {timeoutModalData.data[0].timer_settings.time_left_text}
          </div>
          <div className={cx('modal__timer')}>
            <CountdownTimer
              onTimeout={() => startVideo()}
              minutes={0}
              seconds={60}
            />
          </div>
          <div className={cx('modal__timer-descr')}>
            {timeoutModalData.data[0].timer_settings.question_text}
          </div>
          <div className={cx('modal__buttonWrap')}>
            <button
              type="submit"
              className={cx('btn', 'transparent')}
              disabled={loading}
              onClick={() => {
                initGTMforButtons({
                  eventType: 'session_end',
                });
                answerAction('no');
              }}
            >
              {timeoutModalData.data[0].timer_settings.cancel_button_text}
            </button>
            <button
              type="submit"
              className={cx('btn')}
              disabled={loading}
              onClick={() => {
                initGTMforButtons({
                  eventType: 'custom_click',
                  click_text: `${timeoutModalData.data[0].timer_settings.proceed_button_text}`,
                });
                answerAction('yes');
              }}
            >
              {timeoutModalData.data[0].timer_settings.proceed_button_text}
            </button>
          </div>
        </div>
      ) : (
        ''
      )}
    </Box>
  );
};

export default TimeoutModal;
