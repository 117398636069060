import React from "react";
import CloseIcon from "../../../assets/svg/CloseIcon";
import "./ModalCloseButton.scss";

type Props = {
  onClick: VoidFunction;
  classes?: string;
};

export const ModalCloseButton: React.FC<Props> = ({
  onClick,
  classes = "",
}) => {
  return (
    <div className={`modal-close-button ${classes}`} onClick={onClick}>
      <CloseIcon />
    </div>
  );
};
