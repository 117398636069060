import { BaseResponse } from '../../types';
import { api } from '../api';
import { API_CONFIG } from '../../constants';
import {
  GetCarsModelsForServiceResponse,
  GetYearsCarsResponse,
  GetEngineResponse,
  GetEngineRequest,
} from './types';

const getCarsListForServiceApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getCarsForService: builder.query<
      BaseResponse<GetCarsModelsForServiceResponse>,
      {}
    >({
      query: () => ({
        url: `${API_CONFIG.SERVICE_URL}/model`,
      }),
    }),
    getYears: builder.query<BaseResponse<GetYearsCarsResponse>, {}>({
      query: (modelId: any) => ({
        url: `${API_CONFIG.SERVICE_URL}/year?carModelId=${modelId}`,
      }),
    }),
    getEngineList: builder.query<
      BaseResponse<GetEngineResponse>,
      GetEngineRequest
    >({
      //@ ts-ignore
      query: ({ generationId, filter }) => {
        //@ ts-ignore
        let paramsString = new URLSearchParams();

        if (filter) {
          paramsString = new URLSearchParams(filter);
        }
        return {
          url: `${API_CONFIG.SERVICE_URL}/modification?generationId=${generationId}&${paramsString}`,
        };
      },
    }),
  }),
});

export const {
  useGetCarsForServiceQuery,
  useGetYearsQuery,
  useGetEngineListQuery,
} = getCarsListForServiceApi;

export default getCarsListForServiceApi;
