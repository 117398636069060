import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';

import FormInput from 'components/FormInput';
import { useForm } from 'react-hook-form';
import CheckBoxInput from 'components/CheckBoxInput';
import Select from 'components/Select';
import PhoneInput from '@components/PhoneInput';
import { ModalCloseButton } from 'components/common/Buttons';
import classNames from 'classnames/bind';

import styles from './style.module.scss';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  selectedDealer,
  selectedScreen,
  selectedZone,
  selectedZoneId,
} from '@store/dealers/slice';
import { sendConsultationRequest } from '@api/consultation';
import { sendRateCarRequest } from '@api/rateCar';
import { ScreenTitle } from '@components';
import { useGetScreenDataQuery } from '@store/screens/api';
const cx = classNames.bind(styles);
import { initGTMforButtons } from '@utils/initGTMforButtons';

const RateMyCarModal = ({ closeModal }) => {
  const [successfulStatus, setSuccessfulStatus] = useState(false);
  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm({
    reValidateMode: 'onChange',
  });

  const params = useParams();
  const [loading, setLoading] = useState(false);
  const [showPrivacy, setShowPrivacy] = useState(false);
  const [showMarketingInfo, setShowMarketingInfo] = useState(false);

  const zoneId = useSelector(selectedZoneId);
  const dealerCode = useSelector(selectedDealer);
  const screenId = useSelector(selectedScreen);
  const zona = useSelector(selectedZone);

  const { isLoading: isLoadingScreenData, data: screenDataResponse } =
    useGetScreenDataQuery({
      dealer_dealer_code: dealerCode,
      template: 'privacy_police',
      zona: zona,
    });

  const { isLoading: isMarketingInfo, data: marketingInfoDataResponse } =
    useGetScreenDataQuery({
      dealer_dealer_code: dealerCode,
      template: 'marketing_information',
      zona: zona,
    });

  const onSubmit = (data) => {
    const checkboxes = Object.entries(data.checkboxes).map(([key, value]) => ({
      name: key,
      value: value,
    }));

    let sendData = {
      ...data,
      zone_id: zoneId,
      screen_id: screenId,
      checkboxes: checkboxes,
    };
    setLoading(true);

    sendRateCarRequest(sendData, dealerCode)
      .then((res) => {
        if (res && res.status === 201) {
          setSuccessfulStatus(true);
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    reset();
  }, [closeModal]);

  return (
    <Box className={cx('modal')}>
      <ModalCloseButton onClick={() => closeModal()} />
      <div className={cx('modal__content')}>
        {!successfulStatus ? (
          <>
            <div className={cx('modal__title')}>Оцініть мій автомобіль!</div>
            <form className={cx('form')} onSubmit={handleSubmit(onSubmit)}>
              <div className={cx('form__line')}>
                <FormInput
                  control={control}
                  label="Укажіть ваше ім'я"
                  name="client_name"
                  rules={{
                    required: "Обов'язкове поле",
                  }}
                  errors={errors.name}
                />
              </div>
              <div className={cx('form__line')}>
                <PhoneInput
                  control={control}
                  label="Номер телефону"
                  name="client_phone"
                  rules={{
                    required: "Обов'язкове поле",
                  }}
                  errors={errors.phone}
                />
              </div>
              <div className={cx('form__line', 'borderType_1')}>
                {modalDataResponce?.data.map((el, index) => (
                  <div style={{ marginBottom: 16 }}>
                    <CheckBoxInput
                      control={control}
                      label={`${el.text}`}
                      labelLinkText={`${el.link_text}`}
                      labelLinkAction={() => {
                        if (el.link_template === 'privacy_police') {
                          handleShowPrivacy();
                        } else if (
                          el.link_template === 'marketing_information'
                        ) {
                          setShowMarketingInfo(true);
                        }
                      }}
                      hasLink={el.has_link}
                      name={`checkboxes.${el.name}`}
                      rules={
                        el.required
                          ? {
                              required: "Обов'язкове поле",
                            }
                          : {}
                      }
                      //@ts-ignore
                      errors={errors.checkboxes?.[el.name]}
                    />
                  </div>
                ))}
              </div>
              <div className={cx('form__line', 'buttonWrap')}>
                <button type="submit" className={cx('submitButton')}>
                  Надіслати заявку
                </button>
              </div>
            </form>
          </>
        ) : (
          <div className={cx('successful')}>
            <div className={cx('successful__text')}>
              Заявку надіслано. <br /> Дякуємо за ваше звернення.
            </div>
            <div className={cx('successful__actionBtn')}>
              <button
                className={cx('btn')}
                onClick={() => {
                  initGTMforButtons({
                    eventType: 'custom_click',
                    click_text: `Повернутися на головний екран`,
                  });
                  closeModal();
                }}
              >
                Повернутися на головний екран
              </button>
            </div>
          </div>
        )}
        {showPrivacy && (
          <div className={cx('privacyContent')}>
            <ScreenTitle
              blackText={isLoading ? '' : screenData?.title}
              onBack={() => setShowPrivacy(false)}
            />
            <div
              className={cx('privacyContent__text')}
              dangerouslySetInnerHTML={{
                __html: screenData?.content?.description || '',
              }}
            ></div>
          </div>
        )}
        {showMarketingInfo && (
          <div className={cx('privacyContent')}>
            <ScreenTitle
              blackText={isLoading ? '' : marketingInfoDataResponse?.data.title}
              onBack={() => setShowMarketingInfo(false)}
            />
            <div
              className={cx('privacyContent__text')}
              dangerouslySetInnerHTML={{
                __html:
                  marketingInfoDataResponse?.data.content?.description || '',
              }}
            ></div>
          </div>
        )}
      </div>
    </Box>
  );
};

export default RateMyCarModal;
