import * as React from 'react';

const ToyotaLogo = (props) => (
  <svg
    width={86}
    height={56}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 86 56"
    {...props}
  >
    <path
      d="M42.999 0C19.25 0 0 12.537 0 28.001 0 43.466 19.25 56 42.999 56 66.747 56 86 43.463 86 28.001 86 12.54 66.747 0 42.999 0Zm0 43.98c-3.533 0-6.414-7.001-6.598-15.814 2.127.194 4.348.295 6.598.295s4.47-.101 6.6-.295c-.187 8.813-3.07 15.814-6.6 15.814ZM36.84 21.175c.962-6.23 3.356-10.644 6.158-10.644 2.801 0 5.198 4.414 6.16 10.644-1.97.178-4.033.273-6.16.273-2.128 0-4.188-.106-6.158-.273Zm6.158-17.12c-4.62 0-8.544 6.95-9.995 16.646-8.745-1.368-14.851-4.498-14.851-8.131 0-4.909 11.125-8.876 24.846-8.876 13.72 0 24.845 3.967 24.845 8.876 0 3.633-6.103 6.755-14.848 8.131-1.45-9.696-5.38-16.646-9.997-16.646ZM6.166 26.95c0-4.748 1.813-9.194 4.973-13.019a6.207 6.207 0 0 0-.054.821c0 5.981 8.907 11.07 21.332 12.945v1.313c0 11.141 3.103 20.576 7.386 23.777C20.949 51.633 6.166 40.495 6.166 26.95Zm40.031 25.826C50.483 49.576 53.586 40.14 53.586 29v-1.313c12.424-1.875 21.332-6.964 21.332-12.945 0-.275-.018-.549-.057-.82 3.163 3.83 4.976 8.27 4.976 13.018-.003 13.555-14.789 24.693-33.64 25.837Z"
      fill="#282830"
    />
  </svg>
);

export default ToyotaLogo;
