import * as React from 'react';

const Calculator = (props) => (
  <svg
    width="40"
    height="40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox=" 0 0 80 80"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 17.165a3.5 3.5 0 0 0-3.5 3.5v41.474a3.5 3.5 0 0 0 3.5 3.5h32a3.5 3.5 0 0 0 3.5-3.5v-3.474a1.5 1.5 0 0 0-3 0v3.474a.5.5 0 0 1-.5.5H16a.5.5 0 0 1-.5-.5V20.665a.5.5 0 0 1 .5-.5h32a.5.5 0 0 1 .5.5v8a1.5 1.5 0 1 0 3 0v-8a3.5 3.5 0 0 0-3.5-3.5H16Zm27.623 16.596c.138-1.352 1.282-2.295 2.558-2.295H68.91c1.276 0 2.42.943 2.558 2.295.648 6.33-.99 18.825-12.842 24.363a2.553 2.553 0 0 1-2.154 0c-11.851-5.537-13.497-18.032-12.85-24.363Zm2.947.705c-.478 5.854 1.242 16.175 10.98 20.849 9.736-4.673 11.45-14.993 10.97-20.85H46.57Zm10.951 18.819v-17.2h-9.378c-.447 9.828 6.066 15.562 9.378 17.2ZM21 26.665a2 2 0 0 1 2-2h18a2 2 0 0 1 2 2v2.77a2 2 0 0 1-2 2H23a2 2 0 0 1-2-2v-2.77Zm3.6 10.105a2.2 2.2 0 1 0 0 4.4 2.2 2.2 0 0 0 0-4.4Zm-2.2 10.095a2.2 2.2 0 1 1 4.4 0 2.2 2.2 0 0 1-4.4 0Zm2.2 5.8a2.2 2.2 0 1 0 0 4.4 2.2 2.2 0 0 0 0-4.4ZM37 38.971a2.2 2.2 0 1 1 4.4 0 2.2 2.2 0 0 1-4.4 0Zm-5-2.2a2.2 2.2 0 1 0 0 4.4 2.2 2.2 0 0 0 0-4.4Zm-2.2 10.094a2.2 2.2 0 1 1 4.4 0 2.2 2.2 0 0 1-4.4 0Zm2.2 5.8a2.2 2.2 0 1 0 0 4.4 2.2 2.2 0 0 0 0-4.4Zm5-5.8a2.2 2.2 0 1 1 4.4 0 2.2 2.2 0 0 1-4.4 0Zm2.2 5.8a2.2 2.2 0 1 0 0 4.4 2.2 2.2 0 0 0 0-4.4Z"
      fill="#282830"
    />
  </svg>
);

export default Calculator;
