import React, { useEffect, useMemo, useState } from 'react';
import { Box } from '@mui/material';
import { Button, ModalCloseButton } from '@components';
import classNames from 'classnames/bind';

import styles from './style.module.scss';
import QRCode from 'react-qr-code';
import { Telegram, Messenger, Viber } from '@assets/svg';
import TabComponent from './component/tab';
import { useGetScreenDataQuery } from '@store/screens/api';
import { useGetMessengersDataQuery } from '@store/messengers/api';
import { useSelector } from 'react-redux';
import { getMessages } from '@store/messengers/slice';

const cx = classNames.bind(styles);

type ChatBotModalProps = {
  closeModal: () => void;
  returnToConfiguratorMain?: () => void;
  content?: any;
};

const ChatBotModal = ({
  closeModal,
  returnToConfiguratorMain,
  content,
}: ChatBotModalProps) => {
  const list = [
    {
      id: 1,
      name: 'Telegram',
      icon: <Telegram />,
      url: 'https://pipe.bot/telegram/14196',
    },
    {
      id: 2,
      name: 'Messenger',
      icon: <Messenger />,
      url: 'https://pipe.bot/facebook/14229.',
    },
    {
      id: 3,
      name: 'Viber',
      icon: <Viber />,
      url: 'https://pipe.bot/viber/14197',
    },
  ];
  const { isLoading: isLoadingScreenData, data: messengerData } =
    useGetMessengersDataQuery({});

  // const messengersList = useMemo(() => messengerData?.data,[messengerData])
  const messengersList = useSelector(getMessages);
  const [activeTab, setActiveTab] = useState(
    messengerData && messengersList[0],
  );
  useEffect(() => {
    if (messengerData && messengersList[0]) {
      setActiveTab(messengersList[0]);
    }
  }, [messengersList]);

  const onChangeTab = (item: any) => {
    setActiveTab(item);
  };

  return (
    <Box className={cx('modal')}>
      <ModalCloseButton onClick={() => closeModal()} />
      <div className={cx('modal__content')}>
        <p className={styles.title}>{content?.chat_bot_title}</p>
        <p className={styles.subText}>{content?.chat_bot_subtitle}</p>
        <TabComponent
          list={messengersList ? messengersList : list}
          active={activeTab}
          onChangeTab={onChangeTab}
        />
        <div className={styles.wrapperQrCode}>
          <QRCode
            style={{ background: 'white', width: 120, height: 120 }}
            value={activeTab?.url ? activeTab.url : ''}
            size={140}
            viewBox={`0 0 140 140`}
          />
        </div>
        <div className={styles.description}>{content?.chat_bot_qr_text}</div>
        <Button
          variant="primary"
          withoutBorder
          textFontSize={16}
          onClick={() => closeModal()}
        >
          {content?.chat_bot_cancel_button
            ? content?.chat_bot_cancel_button
            : 'Продовжити роботу з терміналом'}
        </Button>
      </div>
    </Box>
  );
};

export default ChatBotModal;
