import * as React from 'react';

const InteriorCar = (props) => (
  <svg
    width="41"
    height="40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox=" 0 0 41 40"
    {...props}
  >
    <path fillRule="evenodd" clipRule="evenodd" d="M9.30054 6.06976C7.85362 6.06976 6.68066 7.24272 6.68066 8.68964C6.68066 10.1366 7.85362 11.3095 9.30054 11.3095H10.111C11.5579 11.3095 12.7309 10.1366 12.7309 8.68964C12.7309 7.24272 11.5579 6.06976 10.111 6.06976H9.30054ZM8.18066 8.68964C8.18066 8.07115 8.68205 7.56976 9.30054 7.56976H10.111C10.7295 7.56976 11.2309 8.07115 11.2309 8.68964C11.2309 9.30812 10.7295 9.80951 10.111 9.80951H9.30054C8.68205 9.80951 8.18066 9.30812 8.18066 8.68964ZM9.09254 14.1569C9.04987 13.9981 9.16953 13.842 9.33398 13.842H13.4779C13.5905 13.842 13.6892 13.9172 13.719 14.0258L16.8106 25.2677C17.0195 26.0273 17.7101 26.5537 18.4979 26.5537L29.9613 26.5537C30.0994 26.5537 30.2113 26.6656 30.2113 26.8037V30.6689C30.2113 30.807 30.0994 30.9189 29.9613 30.9189L13.7874 30.9189C13.6743 30.9189 13.5753 30.843 13.546 30.7338L9.09254 14.1569ZM9.33398 12.342C8.18283 12.342 7.34523 13.4344 7.6439 14.5461L12.0973 31.123C12.3027 31.8875 12.9958 32.4189 13.7874 32.4189L29.9613 32.4189C30.9278 32.4189 31.7113 31.6354 31.7113 30.6689V26.8037C31.7113 25.8372 30.9278 25.0537 29.9613 25.0537L18.4979 25.0537C18.3854 25.0537 18.2867 24.9785 18.2569 24.87L15.1653 13.628C14.9564 12.8684 14.2657 12.342 13.4779 12.342H9.33398Z"/>
  </svg>
);

export default InteriorCar;
