import API from '../api';

export const getGlodalSettingsData = () => {
  return API.get(`api/v1/global-settings`);
};

export const getTranslateData = () => {
  return API.get(`api/v1/translate`);
};
export const getConsultationAdditionalText = (dealer) => {
  return API.get(`api/v1/dealers/${dealer}/settings/pop-ups/consultation_request`);
};
