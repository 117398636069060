export const checkPageForConsultationFor2s = (url) => {
  switch (url) {
    case '/new-cars/list':
      return true
    case '/new-cars/choose-engine':
      return true
    case '/new-cars/choose-package':
      return true
    case '/new-cars/new-car':
      return true
    case '/new-cars/specifications':
      return true
    case '/new-cars/equipment':
      return true
    case '/new-cars/descriptions':
      return true
    case '/new-cars/photo_selection':
      return true
    case '/new-cars/warranty_service':
      return true
    case '/new-cars/accessories':
      return true
    default:
      return false
  }
}
