import React from 'react';
import { useNavigate } from 'react-router-dom';

import './ArrowBackButton.scss';
import SmallArrow from '../../../assets/svg/SmallArrow';
import { THEME } from '../../../utils/theme';
import { initGTMforButtons } from '@utils/initGTMforButtons';

type Props = {
  onClick?: VoidFunction;
};

export const ArrowBackButton: React.FC<Props> = ({ onClick }) => {
  const navigation = useNavigate();
  const [isPressed, setIsPressed] = React.useState(false);

  const onMouseDown = () => setIsPressed(true);
  const onMouseUp = () => setIsPressed(false);

  const handleClick = () => (onClick ? onClick() : navigation(-1));

  return (
    <button
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      className="back-button"
      onClick={() => {
        initGTMforButtons({
          eventType: 'custom_click',
          click_text: `Назад`,
        });
        handleClick();
      }}
    >
      <SmallArrow
        color={isPressed ? THEME.colors.white : THEME.colors.primary_black}
        direction="left"
      />
    </button>
  );
};
