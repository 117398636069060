import { Carousel } from 'react-responsive-carousel';
import React from 'react';

import './SwiperSlide.scss';
import { useNavigate, useParams } from 'react-router-dom';
import { CarOfModel } from '../../../store/usedCars/types';
import { SliderButton } from '../Buttons';
import { Pressable } from '../../wrappers';
import { Swiper, SwiperSlide } from 'swiper/react';

type Props = {
  index: number;
  car: CarOfModel;
};

const Slide = ({ index, car }: Props) => {
  const carouselRef = React.useRef<Carousel>(null);
  const params = useParams();

  const navigation = useNavigate();
  const goToUseCar = () =>
    navigation(`/used-cars/car-info`, {
      state: { car },
    });

  return (
    <div className={`swiperSlide`}>
      <Swiper
        spaceBetween={0}
        navigation={false}
        className="mySwiperMedium"
        loop={true}
      >
        {car.images.map(({ url }, idx) => (
          <SwiperSlide key={url} onClick={goToUseCar}>
            <img key={url} src={url} alt={url} className="swiperSlide__img" />
          </SwiperSlide>
        ))}
        <SliderButton
          width={48}
          disabled={false}
          variant="left"
          left={24}
          chenvroneWidth={12}
        />
        <SliderButton
          width={48}
          disabled={index === car.images.length - 1}
          variant="right"
          right={24}
          chenvroneWidth={12}
        />
      </Swiper>
      {/* <Carousel
        ref={carouselRef}
        showIndicators={false}
        showStatus={false}
        emulateTouch
        showThumbs={false}
        width={832}
        renderItem={(item) => (
          <div className="big-image" onClick={goToUseCar}>
            {item}
          </div>
        )}
        renderArrowPrev={(click, hasPrev) => (
          <SliderButton
            onClick={click}
            disabled={!hasPrev}
            variant="left"
            left={20}
            chenvroneWidth={36}
          />
        )}
        renderArrowNext={(click, hasNext) => (
          <SliderButton
            onClick={click}
            disabled={!hasNext}
            variant="right"
            right={20}
            chenvroneWidth={36}
          />
        )}
        infiniteLoop
      >
        {car.images.map(({ url }) => (
          <img key={url} src={url} alt={url} />
        ))}
      </Carousel> */}
      <Pressable classes={`swiperSlide-content`} onClick={goToUseCar}>
        <div className="flex flex-col items-center">
          <span className="swiperSlide-content__title">
            {car.model.model || ''}
            {/* {car.model.mark.label + ' ' + car.model.model || ''} */}
          </span>
          <span className="swiperSlide-content__pack">
            {car.pack || ''}
          </span>
          <div className="flex items-center">
            <p className="swiperSlide-content__price swiperSlide-content__text">
              {car.price.toLocaleString('uk')} грн
            </p>
            <div className="swiperSlide-content__subtitle flex items-center ml-4">
              Пробіг:
              <p className="swiperSlide-content__text ml-4">
                {car.mileage.toLocaleString('uk')} км
              </p>
            </div>
          </div>
        </div>
        <div className="description">
          <div className="swiperSlide-content__about">
            <div>
              <p className="swiperSlide-content__subtitle">Рік:</p>
              <p className="swiperSlide-content__text">{car.productionYear}</p>
            </div>
            <div className="mt-5">
              <p className="swiperSlide-content__subtitle">Тип палива:</p>
              <p className="swiperSlide-content__text">{car.fuel.label}</p>
            </div>
            <div className="mt-5">
              <p className="swiperSlide-content__subtitle">Трансмісія:</p>
              <p className="swiperSlide-content__text">
                {car.transmissionType}
              </p>
            </div>
            <div className="mt-5">
              <p className="swiperSlide-content__subtitle">Об’єм двигуна:</p>
              <p className="swiperSlide-content__text">{car.engine_volume}</p>
            </div>
            <div className="mt-5">
              <p className="swiperSlide-content__subtitle">Привод:</p>
              <p className="swiperSlide-content__text">{car.drive_type}</p>
            </div>
          </div>
        </div>
      </Pressable>
    </div>
  );
};

export default Slide;
