import * as React from 'react';

const CloseIcon = (props) => (
  <svg
    width={20}
    height={20}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    {...props}
  >
    <g clipPath="url(#a)" stroke="#fff" strokeWidth={3} strokeLinejoin="round">
      <path d="m1.667 1.667 16.666 16.666M18.333 1.667 1.667 18.333" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default CloseIcon;
