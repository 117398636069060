import WrappedComponent from "@components/Modals/TipsModal/VideoView";
import React, {SetStateAction, useEffect} from "react";
import {TipsDataType} from "@components/Modals/TipsModal";
type PropsType = {
    item: TipsDataType;
    activeSlide:number;
    length:number;
    setCurrentRef:any;
    setShowControls:any;
    showControls:boolean
};
const ListItem=({item,activeSlide,length,setCurrentRef,setShowControls,showControls}:PropsType)=>{
    const vidRef=React.useRef();
    return(
        <div>
            <div key={item.id} className={item?.video?'wrapperSlideView hasVideo':'wrapperSlideView'}>
                <div className="leftBlock">
                    {
                        item.video?
                            <WrappedComponent item={item} ref={vidRef} setCurrentRef={setCurrentRef} setShowControls={setShowControls} showControls={showControls}/>
                            :
                            <img alt={item.title} src={item.image}/>
                    }
                </div>
                <div className="rightBlock">
                    <p className={'title'}>{item.title}</p>
                    <div className={'description'}
                         dangerouslySetInnerHTML={{
                             __html: item.description,
                         }}
                    ></div>
                    <div className={"paginationSlider"}>
                        <span className={'active'}>{activeSlide}</span> <span className={'withMr'}>/ </span> <span>{length}</span>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ListItem
