import * as React from 'react';

const CompareApprove = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="40"
        height="41"
        fill="none"
        viewBox="0 0 40 41"
        {...props}
    >
      <path
          fill="#4CAF50"
          fillRule="evenodd"
          d="M31.522 12.461a.75.75 0 01.017 1.06L16.604 28.939a1.55 1.55 0 01-2.21.018L8.47 23.03a.75.75 0 111.06-1.06l5.926 5.925c.02.02.051.02.07 0l14.935-15.417a.75.75 0 011.06-.017z"
          clipRule="evenodd"
      ></path>
    </svg>
);

export default CompareApprove;
