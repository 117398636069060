import styles from './style.module.scss'
import React from "react";
type Props = {
    active:boolean,
    handleClick:()=>void;
    label:any,
    bigSize?:boolean;
};
const RadioButton:React.FC<Props>=({active,handleClick=()=>{},label,bigSize})=>{
    const style=bigSize?styles.wrapperRadioBlockBigSize:styles.wrapperRadioBlock
    return(
        <button onClick={()=>handleClick()} className={style}>
            <div className={active? `${styles.wrapperRadio} ${styles.active}`:styles.wrapperRadio} >
                {
                    active &&  <div className={styles.activeCircle}>
                        <div className={styles.circle}></div>
                    </div>
                }

            </div>
                {label}
        </button>

    )
}
export default RadioButton
