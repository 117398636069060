type Colors =
  | "primary_black"
  | "highlight_black"
  | "gray_1"
  | "gray_4"
  | "gray_5"
  | "gray_6"
  | "gray_7"
  | "white"
  | "black"
  | "primary_red"
  | "transparent"
  | "effective_blue";

interface ITheme {
  colors: {
    [key in Colors]: string;
  };
}

export const THEME: ITheme = {
  colors: {
    white: "#FFFFFF",
    black: "#000000",
    primary_black: "#282830",
    highlight_black: "#15151B",
    gray_1: "#F5F5F5",
    gray_4: "#E4E4E4",
    gray_5: "#D9D9D9",
    gray_6: "#A8AAAC",
    gray_7: "#6C7073",
    primary_red: "#ff0022",
    effective_blue: "#0072f0",
    transparent:"transparent"
  },
};
