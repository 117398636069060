import { Box, Tab, Tabs } from '@mui/material';
import React from 'react';
import { Button, ModalCloseButton } from '@components';
import { BrochureBotsModalProps } from './types';
import classNames from 'classnames/bind';
import styles from './style.module.scss';
import QRCode from 'react-qr-code';
import { Telegram, Viber, Messenger } from '@assets/svg';

const cx = classNames.bind(styles);

function a11yProps(index: any) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const BrochureBotsModal: React.FC<BrochureBotsModalProps> = ({
  closeModal,
}) => {
  const [activeTab, setActiveTab] = React.useState<number>(0);

  return (
    <Box className={cx('brochure-modal')}>
      <ModalCloseButton onClick={closeModal} />
      <div className={cx('brochure-modal__content')}>
        <p className={styles.title}>Отримати брошуру</p>
        <p className={styles.subText}>Оберіть бажаний месенджер</p>
        <Tabs
          value={activeTab}
          onChange={(_, value) => setActiveTab(value)}
          sx={{ height: 'fit-content', width: '66%', overflow: 'unset' }}
          classes={{
            indicator: cx('active'),
            scroller: cx('scroller'),
            flexContainer: cx('flexContainer'),
          }}
        >
          <Tab
            className={cx('tabs__label')}
            label={<span className={cx('tabs__label-text')}>Telegram</span>}
            icon={<Telegram />}
            {...a11yProps(0)}
          />
          <Tab
            className={cx('tabs__label')}
            label={<span className={cx('tabs__label-text')}>Messenger</span>}
            icon={<Messenger />}
            {...a11yProps(1)}
          />
          <Tab
            label={<span className={cx('tabs__label-text')}>Viber</span>}
            icon={<Viber />}
            {...a11yProps(2)}
            className={cx('tabs__label')}
          />
        </Tabs>
        <div className={styles.wrapperQrCode}>
          <QRCode
            style={{ height: '100%', background: 'white' }}
            value={''}
            size={140}
            viewBox={`0 0 140 140`}
          />
        </div>
        <div className={styles.description}>
          Відскануйте QR-код за допомогую вашого мобільного телефона та
          отримайте посилання на чат-бот.
        </div>
        <Button
          variant="primary"
          withoutBorder
          textFontSize={16}
          onClick={closeModal}
        >
          Продовжити роботу з терміналом
        </Button>
      </div>
    </Box>
  );
};

export default BrochureBotsModal;
