import React, { useState, useEffect, memo, useMemo } from 'react';
import classNames from 'classnames/bind';
import styles from './style.module.scss';
import MyTimer from '@components/hookTimer/HookTimer';
const cx = classNames.bind(styles);

type CountdownTimerProps = {
  minutes?: number;
  seconds?: number;
  onTimeout: () => void;
};

const CountdownTimer = React.memo(
  ({ minutes = 0, seconds = 0, onTimeout }: CountdownTimerProps) => {
    const [time, setTime] = useState({
      minutes: minutes,
      seconds: seconds,
    });
    const [activeTimer, setActiveTimer] = useState(false);
    const [timeOut, setTimeOut] = useState(false);
    const timerTime = new Date();
    //@ts-ignore
    timerTime.setSeconds(timerTime.getSeconds() + minutes * 60 + seconds);

    const padValue = (value: number) => {
      return value < 10 ? '0' + value : value;
    };

    const runningTimerFc = (isRunning: boolean) => {
      setActiveTimer(isRunning);
    };
    return (
      <div
        className={cx(
          'countdownTimer',
          time.minutes * 60 + time.seconds === minutes * 60 + seconds &&
            !activeTimer &&
            timeOut
            ? 'start'
            : activeTimer && time.minutes * 60 + time.seconds > 0
            ? 'active'
            : 'timeOut',
        )}
      >
        {activeTimer ? (
          <svg>
            <circle
              r="69"
              cx="70"
              cy="70"
              style={
                activeTimer
                  ? { animationDuration: `${minutes * 60 + seconds}s` }
                  : { animationDuration: `0s` }
              }
            ></circle>
          </svg>
        ) : null}
        <div className={cx('countdownTimer__time')}>
          <MyTimer
            expiryTimestamp={timerTime}
            onTimeout={() => {
              setTimeOut(true);
              onTimeout();
            }}
            runingTimer={(isRunning: boolean) => runningTimerFc(isRunning)}
          />
        </div>
      </div>
    );
  },
);

export default React.memo(CountdownTimer);
