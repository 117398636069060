import React, { useState, useEffect, useRef } from 'react';
import { useController, useForm } from 'react-hook-form';
import classNames from 'classnames/bind';

import InputMask from 'react-input-mask';

import styles from './style.module.scss';
const cx = classNames.bind(styles);

const PhoneInput = (props) => {
  const { field } = useController(props);
  const { register } = useForm();

  const [focus, setFocus] = useState(props.setFocus);
  const firstNameRef = useRef(null);
  const { ref } = register(props.name);

  const required = props?.rules?.required;

  useEffect(() => {
    if (field.value) {
      setFocus(true);
    }
    if (field.value === '' || field.value === '+' || field.value === '+380-') {
      setFocus(false);
    }
  }, [field.value]);

  return (
    <div className={props.disabled ? cx('disabled') : ''}>
      <div
        className={props.errors ? cx('inputWrap', 'error') : cx('inputWrap')}
      >
        <div className={focus ? cx('label', 'focus') : cx('label')}>
          {props.label}{' '}
          {required && !props.disabled ? (
            <span className={cx('required')}>*</span>
          ) : null}
        </div>
        <InputMask
          {...props}
          {...field}
          innerRef={field.ref}
          mask="+380 99 999 99 99"
          maskChar=" "
          value={field.value}
          ref={(e) => {
            ref(e);
            firstNameRef.current = e;
          }}
          onFocus={() => {
            setFocus(true);
          }}
          onBlur={() => !field.value && setFocus(false)}
          className={focus ? cx('input', 'focus') : cx('input')}
        />
        {props.errors && (
          <p className={cx('errorName')}>{props.errors?.message}</p>
        )}
      </div>
    </div>
  );
};

export default PhoneInput;
