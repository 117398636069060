import { BaseResponse } from '../../types';
import { api } from '../api';
import {
  CarDetailRequest,
  CarDetailResponse,
  GetFilterValuesResponse,
  GetListCarsOfModelRequest,
  GetListCarsOfModelResponse,
  GetModelCarsRequest,
  GetUsedCarsModelsResponse,
} from './types';

const usedCarsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getUsedCarsModels: builder.query<
      BaseResponse<GetUsedCarsModelsResponse>,
      GetModelCarsRequest
    >({
      query: ({ dealer_dealer_code, filter, sortBy }) => {
        let paramsString = new URLSearchParams();
        if (filter) {
          paramsString = new URLSearchParams({
            'price[from]': filter.price.from.toString(),
            'price[to]': filter.price.to.toString(),
            'year[from]': filter.year.from.toString(),
            'year[to]': filter.year.to.toString(),
            'mileage[from]': filter.mileage.from.toString(),
            'mileage[to]': filter.mileage.to.toString(),
            toyotaPlus: Number(filter.toyotaPlus).toString(),
            VAT: Number(filter.VAT).toString(),
          });

          filter.make.length &&
            filter.make.map((item) =>
              paramsString.append('make[]', item.id!.toString()),
            );
          filter.fuel.length &&
            filter.fuel.map((item) =>
              paramsString.append('fuel[]', item.toString()),
            );
        }

        sortBy &&
          sortBy !== 'most_popular' &&
          paramsString.append('sortBy', sortBy);

        return {
          url: `/api/v1/dealers/${dealer_dealer_code}/used-cars/models?${paramsString}`,
        };
      },
    }),
    getValuesForUsedCarsFilter: builder.query<
      GetFilterValuesResponse,
      { dealer_dealer_code: string }
    >({
      query: ({ dealer_dealer_code }) => ({
        url: `/api/v1/dealers/${dealer_dealer_code}/used-cars/filters`,
      }),
    }),
    getListCarsOfModel: builder.query<
      BaseResponse<GetListCarsOfModelResponse>,
      GetListCarsOfModelRequest
    >({
      query: ({ dealer_dealer_code, filter, sortBy, models, searchKey }) => {
        let paramsString = new URLSearchParams();
        if (filter) {
          paramsString = new URLSearchParams({
            'price[from]': filter?.price.from.toString(),
            'price[to]': filter?.price.to.toString(),
            'year[from]': filter?.year.from.toString(),
            'year[to]': filter?.year.to.toString(),
            'mileage[from]': filter?.mileage.from.toString(),
            'mileage[to]': filter?.mileage.to.toString(),
            toyotaPlus: Number(filter?.toyotaPlus).toString(),
            VAT: Number(filter?.VAT).toString(),
          });

          filter.make.length &&
            filter.make.map((item) =>
              paramsString.append('make[]', item.id!.toString()),
            );
          filter.fuel.length &&
            filter.fuel.map((item) =>
              paramsString.append('fuel[]', item.toString()),
            );
        }
        if (searchKey === 'models') {
          models.map((item) =>
            paramsString.append('models[]', item.toString()),
          );
        } else {
          models.map((item) => paramsString.append('make[]', item.toString()));
        }

        sortBy &&
          sortBy !== 'most_popular' &&
          paramsString.append('sortBy', sortBy);

        return {
          url: `/api/v1/dealers/${dealer_dealer_code}/used-cars?${paramsString}`,
        };
      },
    }),
    getCarDetail: builder.query<
      BaseResponse<CarDetailResponse>,
      CarDetailRequest
    >({
      query: ({ dealer_dealer_code, id }) => ({
        url: `/api/v1/dealers/${dealer_dealer_code}/used-cars/${id}`,
      }),
    }),
  }),
});

export const {
  useGetUsedCarsModelsQuery,
  useGetValuesForUsedCarsFilterQuery,
  useGetListCarsOfModelQuery,
  useGetCarDetailQuery,
} = usedCarsApi;

export default usedCarsApi;
