import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '..';
import { SortByType } from '../../types';
import configuratorCarsApi, {
  useGetModelConfiguratorAccessoriesQuery,
  useGetModelConfiguratorOptionsQuery
} from './api';
import { InitialState, NewCarsFilterType, ConfiguratorCarsSortVariants } from './types';

export const sortVariants: {
  [name: string]: SortByType<ConfiguratorCarsSortVariants>;
} = {
  'populars.asc': {
    by: 'populars.asc',
    label: 'Найпопулярніші',
  },
  'title.asc': {
    by: 'title.asc',
    label: 'Назва моделі',
  },
};

const initialState: InitialState = {
  defaultFilterValues: {
    types: [],
    engines: [],
    fuel_consumption: { from: 0, to: 1 },
    number_of_seats: { from: 0, to: 1 },
    power: { from: 0, to: 1 },
  },
  filter: {
    types: [],
    engines: [],
    fuel_consumption: { from: 0, to: 1 },
    number_of_seats: { from: 0, to: 1 },
    power: { from: 0, to: 1 },
  },
  choosedEngine: null,
  choosedPackage: null,
  choosedOption: [],
  choosedAccessories: [],
  choosedBody: null,
  choosedCargoBody: null,
  choosedSpecification: null,
  isResetFilter: true,
  models: null,
  sortBy: sortVariants['populars.asc'],
  configuratorId:null,
  carStyle:null,
  choosedExterior:null,
  choosedInterior:null,
  choosedDisc:null,
  myConfigurationsList:null,
  optionsList:[],
  accessoriesDataList:[],
  accessoriesDataMeta:null
};

const slice = createSlice({
  name: 'configuratorCars',
  initialState,
  reducers: {
    setFilter: (state, action: PayloadAction<NewCarsFilterType>) => {
      state.filter = action.payload;
      state.isResetFilter = false;
    },
    resetFilters: (state) => {
      state.filter = {
        ...state.defaultFilterValues,
        types: [],
        engines: [],
      };
      state.isResetFilter = true;
    },
    setSortBy: (state, action: PayloadAction<ConfiguratorCarsSortVariants>) => {
      state.sortBy = sortVariants[action.payload];
    },
    setEngine: (state, action) => {
      state.choosedEngine = action.payload;
    },
    setBody: (state, action) => {
      state.choosedBody = action.payload;
    },
    setCargoBody: (state, action) => {
      state.choosedCargoBody = action.payload;
    },
    setSpecification: (state, action) => {
      state.choosedSpecification = action.payload;
    },
    setPackage: (state, action) => {
      state.choosedPackage = action.payload;
    },
    setOption: (state, action) => {
      state.choosedOption = action.payload;
    },
    setAccessories: (state, action) => {
      state.choosedAccessories = action.payload;
    },
    setExteriorColor: (state, action) => {
      state.choosedExterior = action.payload;
    },
    setInteriorColor: (state, action) => {
      state.choosedInterior= action.payload;
    },
    setDiscColor: (state, action) => {
      state.choosedDisc= action.payload;
    },
    clearStyledCarImages: (state, action) => {
      state.carStyle= action.payload;
    },
    clearAccessoriesList: (state, action) => {
      state.accessoriesDataList= [];
      state.accessoriesDataMeta= null;
    },
    setMyConfigurationsList: (state, action) =>{
      state.myConfigurationsList = action.payload
    }
  },
  extraReducers: (buider) => {
    buider
      .addMatcher(
        configuratorCarsApi.endpoints.getValuesForConfiguratorCarsFilter.matchFulfilled,
        (state, action) => {
          state.defaultFilterValues = action.payload;
          state.filter = {
            ...action.payload,
            types: [],
            engines: [],
          };
        },
      )
      .addMatcher(
        configuratorCarsApi.endpoints.getConfiguratorCarsModels.matchFulfilled,
        (state, action) => {
          state.models = action.payload;
        },
      )
      .addMatcher(
          configuratorCarsApi.endpoints.saveConfiguratorResults.matchFulfilled,
          (state, action) => {
            state.configuratorId = action.payload.id;
          },
      )
    .addMatcher(
          configuratorCarsApi.endpoints.getStyledCarImages.matchFulfilled,
          (state, action) => {
            state.carStyle = action.payload;
          },
      )
    .addMatcher(
          configuratorCarsApi.endpoints.getMyConfigurations.matchFulfilled,
          (state, action) => {
            state.myConfigurationsList = action.payload;
          },
      )
        .addMatcher(
            configuratorCarsApi.endpoints.getModelConfiguratorAccessories.matchFulfilled,
            (state, action) => {
              state.accessoriesDataList = [...state.accessoriesDataList,...action.payload.data];
              state.accessoriesDataMeta = action.payload.meta;
            },
        )
        .addMatcher(
          configuratorCarsApi.endpoints.getModelConfiguratorOptions.matchFulfilled,
          (state, action) => {
            state.optionsList = action.payload.data;
          },
      );
  }
});

export const { setFilter, resetFilters, setSortBy, setEngine, setPackage,setOption,setAccessories,setExteriorColor,setInteriorColor,setDiscColor,clearStyledCarImages,clearAccessoriesList,setCargoBody, setBody, setSpecification, setMyConfigurationsList } =
  slice.actions;

export const selectChoosedEngine = (state: RootState) =>
  state.configuratorCars.choosedEngine;
export const selectChoosedPackage = (state: RootState) =>
  state.configuratorCars.choosedPackage;
export const selectChoosedOption = (state: RootState) =>
    state.configuratorCars.choosedOption;
export const selectChoosedBody = (state: RootState) =>
    state.configuratorCars.choosedBody;
export const selectChoosedCargoBody = (state: RootState) =>
    state.configuratorCars.choosedCargoBody;
export const selectChoosedSpecification = (state: RootState) =>
    state.configuratorCars.choosedSpecification;
export const selectChoosedAccessories = (state: RootState) =>
    state.configuratorCars.choosedAccessories;
export const selectChoosedExterior = (state: RootState) =>
    state.configuratorCars.choosedExterior;
export const selectChoosedInterior = (state: RootState) =>
    state.configuratorCars.choosedInterior;
export const selectChoosedDisc = (state: RootState) =>
    state.configuratorCars.choosedDisc;
export const selectConfiguratorCarsSortBy = (state: RootState) => state.configuratorCars.sortBy;
export const selectConfiguratorCarsModels = (state: RootState) => state.configuratorCars.models;
export const myConfigurationsList = (state: RootState) => state.configuratorCars.myConfigurationsList;
export const selectCarStyle = (state: RootState) => state.configuratorCars.carStyle;
export const selectAccessoriesDataList = (state: RootState) => state.configuratorCars.accessoriesDataList;
export const selectAccessoriesDataMeta = (state: RootState) => state.configuratorCars.accessoriesDataMeta;
export const selectDefaultFilterValues = (state: RootState) =>
  state.configuratorCars.defaultFilterValues;
export const selectConfiguratorCarsFilter = (state: RootState) => state.configuratorCars.filter;
export const selectIsResetFilterFilter = (state: RootState) =>
  state.configuratorCars.isResetFilter;
export const selectListOptions = (state: RootState) =>
    state.configuratorCars.optionsList;
export const selectCountOfActiveFilters = (state: RootState) => {
  const { engines, fuel_consumption, number_of_seats, power, types } =
    state.configuratorCars.filter;
  const {
    fuel_consumption: defaultFuelConsumption,
    number_of_seats: defaultNumberOfSeats,
    power: defaultPower,
  } = state.configuratorCars.defaultFilterValues;

  const typesNumber = Number(!!types.length);
  const enginesNumber = Number(!!engines.length);
  const fuelConsumptionNumber = Number(
    fuel_consumption?.from !== defaultFuelConsumption?.from ||
      fuel_consumption?.to !== defaultFuelConsumption?.to,
  );
  const numberOfSeatsNumber = Number(
    number_of_seats?.from !== defaultNumberOfSeats?.from ||
      number_of_seats?.to !== defaultNumberOfSeats?.to,
  );
  const powerNumber = Number(
    power?.from !== defaultPower?.from || power?.to !== defaultPower?.to,
  );

  return (
    typesNumber +
    enginesNumber +
    fuelConsumptionNumber +
    numberOfSeatsNumber +
    powerNumber
  );
};

export default slice.reducer;
