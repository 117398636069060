import React from 'react';
import { Box } from '@mui/material';
import { Button, ModalCloseButton } from '@components';
import classNames from 'classnames/bind';

import styles from './style.module.scss';
import { useGetQrCodeQuery } from '@store/configuratorCars/api';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectedDealer } from '@store/dealers/slice';
import { translationsDataArr } from '@store/translate/slice';
import getTranslateText from '@utils/getTranslateText';
const cx = classNames.bind(styles);

type PdfModalProps = {
  closeModal: () => void;
  configurationId: string;
  qrCodeType?: string;
};

const DownloadPdfModal = ({
  closeModal,
  configurationId,
  qrCodeType = 'configurator',
}: PdfModalProps) => {
  const params = useParams();
  const dealer = useSelector(selectedDealer);
  const { data: qrCodeLink, isLoading: isLoadingLink } = useGetQrCodeQuery({
    dealer_dealer_code: dealer || 'AZ',
    configurator_id: configurationId,
    qrCodeType: qrCodeType,
  });
  const translateArr = useSelector(translationsDataArr);
  return (
    <Box className={cx('modal')}>
      <ModalCloseButton onClick={() => closeModal()} />
      <div className={cx('modal__content')}>
        {!isLoadingLink && (
          <>
            <p>
              {qrCodeType === 'service' ? getTranslateText(
                  translateArr,
                  'translate.admin.modal.pdf.service.title',
              ) : getTranslateText(
                  translateArr,
                  'translate.admin.modal.pdf.title',
              )}
            </p>
            <div
              className={cx('wrapperQrCode')}
              dangerouslySetInnerHTML={{ __html: qrCodeLink?.qrCode }}
            ></div>
            <span className={cx('description')}>
              {qrCodeType === 'service' ? getTranslateText(
                translateArr,
                'translate.admin.modal.pdf.service.descr',
              ) : getTranslateText(
                  translateArr,
                  'translate.admin.modal.pdf.descr',
              )}
            </span>
            <Button
              variant="primary"
              withoutBorder
              textFontSize={16}
              onClick={closeModal}
            >
              {qrCodeType === 'service' ? getTranslateText(
                translateArr,
                'translate.admin.modal.pdf.submit.service.btn',
              ) : getTranslateText(
                  translateArr,
                  'translate.admin.modal.pdf.submit.btn',
              )}
            </Button>
          </>
        )}
      </div>
    </Box>
  );
};

export default DownloadPdfModal;
