import React, { PropsWithChildren } from 'react';
import { BaseButtonProps } from './types';
import './Button.scss';
import { THEME } from '../../../utils/theme';
import { initGTMforButtons } from '@utils/initGTMforButtons';

export type ButtonProps = PropsWithChildren<
  BaseButtonProps & {
    type?: 'default' | 'rounded' | 'submit';
    badge?: number;
    variant?: 'primary' | 'secondary';
    icon?: ((color?: string) => React.ReactNode) | React.ReactNode;
    withoutBorder?: boolean;
    border?: string;
    style?: React.CSSProperties;
    iconPosition?: 'left' | 'right';
  }
>;

export const Button = ({
  icon,
  height = 48,
  width = 220,
  children,
  badge,
  backgroundColor,
  marginRight,
  marginLeft,
  withoutBorder,
  onClick,
  small = false,
  paddingLeft,
  paddingRight,
  textColor,
  disabled,
  border,
  textFontSize,
  style,
  iconPosition = 'left',
  textFontFamily,
  badgeColor,
  classes,
  marginBottom,
  marginTop,
  variant,
  type = 'default',
  iconColor,
  borderColor,
  textMarginBottom,
  notAddedGTM = false,
}: ButtonProps) => {
  const [isPressed, setIsPressed] = React.useState(false);

  const _textMarginBottom =
    textMarginBottom !== undefined ? textMarginBottom : small ? 1 : 5;

  const onMouseDown = () => setIsPressed(true);
  const onMouseUp = () => setIsPressed(false);

  const computedBgColor = React.useMemo(() => {
    if (type === 'default') {
      if (isPressed) {
        if (backgroundColor) {
          return typeof backgroundColor === 'object'
            ? backgroundColor.active
            : backgroundColor;
        } else if (variant === 'primary') {
          return 'rgba(40, 40, 48, 0.8)';
        } else if (variant === 'secondary') {
          return THEME.colors.primary_black;
        }
      } else {
        if (backgroundColor) {
          return typeof backgroundColor === 'object'
            ? backgroundColor.default
            : backgroundColor;
        } else if (variant === 'primary') {
          return THEME.colors.primary_black;
        } else if (variant === 'secondary') {
          return THEME.colors.white;
        }
      }
    } else {
      if (isPressed) {
        if (backgroundColor) {
          return typeof backgroundColor === 'object'
            ? backgroundColor.active
            : backgroundColor;
        } else {
          return THEME.colors.primary_black;
        }
      } else if (disabled) {
        if (backgroundColor) {
          return typeof backgroundColor === 'object'
            ? backgroundColor.disabled
            : backgroundColor;
        } else {
          return THEME.colors.gray_4;
        }
      } else {
        if (backgroundColor) {
          return typeof backgroundColor === 'object'
            ? backgroundColor.default
            : backgroundColor;
        } else {
          return THEME.colors.gray_4;
        }
      }
    }
  }, [backgroundColor, isPressed, variant, disabled, type]);

  const computedBadgeBgColor = React.useMemo(() => {
    if (isPressed) {
      if (badgeColor) {
        return typeof badgeColor === 'object' ? badgeColor.active : badgeColor;
      } else {
        return THEME.colors.primary_black;
      }
    } else if (disabled) {
      if (badgeColor) {
        return typeof badgeColor === 'object'
          ? badgeColor.disabled
          : badgeColor;
      } else {
        return THEME.colors.gray_4;
      }
    } else {
      if (badgeColor) {
        return typeof badgeColor === 'object' ? badgeColor.default : badgeColor;
      } else {
        return THEME.colors.primary_black;
      }
    }
  }, [badgeColor, isPressed, disabled]);

  const computedBorderColor = React.useMemo(() => {
    if (borderColor) {
      if (isPressed) {
        return typeof borderColor === 'object'
          ? borderColor.active
          : borderColor;
      } else {
        return typeof borderColor === 'object'
          ? borderColor.default
          : borderColor;
      }
    }
  }, [borderColor, isPressed]);

  const computedTextColor = React.useMemo(() => {
    if (type === 'default') {
      if (isPressed) {
        if (textColor) {
          return typeof textColor === 'object' ? textColor.active : textColor;
        } else {
          return THEME.colors.white;
        }
      } else {
        if (textColor) {
          return typeof textColor === 'object' ? textColor.default : textColor;
        } else if (variant === 'primary') {
          return THEME.colors.white;
        } else if (variant === 'secondary') {
          return THEME.colors.primary_black;
        }
      }
    } else {
      if (isPressed) {
        if (textColor) {
          return typeof textColor === 'object' ? textColor.active : textColor;
        } else {
          return THEME.colors.white;
        }
      } else if (disabled) {
        if (textColor) {
          return typeof textColor === 'object' ? textColor.disabled : textColor;
        } else {
          return THEME.colors.white;
        }
      } else {
        if (textColor) {
          return typeof textColor === 'object' ? textColor.default : textColor;
        } else {
          return THEME.colors.gray_7;
        }
      }
    }
  }, [textColor, isPressed, variant, disabled, type]);

  const computedIconColor = React.useMemo(() => {
    if (isPressed) {
      return typeof iconColor === 'object' ? iconColor.active : iconColor;
    } else if (disabled) {
      return typeof iconColor === 'object' ? iconColor.disabled : iconColor;
    } else {
      return typeof iconColor === 'object' ? iconColor.default : iconColor;
    }
  }, [disabled, isPressed, iconColor]);

  return type === 'default' ? (
    <button
      className={`${variant}-button ${classes || ''}`}
      style={{
        width,
        height,
        backgroundColor: computedBgColor,
        color: computedTextColor,
        marginLeft,
        marginRight,
        marginBottom,
        marginTop,
        borderWidth: withoutBorder || variant === 'primary' ? 0 : 1,
        ...style,
      }}
      // onClick={onClick}
      onClick={() => {
        //@ts-ignore
        onClick(),
          initGTMforButtons({
            eventType: 'custom_click',
            click_text: `${children}`,
          });
      }}
      disabled={disabled}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      onMouseLeave={onMouseUp}
    >
      {icon
        ? typeof icon === 'function'
          ? icon(iconColor ? computedIconColor : computedTextColor)
          : icon
        : null}
      <span
        className="text"
        style={{
          marginRight: badge !== undefined ? 20 : 0,
          marginLeft: icon ? 16 : 0,
          fontSize: textFontSize,
          marginBottom: _textMarginBottom,
          fontFamily: textFontFamily,
        }}
      >
        {children}
      </span>
      {badge !== undefined ? (
        <div
          className="badge"
          style={{ backgroundColor: computedBadgeBgColor }}
        >
          <span>{badge}</span>
        </div>
      ) : null}
    </button>
  ) : (
    <button
      disabled={disabled}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      onMouseLeave={onMouseUp}
      className="rounded-button"
      style={{
        height,
        width,
        borderRadius: width !== 'auto' ? Number(width) / 2 : 50,
        backgroundColor: computedBgColor,
        color: computedTextColor,
        marginRight,
        marginLeft,
        paddingLeft,
        paddingRight,
        border,
        borderWidth: withoutBorder ? 0 : 1,
        borderColor: computedBorderColor,
        ...style,
      }}
      // onClick={onClick}
      onClick={() => {
        !notAddedGTM &&
          initGTMforButtons({
            eventType: 'custom_click',
            click_text: `${children}`,
          }),
          //@ts-ignore
          onClick();
      }}
    >
      {icon && iconPosition === 'left'
        ? typeof icon === 'function'
          ? icon(iconColor ? computedIconColor : computedTextColor)
          : icon
        : null}
      <span
        style={{
          marginLeft: icon
            ? iconPosition === 'left'
              ? small
                ? 8
                : 10
              : 0
            : 0,
          marginRight:
            badge !== undefined || (icon && iconPosition === 'right')
              ? small
                ? 8
                : 16
              : 0,
          fontSize: textFontSize,
          marginBottom: _textMarginBottom,
          fontFamily: textFontFamily,
        }}
        className={small ? 'small' : ''}
      >
        {children}
      </span>
      {icon && iconPosition === 'right'
        ? typeof icon === 'function'
          ? icon(iconColor ? computedIconColor : computedTextColor)
          : icon
        : null}
      {badge !== undefined ? (
        <div
          className={small ? 'badge badge-small' : 'badge'}
          style={{ backgroundColor: computedBadgeBgColor }}
        >
          <span>{badge}</span>
        </div>
      ) : null}
    </button>
  );
};
