import * as React from 'react';

const Filter = (props) => (
  <svg
    width={20}
    height={20}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    viewBox="0 0 40 40"
  >
    <path
      d="m23.297 20.474-.297.244V34.761l-6-3.392V20.718l-.297-.244L3 9.177V5.834h34v3.343L23.297 20.474Z"
      stroke={props.color ? 'currentColor' : '#6C7073'}
      strokeWidth={2}
    />
  </svg>
);

export default Filter;
