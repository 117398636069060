import * as React from 'react';

const TestDrive = (props) => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.614 51.928a1.5 1.5 0 0 1 1.5-1.5h16.203a1.5 1.5 0 1 1 0 3H9.114a1.5 1.5 0 0 1-1.5-1.5ZM14.703 41.928a1.5 1.5 0 0 1 1.5-1.5h9.114a1.5 1.5 0 1 1 0 3h-9.114a1.5 1.5 0 0 1-1.5-1.5ZM10.652 31.928a1.5 1.5 0 0 1 1.5-1.5h13.165a1.5 1.5 0 1 1 0 3H12.152a1.5 1.5 0 0 1-1.5-1.5ZM18.753 21.928a1.5 1.5 0 0 1 1.5-1.5h5.064a1.5 1.5 0 1 1 0 3h-5.064a1.5 1.5 0 0 1-1.5-1.5ZM31.5 32.387a1.5 1.5 0 0 1 1.5-1.5h20c5.78 0 10.937 1.909 14.66 5.367 3.733 3.468 5.934 8.415 5.92 14.296-.005 1.943-1.631 3.337-3.42 3.337H62a1.5 1.5 0 0 1 0-3h8.16a.464.464 0 0 0 .32-.12c.069-.067.1-.141.1-.225.013-5.09-1.875-9.222-4.963-12.09-3.099-2.88-7.496-4.565-12.617-4.565H33a1.5 1.5 0 0 1-1.5-1.5ZM31.5 52.387a1.5 1.5 0 0 1 1.5-1.5h10.6a1.5 1.5 0 0 1 0 3H33a1.5 1.5 0 0 1-1.5-1.5Z"
      fill="#282830"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M31.707 21.627a1.5 1.5 0 0 1 2.054-.533l17 10a1.5 1.5 0 1 1-1.521 2.586l-17-10a1.5 1.5 0 0 1-.533-2.053ZM53 43.387a7.5 7.5 0 1 0 0 15 7.5 7.5 0 0 0 0-15Zm-10.5 7.5c0-5.799 4.701-10.5 10.5-10.5 5.8 0 10.5 4.701 10.5 10.5 0 5.8-4.7 10.5-10.5 10.5-5.799 0-10.5-4.7-10.5-10.5Z"
      fill="#282830"
    />
  </svg>
);

export default TestDrive;
