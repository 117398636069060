import React, { PropsWithChildren } from 'react';
import { Modal, Box } from '@mui/material';
import './styles.scss';
import { Button, ModalCloseButton } from '../../common/Buttons';
import { THEME } from '../../../utils/theme';
import { useSelector } from 'react-redux';
import { translationsDataArr } from '@store/translate/slice';
import getTranslateText from '@utils/getTranslateText';

type Props = {
  isOpen: boolean;
  onClose: VoidFunction;
  classes?: string;
  handleResetFilters: VoidFunction;
  badgeCount?: number;
};

const FilterModalWrapper: React.FC<PropsWithChildren<Props>> = ({
  isOpen,
  onClose,
  children,
  classes = '',
  handleResetFilters,
  badgeCount,
}) => {
  const translateArr = useSelector(translationsDataArr);
  return (
    <Modal open={isOpen} onClose={onClose} disableAutoFocus={true}>
      <Box className={`filter-modal ${classes}`}>
        <ModalCloseButton onClick={onClose} />
        <p className="filter-modal__title">
          {getTranslateText(translateArr, 'translate.admin.title.filter')}
        </p>
        {children}
        <div className="buttons">
          <Button
            variant="secondary"
            width={182}
            height={58}
            marginRight={24}
            onClick={handleResetFilters}
            textFontFamily="Toyota-Type Book"
            textFontSize={16}
            textMarginBottom={1}
          >
            {getTranslateText(translateArr, 'translate.admin.filter.btn.reset')}
          </Button>
          <Button
            variant="primary"
            width={182}
            height={58}
            onClick={onClose}
            badge={badgeCount}
            textFontFamily="Toyota-Type Book"
            badgeColor={{
              active: THEME.colors.primary_black,
              default: 'rgb(60, 60, 65)',
              disabled: THEME.colors.gray_4,
            }}
            textFontSize={16}
            textMarginBottom={1}
          >
            {getTranslateText(
              translateArr,
              'translate.admin.filter.btn.to.choose',
            )}
          </Button>
        </div>
      </Box>
    </Modal>
  );
};

export default FilterModalWrapper;
