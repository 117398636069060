import * as React from 'react';

const Comparison = (props) => (
  <svg
    width={26}
    height={26}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox=" 0 0 51 51"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M35.263 25.723a1.329 1.329 0 0 0 0 2.001 1.659 1.659 0 0 0 2.194 0l11.983-10.93c1.413-1.29 1.413-3.38 0-4.67L37.457 1.195a1.659 1.659 0 0 0-2.194 0 1.329 1.329 0 0 0 0 2.002l10.797 9.848H7.4c-.857 0-1.551.634-1.551 1.415 0 .782.694 1.416 1.551 1.416h38.66l-10.797 9.848Zm9.888 11.378c0 .782-.695 1.415-1.551 1.415H4.94l10.797 9.849a1.329 1.329 0 0 1 0 2 1.659 1.659 0 0 1-2.194 0L1.56 39.436c-1.413-1.289-1.413-3.38 0-4.669l11.983-10.93a1.659 1.659 0 0 1 2.194 0 1.329 1.329 0 0 1 0 2.002L4.94 35.685H43.6c.856 0 1.551.633 1.551 1.415Z"
      fill="currentColor"
    />
  </svg>
);

export default Comparison;
