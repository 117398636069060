import { createSlice } from "@reduxjs/toolkit";
import creditInsuranceApi from "./api";
import {RootState} from "../index";

const initialState: {
  screenList: any;
  screenListItem: any;
  choosedList: any;
  choosedListItem: any;
} = {
  screenList: null,
  screenListItem: null,
  choosedList: null,
  choosedListItem: null
};

const slice = createSlice({
  name: "creditInsurance",
  initialState,
  reducers: {
    setTestDriveEngine: (state, action) => {
      state.choosedList = action.payload;
    },
    setTestDriveModel: (state, action) => {
      state.choosedListItem = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      creditInsuranceApi.endpoints.getCreditInsuranceList.matchFulfilled,
      (state, action) => {
        state.screenList = action.payload.data;
      },
    ).addMatcher(
      creditInsuranceApi.endpoints.getCreditInsuranceListItem.matchFulfilled,
      (state, action) => {
        state.screenListItem = action.payload.data;
      },
    );
  },
});

export const { setTestDriveEngine, setTestDriveModel } =
    slice.actions;

export const getChoosedList = (state: RootState) =>
    state.creditInsurance.choosedList;
export const getChoosedListItem = (state: RootState) =>
    state.creditInsurance.choosedListItem;

export default slice.reducer;
