import * as React from 'react';
import { SVGProps } from 'react';

const Arrow = (
  props: SVGProps<SVGSVGElement> & {
    direction?: 'left' | 'right' | 'down' | 'up';
  },
) => (
  <div
    style={{
      transform: `rotate(${
        props.direction === 'down'
          ? '90deg'
          : props.direction === 'up'
          ? '-90deg'
          : props.direction === 'left'
          ? '180deg'
          : '0'
      })`,
    }}
  >
    <svg
      width={30}
      height={27}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      viewBox="0 0 60 54"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m33.797.439 25.76 25.502c.59.585.59 1.533 0 2.118l-25.76 25.502a1.524 1.524 0 0 1-2.14 0 1.487 1.487 0 0 1 0-2.117l23.179-22.947H1.513C.677 28.497 0 27.827 0 27c0-.827.677-1.497 1.513-1.497h53.323L31.657 2.556a1.487 1.487 0 0 1 0-2.117 1.524 1.524 0 0 1 2.14 0Z"
        fill="currentColor"
      />
    </svg>
  </div>
);

export default Arrow;
