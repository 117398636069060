import React from 'react';
import MostPopular from '../../../assets/svg/MostPopular';
import { SortByType } from '../../../types';
import { THEME } from '../../../utils/theme';
import { Button } from '../../common/Buttons';

import './Dropdown.scss';

function SortDropdown<T>({
  variants,
  onClickItem,
  currentItem,
}: {
  variants: Array<SortByType<T>>;
  onClickItem: (item: T) => void;
  currentItem: string;
}) {
  const [showDropdown, setShowDropdown] = React.useState(false);
  const toggleDropDown = () => setShowDropdown((prev) => !prev);

  return (
    <>
      {showDropdown && (
        <div
          className="background"
          style={{ zIndex: 3 }}
          onClick={() => setShowDropdown(false)}
        />
      )}
      <div className="dropdown">
        <Button
          type="rounded"
          width={'auto'}
          height={48}
          onClick={toggleDropDown}
          icon={(color) => <MostPopular color={color} />}
          small={true}
          paddingLeft={20}
          paddingRight={16}
          border={'2px solid var(--gray-4)'}
          backgroundColor={{
            default: THEME.colors.white,
            active: THEME.colors.white,
            disabled: THEME.colors.gray_4,
          }}
          textColor={{
            default: THEME.colors.gray_7,
            active: THEME.colors.primary_black,
            disabled: THEME.colors.white,
          }}
          iconColor={{
            default: THEME.colors.gray_7,
            active: THEME.colors.primary_black,
            disabled: THEME.colors.gray_4,
          }}
          borderColor={{
            active: THEME.colors.primary_black,
            default: THEME.colors.gray_6,
            disabled: THEME.colors.gray_4,
          }}
        >
          {currentItem}
        </Button>
        {showDropdown ? (
          <>
            <div className="dropdown__list">
              <ul>
                {variants.map((item) => (
                  <li
                    key={item.label}
                    onClick={() => {
                      onClickItem(item.by);
                      toggleDropDown();
                    }}
                    className={item.label === currentItem ? 'active' : ''}
                  >
                    {item.label}
                  </li>
                ))}
              </ul>
            </div>
          </>
        ) : null}
      </div>
    </>
  );
}

export default SortDropdown;
