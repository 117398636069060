import React, {useEffect} from "react";
import {Swiper as SwiperType} from "swiper";

export const useSwipePagination=(countOfPages:number,onFetchData?:(page:number)=>void)=>{
    const [swiper, setSwiper] = React.useState<SwiperType | null>(null);
    const [index, setIndex] = React.useState(0);
    useEffect(()=>{
        if(index>0){
            onFetchData && onFetchData(index+1)
        }
    },[index])
    const onSlideNext = React.useCallback(() => {
        if (index !== countOfPages) {
            swiper?.slideTo(index + 1);
        }
    }, [swiper,index]);
    const onSlidePrev = React.useCallback(() => {
        if (index !== 0) {
            swiper?.slideTo(index - 1);
        }
    }, [swiper,index]);
    return {
        onSlideNext,
        onSlidePrev,
        setSwiper,
        setIndex,
        index
    }
}
