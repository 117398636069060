import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import accessoriesApi from "./api";
import {RootState} from "@store/index";
import {InitialState} from "@store/accessories/types";
import {SortByType} from "../../types";
import {NewCarsSortVariants} from "@store/newCars/types";

export const sortVariants: {
  [name: string]: SortByType<NewCarsSortVariants>;
} = {
  'populars.asc': {
    by: 'populars.asc',
    label: 'Найпопулярніші',
  },
  'price.asc': {
    by: 'price.asc',
    label: 'Ціна: за зрозстанням',
  },
  'price.desc': {
    by: 'price.desc',
    label: 'Ціна: за спаданням',
  },
};

const initialState: InitialState = {
  carList: null,
  accessoriesList: null,
  sortBy: sortVariants['populars.asc'],
  defaultFilterValues: {
    types: [
      {
        label: 'Всі',
        name: 'sortBy',
        value: 'populars.asc'
      },
      {
        label: `Інтер'єр`,
        name: 'accessoryType[]',
        value: 'INT'
      },
      {
        label: `Екстер'єр`,
        name: 'accessoryType[]',
        value: 'EXT'
      },
      {
        label: 'Диски',
        name: 'isDisc',
        value: '1'
      },
    ]
  },
  filter: {
    types: [
      {
        label: 'Всі',
        name: 'sortBy',
        value: 'populars.asc'
      },
    ]
  },
  isResetFilter: true,
};

const slice = createSlice({
  name: "accessories",
  initialState,
  reducers: {
    setAccessoriesFilter: (state, action: any) => {
      state.filter = action.payload;
      state.isResetFilter = false;
    },
    resetAccessoriesFilters: (state) => {
      state.filter = {
        ...state.defaultFilterValues,
        types: [
          {
            label: 'Всі',
            name: 'sortBy',
            value: 'populars.asc'
          },
        ]
      };
      state.isResetFilter = true;
    },
    setSortAccessoriesBy: (state, action: PayloadAction<NewCarsSortVariants>) => {
      state.sortBy = sortVariants[action.payload];
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      accessoriesApi.endpoints.getAccessories.matchFulfilled,
      (state, action) => {
        state.carList = action.payload.data;
      },
    ).addMatcher(
      accessoriesApi.endpoints.getAccessoriesModelList.matchFulfilled,
      (state, action) => {
        state.accessoriesList = action.payload.data;
      },
    );
  },
});


export const { setAccessoriesFilter, resetAccessoriesFilters, setSortAccessoriesBy} =
  slice.actions;

export const selectAccessoriesCars = (state: RootState) => state.accessories.carList;
export const selectCarAccessories = (state: RootState) => state.accessories.accessoriesList;
export const selectAccessoriesSortBy = (state: RootState) => state.accessories.sortBy;
export const selectAccessoriesDefaultFilterValues = (state: RootState) =>
  state.accessories.defaultFilterValues;
export const selectAccessoriesFilterValues = (state: RootState) =>
  state.accessories.filter;

export const selectCountOfActiveAccessoriesFilters = (state: RootState) => {
  const { types } =
  state.accessories.filter;

  const typesNumber = Number(!!types.length);
  return (
    typesNumber
  );
};

export default slice.reducer;
