import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, store } from '..';
import { SortByType } from '../../types';
import newCarsApi from './api';
import { InitialState, NewCarsFilterType, NewCarsSortVariants } from './types';



export const sortVariants: {
  [name: string]: SortByType<NewCarsSortVariants>;
} = {
  'populars.asc': {
    by: 'populars.asc',
    label: 'Найпопулярніші',
  },
  'title.asc': {
    by: 'title.asc',
    label: 'Назва моделі',
  },
};

const initialState: InitialState = {
  defaultFilterValues: {
    types: [],
    engines: [],
    fuel_consumption: { from: 0, to: 1 },
    number_of_seats: { from: 0, to: 1 },
    power: { from: 0, to: 1 },
  },
  filter: {
    types: [],
    engines: [],
    fuel_consumption: { from: 0, to: 1 },
    number_of_seats: { from: 0, to: 1 },
    power: { from: 0, to: 1 },
  },
  choosedEngine: null,
  choosedPackage: null,
  isResetFilter: true,
  models: null,
  modelsCompare: null,
  sortBy: sortVariants['populars.asc'],
  firstCarCompare: null,
  secondCarCompare: null,
  thirdCarCompare: null,
  compareStatus: true,
  modificationId: null,
  modelId: null
};

const slice = createSlice({
  name: 'newCars',
  initialState,
  reducers: {
    setFilter: (state, action: PayloadAction<NewCarsFilterType>) => {
      state.filter = action.payload;
      state.isResetFilter = false;
    },
    resetFilters: (state) => {
      state.filter = {
        ...state.defaultFilterValues,
        types: [],
        engines: [],
      };
      state.isResetFilter = true;
    },
    setCompare: (state, action) => {
      state.compareStatus = action.payload;
    },
    resetCompare: (state, action) => {
      state.compareStatus = true;
    },
    setSortBy: (state, action: PayloadAction<NewCarsSortVariants>) => {
      state.sortBy = sortVariants[action.payload];
    },
    setEngine: (state, action) => {
      state.choosedEngine = action.payload;
    },
    setPackage: (state, action) => {
      state.choosedPackage = action.payload;
    },
    setFirstCompareCar: (state, action) => {
      state.firstCarCompare = action.payload;
    },
    setSecondCompareCar: (state, action) => {
      state.secondCarCompare = action.payload;
    },
    setThirdCompareCar: (state, action) => {
      state.thirdCarCompare = action.payload;
    },
    setNewCarsModels: (state, action) => {
      state.modelsCompare = action.payload;
    },
    setModificationId: (state, action) => {
      state.modificationId = action.payload;
    },
    setModelId: (state, action) => {
      state.modelId = action.payload;
    },
  },
  extraReducers: (buider) => {
    buider
      .addMatcher(
        newCarsApi.endpoints.getValuesForNewCarsFilter.matchFulfilled,
        (state, action) => {
          state.defaultFilterValues = action.payload;
          state.filter = {
            ...action.payload,
            types: [],
            engines: [],
          };
        },
      )
      .addMatcher(
        newCarsApi.endpoints.getNewCarsModels.matchFulfilled,
        (state, action) => {
          state.models = action.payload;
        },
      ).addMatcher(
        newCarsApi.endpoints.getComparisonModels.matchFulfilled,
        (state, action) => {
          state.modelsCompare = action.payload;
          state.compareStatus = true;
        },
      );
  },
});

export const {
  setFilter,
  resetFilters,
  setSortBy,
  setEngine,
  setPackage,
  setFirstCompareCar,
  setSecondCompareCar,
  setThirdCompareCar,
  setNewCarsModels,
  setCompare,
  resetCompare,
  setModificationId,
  setModelId
} =
  slice.actions;

export const modificationId = (state: RootState) => state.newCars.modificationId;
export const modelId = (state: RootState) => state.newCars.modelId;

export const selectChoosedEngine = (state: RootState) =>
  state.newCars.choosedEngine;

export const selectedFirstCompareCar = (state: RootState) =>
  state.newCars.firstCarCompare;
export const selectedSecondCompareCar = (state: RootState) =>
  state.newCars.secondCarCompare;
export const selectedThirdCompareCar = (state: RootState) =>
  state.newCars.thirdCarCompare;
export const selectChoosedPackage = (state: RootState) =>
  state.newCars.choosedPackage;
export const selectNewCarsSortBy = (state: RootState) => state.newCars.sortBy;
export const selectNewCarsModels = (state: RootState) => state.newCars.models;
export const selectModelsCompare = (state: RootState) => state.newCars.modelsCompare;
export const selectDefaultFilterValues = (state: RootState) =>
  state.newCars.defaultFilterValues;
export const selectNewCarsFilter = (state: RootState) => state.newCars.filter;
export const selectIsResetFilterFilter = (state: RootState) =>
  state.newCars.isResetFilter;
export const selectCountOfActiveFilters = (state: RootState) => {
  const { engines, fuel_consumption, number_of_seats, power, types } =
    state.newCars.filter;
  const {
    fuel_consumption: defaultFuelConsumption,
    number_of_seats: defaultNumberOfSeats,
    power: defaultPower,
  } = state.newCars.defaultFilterValues;

  const typesNumber = Number(!!types.length);
  const enginesNumber = Number(!!engines.length);
  const fuelConsumptionNumber = Number(
    fuel_consumption.from !== defaultFuelConsumption.from ||
      fuel_consumption.to !== defaultFuelConsumption.to,
  );
  const numberOfSeatsNumber = Number(
    number_of_seats.from !== defaultNumberOfSeats.from ||
      number_of_seats.to !== defaultNumberOfSeats.to,
  );
  const powerNumber = Number(
    power.from !== defaultPower.from || power.to !== defaultPower.to,
  );

  return (
    typesNumber +
    enginesNumber +
    fuelConsumptionNumber +
    numberOfSeatsNumber +
    powerNumber
  );
};

export default slice.reducer;
