export const getUrl = (templatePageName: string) => {
  let url;

  const URL_LIST: any = [
    {
      template: 'new_cars_main_page',
      url: '/new-cars',
    },
    {
      template: 'configurator_main',
      url: '/configurator',
    },
    {
      template: 'service_main_page',
      url: '/service',
    },
    {
      template: 'used_cars_categories',
      url: '/used-cars',
    },
    {
      template: 'promotions_main_page',
      url: '/promotions',
    },
    {
      template: 'test_drive_main',
      url: '/test-drive',
    },
    {
      template: 'trade_in',
      url: '/trade-in',
    },
    {
      template: 'promotions_main_page',
      url: '/promotions',
    },
    {
      template: 'lending_and_insurance_main_page',
      url: '/credit-and-insurance',
    },
    {
      template: 'service_information',
      url: '/service/service-info'
    },
    {
      template: 'service_model_detail',
      url: '/'
    },
    {
      template: 'service_model_years_select',
      url: '/'
    },
    {
      template: 'service_models',
      url: '/'
    },
    {
      template: 'privacy_police',
      url: '/'
    },
    {
      template: 'used_cars_category_details',
      url: '/'
    },
    {
      template: 'used_cars_car_detail',
      url: '/'
    },
    {
      template: 'new_cars_models',
      url: '/'
    },
    {
      template: 'model_engine_selector',
      url: '/'
    },
    {
      template: 'model_completion_selector',
      url: '/'
    },
    {
      template: 'new_car_product',
      url: '/'
    },
    {
      template: 'model_description',
      url: '/'
    },
    {
      template: 'lending_page',
      url: '/'
    },
    {
      template: 'insurance_page',
      url: '/insurance'
    },
    {
      template: 'new_cars_available_at_dealer',
      url: '/new-cars/stock-cars'
    },
    {
      template: 'maintenance',
      url: '/service/maintenance'
    },
    {
      template: '',
      url: '/'
    },
    {
      template: '',
      url: '/'
    },
    {
      template: '',
      url: '/'
    },
  ];

  if (templatePageName) {
    url = URL_LIST.filter(
      (template: any) => template.template === templatePageName,
    )[0]?.url;
  }

  return url;
};
