import * as React from 'react';

const Comparison = (props) => (
  <svg
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 80 80"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M40.001 52.49c10.054 0 18.204-8.15 18.204-18.204 0-10.053-8.15-18.204-18.204-18.204s-18.204 8.15-18.204 18.204S29.947 52.49 40 52.49Zm10.459.246c6.417-3.646 10.745-10.542 10.745-18.45 0-11.71-9.493-21.204-21.204-21.204-11.71 0-21.204 9.494-21.204 21.204 0 7.907 4.328 14.804 10.744 18.45a1.497 1.497 0 0 0-.098.533v8.506c0 3.016 3.561 4.62 5.82 2.62l4.406-3.898a.5.5 0 0 1 .663 0l4.406 3.899c2.258 1.999 5.82.395 5.82-2.621v-8.506c0-.188-.035-.367-.098-.533Zm-2.903 1.369a21.154 21.154 0 0 1-7.556 1.386c-2.662 0-5.21-.491-7.558-1.387v7.67a.5.5 0 0 0 .832.375l4.406-3.899a3.5 3.5 0 0 1 4.639 0l4.406 3.9a.5.5 0 0 0 .831-.375v-7.67Zm-7.556-27.51-.515 1.583a5 5 0 0 1-4.755 3.455h-1.665l1.347.979a5 5 0 0 1 1.816 5.59l-.514 1.584 1.347-.979a5 5 0 0 1 5.878 0l1.347.979-.515-1.584a5 5 0 0 1 1.817-5.59l1.347-.979H45.27a5 5 0 0 1-4.756-3.455l-.514-1.583Zm1.902-3.854c-.599-1.843-3.206-1.843-3.804 0l-1.466 4.51a2 2 0 0 1-1.902 1.382h-4.743c-1.937 0-2.743 2.48-1.175 3.618l3.837 2.788a2 2 0 0 1 .726 2.236l-1.465 4.51c-.6 1.843 1.51 3.376 3.077 2.237l3.837-2.788a2 2 0 0 1 2.351 0l3.837 2.788c1.568 1.139 3.677-.394 3.078-2.236l-1.466-4.51a2 2 0 0 1 .727-2.237l3.837-2.788c1.567-1.139.762-3.618-1.176-3.618h-4.742a2 2 0 0 1-1.903-1.382l-1.465-4.51Z"
      fill="#282830"
    />
  </svg>
);

export default Comparison;
