import axios from 'axios';
import { API_CONFIG } from '../constants';

const api = axios.create({
  baseURL: API_CONFIG.BASE_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  timeout: 30000,
});

api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    config.headers['Authorization'] = 'Bearer ' + token;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

api.interceptors.response.use(
  (response) => {
    const { status } = response;
    if (status === 200 || status === 201) {
      return response;
    }
  },
  (error) => {
    console.log('errorAPI', error);
    const { status, statusText } = error.response;
    if (status === 500) {
    }
    if (status === 401) {
      localStorage.removeItem('token');
      // window.location = '/';
    }
    return Promise.reject(error);
  },
);

export default api;
