import * as React from 'react';

const Messenger = (props) => (
  <svg
    width={48}
    height={59}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 96 97"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M48 8.483c-23.218 0-41.212 16.92-41.212 39.772 0 11.953 4.925 22.282 12.944 29.418a3.296 3.296 0 0 1 1.11 2.334l.225 7.293c.075 2.326 2.488 3.836 4.627 2.904l8.176-3.589a3.304 3.304 0 0 1 2.198-.165c3.756 1.032 7.761 1.576 11.932 1.576 23.218 0 41.213-16.92 41.213-39.771C89.213 25.403 71.218 8.483 48 8.483Z"
      fill="#0075FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m22.466 60.084 12.108-19.08c1.924-3.031 6.054-3.789 8.94-1.639l9.628 7.175c.887.66 2.098.651 2.977-.008l13.003-9.803c1.734-1.31 4.006.758 2.837 2.587l-12.1 19.07c-1.924 3.032-6.054 3.79-8.94 1.64l-9.628-7.175a2.489 2.489 0 0 0-2.977.008l-13.012 9.812c-1.733 1.31-4.005-.758-2.836-2.587Z"
      fill="#fff"
    />
  </svg>
);

export default Messenger;
