import React from 'react';
import styles from './style.module.scss';

type TabProps = {
  onChangeTab: (id: any) => void;
  active: any;
  list: {
    id: number;
    name: string;
    icon: any;
    url?: string;
  }[];
};

const TabComponent = ({ onChangeTab, active, list }: TabProps) => {
  return (
    <div className={styles.wrapperTab}>
      {list.map((item) => {
        return (
          <button
            className={
              active?.id === item?.id
                ? `${styles.wrapperTabItem} ${styles.active}`
                : styles.wrapperTabItem
            }
            onClick={() => onChangeTab(item)}
          >
            <div className={styles.wrapperIcon}>
              <img
                  src={item?.icon}
                  alt={'Messenger'}
              />
            </div>
            <p className={styles.label}>{item?.name}</p>
          </button>
        );
      })}
    </div>
  );
};
export default TabComponent;
