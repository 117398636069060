import * as React from 'react';

const PlayIcon = (props) => (
  <svg
    width={96}
    height={96}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 96 96"
    {...props}
  >
    <path fillRule="evenodd" clipRule="evenodd" d="M92.8 48C92.8 72.7424 72.7424 92.8 48 92.8C23.2576 92.8 3.2 72.7424 3.2 48C3.2 23.2576 23.2576 3.2 48 3.2C72.7424 3.2 92.8 23.2576 92.8 48ZM96 48C96 74.5097 74.5097 96 48 96C21.4903 96 0 74.5097 0 48C0 21.4903 21.4903 0 48 0C74.5097 0 96 21.4903 96 48ZM44.9608 33.9863L58.9743 47.9998L44.9608 62.0133V33.9863ZM61.237 45.737L47.2235 31.7235C45.2076 29.7076 41.7608 31.1354 41.7608 33.9863V62.0133C41.7608 64.8642 45.2076 66.2919 47.2235 64.276L61.237 50.2625C62.4867 49.0128 62.4867 46.9867 61.237 45.737Z" fill="white"/>
  </svg>
);

export default PlayIcon;
