import { createSlice } from '@reduxjs/toolkit';
import { InitialState } from './types';
import getCarsListForServiceApi from './api';
import { RootState } from '..';

const initialState: InitialState = {
  defaultFilterValues: {
    drive: '',
    fuel: '',
    transmission: '',
    volume: '',
  },
  filter: {
    drive: '',
    fuel: '',
    transmission: '',
    volume: '',
  },
  techMapData: {
    range: {
      title: '',
      steps: [],
    },
  },
  techMapTabsData: {},
  dealerInfo: {
    city_id: null,
    code: '',
    email: '',
    id: null,
  },
  isResetFilter: true,
  models: null,
  years: null,
  modifications: null,
  choosedEngine: null,
};
const slice = createSlice({
  name: 'carsModelForService',
  initialState,
  reducers: {
    setEngine: (state, action) => {
      state.choosedEngine = action.payload;
    },
    setFilter: (state, action) => {
      state.filter = action.payload;
      // state.defaultFilterValues = action.payload;
      state.isResetFilter = false;
    },
    resetFilters: (state) => {
      state.filter = {
        ...state.defaultFilterValues,
      };
      state.isResetFilter = true;
    },
    getTechMapData: (state, action) => {
      state.techMapData = action.payload;
    },
    setDealerinfoData: (state, action) => {
      state.dealerInfo = action.payload;
    },
    setTechMapTabsData: (state, action) => {
      state.techMapTabsData = action.payload;
    },
  },
  extraReducers: (buider) => {
    buider.addMatcher(
      getCarsListForServiceApi.endpoints.getCarsForService.matchFulfilled,
      (state, action) => {
        state.models = action.payload;
      },
    );
    buider.addMatcher(
      getCarsListForServiceApi.endpoints.getYears.matchFulfilled,
      (state, action) => {
        state.years = action.payload;
      },
    );
    buider.addMatcher(
      getCarsListForServiceApi.endpoints.getEngineList.matchFulfilled,
      (state, action) => {
        state.modifications = action.payload;
      },
    );
  },
});

export const {
  setEngine,
  setFilter,
  resetFilters,
  getTechMapData,
  setDealerinfoData,
  setTechMapTabsData,
} = slice.actions;

export const carsForService = (state: RootState) => state.carsForService.models;
export const chooseYear = (state: RootState) => state.carsForService.years;
export const chooseEngine = (state: RootState) =>
  state.carsForService.modifications;
export const selectChoosedEngine = (state: RootState) =>
  state.carsForService.choosedEngine;
export const techDataMap = (state: RootState) =>
  state.carsForService.techMapData;
export const dealerInfoData = (state: RootState) =>
  state.carsForService.dealerInfo;

export const selectDefaultFilterValues = (state: RootState) =>
  state.carsForService.defaultFilterValues;
export const selectServiceCarsFilter = (state: RootState) =>
  state.carsForService.filter;
export const selectIsResetFilterFilter = (state: RootState) =>
  state.carsForService.isResetFilter;
export const techMapTabsData = (state: RootState) =>
  state.carsForService.techMapTabsData;

export default slice.reducer;
