import React, { PropsWithChildren } from 'react';
import { useSelector } from 'react-redux';
import { selectIsShowFullSizeMenu } from 'store/menu/slice';
import { ScreenContentLayout } from '../../common';
import './styles.scss';

type Props = {
  left?: number;
};

const AbsoluteCardsContainer: React.FC<PropsWithChildren<Props>> = ({
  children,
  left,
}) => {
  const isShowFullSizeMenu = useSelector(selectIsShowFullSizeMenu);
  return (
    // <ScreenContentLayout
    //   classes="absolute-container"
    //   bgTransparent
    //   styles={{
    //     width: 2592,
    //     left: 3890,
    //   }}
    // >
    <div
      style={{
        position: 'absolute',
        left: left ? left : isShowFullSizeMenu ? 1315 : 1640,
        display: 'flex',
        flexWrap: 'wrap',
        width: 2592,
      }}
    >
      {children}
      {/* </ScreenContentLayout> */}
    </div>
  );
};

export default AbsoluteCardsContainer;
