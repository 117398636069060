import { BaseResponse } from 'types';
import { api } from '../api';
import { DealersResponse, MenuRequest, ZonesResponse } from './types';

const dealerApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getDealerList: builder.query<BaseResponse<DealersResponse>, {}>({
      query: () => ({
        url: `/api/v1/dealers/`,
      }),
    }),
    getZonesForDealer: builder.query<
      BaseResponse<ZonesResponse>,
      { dealer_code: string | undefined | null | void }
    >({
      query: ({ dealer_code }) => ({
        url: `/api/v1/dealers/${dealer_code}/zones`,
      }),
    }),
    getScreensForDealer: builder.query<
      BaseResponse<ZonesResponse>,
      { dealer_code: string | undefined | null | void, zone: string | undefined | null | void }
    >({
      query: ({ dealer_code, zone }) => ({
        url: `/api/v1/dealers/${dealer_code}/screens${zone ? `/${zone}` : ''}`,
      }),
    }),
    getMenu: builder.query<BaseResponse<ZonesResponse>, MenuRequest>({
      query: ({ dealer_code, zone }) => ({
        url: `/api/v1/dealers/${dealer_code}/menus${zone ? `/${zone}` : ''}`,
      }),
    }),
  }),
});

export const {
  useGetDealerListQuery,
  useGetZonesForDealerQuery,
  useGetScreensForDealerQuery,
  useGetMenuQuery,
} = dealerApi;

export default dealerApi;
