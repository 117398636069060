import React, { useState, useEffect } from 'react';
import { useController } from 'react-hook-form';
import classNames from 'classnames/bind';
import ReactSelect from 'react-select';
import CustomSelect from '@components/CustomSelect';

import styles from './style.module.scss';
const cx = classNames.bind(styles);

const customStyles = {
  control: (styles) => ({
    ...styles,
    border: 'none',
    boxShadow: 'none',
    textAlign: 'left',
    '&:hover': {
      boxShadow: '0 0 0 1px var(--gray-4)!important',
    },
    '&:active': {
      boxShadow: '0 0 0 1px var(--gray-4)!important',
    },
    '&:focus': {
      boxShadow: '0 0 0 1px var(--gray-4)!important',
    },
    minHeight: '56px',
  }),
  indicatorSeparator: (styles) => ({ ...styles, display: 'none' }),
  placeholder: (styles) => ({
    ...styles,
    fontSize: '16px',
    color: 'var(--gray-7)',
    textAlign: 'left',
    textTransform: 'initial',
  }),
  valueContainer: (styles) => ({
    ...styles,
    padding: '7px 20px',
  }),
  singleValue: (styles) => ({
    ...styles,
    lineHeight: 'normal',
    fontSize: '16px',
    color: 'var(--gray-7)',
  }),
  option: (styles, { isSelected, isFocused, isActive }) => ({
    ...styles,
    fontSize: '16px',
    backgroundColor: isSelected ? 'var(--gray-1)' : '#ffffff',
    color: isSelected ? 'var(--primary-black)' : 'var(--gray-7)',
    zIndex: 999,
    padding: '10px 20px',
    textAlign: 'left',
    borderBottom: '1px solid var(--gray-4)',
    '&:last-child': {
      borderBottom: 'none',
    },
    '&:active': {
      backgroundColor: 'var(--gray-1)',
    },
  }),
  menuList: (styles) => ({
    ...styles,
    width: '100%',
    border: '1px solid var(--gray-4)',
    textAlign: 'left',
    maxHeight: '300px',
    paddingBottom: '0px',
    paddingTop: '0px',
    '::-webkit-scrollbar': {
      width: '4px',
      height: '0px',
    },
    '::-webkit-scrollbar-track': {
      background: 'var(--white)',
    },
    '::-webkit-scrollbar-thumb': {
      background: 'var(--primary-red)',
    },
    '::-webkit-scrollbar-thumb:hover': {
      background: 'var(--primary-red)',
    },
  }),
  dropdownIndicator: (styles) => ({
    ...styles,
    marginRight: '23px',
    svg: {
      fill: 'var(--primary-black)',
      width: '15px',
      height: '15px',
    },
  }),
  clearIndicator: (styles) => ({
    ...styles,
    fontSize: '16px',
    svg: {
      width: '25px',
      height: '25px',
    },
  }),
};

const Select = (props) => {
  const { field } = useController(props);
  const [focus, setFocus] = useState(false);

  const required = props?.rules?.required;

  useEffect(() => {
    if (field.value) {
      setFocus(true);
    }
  }, [field.value]);

  return (
    <div className={props.disabled ? cx('disabled') : ''}>
      <div
        className={props.errors ? cx('selectWrap', 'error') : cx('selectWrap')}
      >
        {/* <ReactSelect
          {...field}
          {...props}
          styles={customStyles}
          options={props.options}
          isMulti={props.isMulti}
          classNamePrefix="select"
          className={focus ? cx('select', 'focus') : cx('input')}
          disabled={props.disabled}
          noOptionsMessage={() => 'Дані відсутні'}
          onChange={(e) => {
            field.onChange(e);
            if (props.onChange) {
              props.onChange(e);
            }
          }}
          placeholder={
            <div>
              {props.label}
              {required ? <span className={cx('required')}>*</span> : ''}
            </div>
          }
        /> */}
        <CustomSelect
          name="type"
          options={props.options}
          optValueKey={props.optValueKey}
          optLabelKey={props.optLabelKey}
          selectedValueKey={props.selectedValueKey}
          selectedOpt={
            field.value ? field.value[props.optValueKey] : field.value
          }
          isClerable={props.isClerable}
          onChange={(e) => {
            field.onChange(e);
            if (props.onChange) {
              props.onChange(e);
            }
          }}
          placeholder={props.label}
          menuPlacement={props.menuPlacement}
          required={props.required}
          // @ts-ignore
          // error={errors.type}
          // errorText={getTranslateText(translateArr, 'validation.required')}
        />
        {props.errors && (
          <p className={cx('errorName')}>{props.errors?.message}</p>
        )}
      </div>
    </div>
  );
};

export default Select;
