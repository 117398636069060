import * as React from 'react';

const Shutdown = (props) => (
  <svg
    width={40}
    height={40}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 78 80"
    {...props}
  >
    <path
      d="M29.285 15.181c-5.985 2.425-10.925 6.804-13.979 12.39a26.855 26.855 0 0 0-2.768 18.252c1.264 6.213 4.692 11.806 9.699 15.825 5.008 4.02 11.285 6.216 17.763 6.216 6.478 0 12.755-2.197 17.763-6.216 5.007-4.019 8.435-9.612 9.699-15.825a26.855 26.855 0 0 0-2.768-18.251c-3.054-5.587-7.994-9.966-13.979-12.39"
      stroke="currentColor"
      strokeWidth={3}
      strokeLinecap="round"
    />
    <path
      d="M38.5 10.79c0-.808.672-1.463 1.5-1.463s1.5.655 1.5 1.464v30.244c0 .808-.672 1.463-1.5 1.463s-1.5-.655-1.5-1.463V10.79Z"
      fill="currentColor"
    />
  </svg>
);

export default Shutdown;
