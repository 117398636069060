import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '..';
import { InitialState } from './types';

const initialState: InitialState = {
  isShowConsultModal: false,
  isShowBrochureBotsModal: false,
  isShowBrochurePdfModal: false,
  openedFrom: null
};

const slice = createSlice({
  name: 'modals',
  initialState,
  reducers: {
    showConsultModal: (state) => {
      state.isShowConsultModal = true;
    },
    hideConsultModal: (state) => {
      state.isShowConsultModal = false;
    },
    setOpenedFrom: (state, action) => {
      state.openedFrom = action.payload
    }
  },
});

export const { showConsultModal, hideConsultModal, setOpenedFrom } = slice.actions;

export const selectIsShowConsultModal = (state: RootState) =>
  state.modals.isShowConsultModal;
export const selectIsShowBrochureBotsModal = (state: RootState) =>
  state.modals.isShowBrochureBotsModal;
export const selectIsShowBrochurePdfModal = (state: RootState) =>
  state.modals.isShowBrochurePdfModal;
export const selectOpenedFrom = (state: RootState) =>
  state.modals.openedFrom;

export default slice.reducer;
