import { BaseResponse } from '../../types';
import { api } from '../api';
import {
  GetFilterValuesRespone,
  GetModelComplectationsRequest,
  GetModelComplectationsResponse,
  GetModelEnginesRequest,
  GetModelEnginesResponse,
  GetConfiguratorCarModelsRequest,
  GetConfiguratorCarsModelsResponse,
  ConfiguratorCarDetailResponse,
  ConfiguratorCarDetailRequest,
  GetConfiguratorCarPricesRequest,
  GetConfiguratorCarPricesResponse,
  GetModelOptionsRequest,
  GetModelOptionsResponse,
  ConfiguratorColorsRequest,
  ConfiguratorColorsResponse,
  GetModelAccessoriesRequest,
  GetModelAccessoriesResponse,
  SaveConfiguratorRequest,
  MyConfigurationsRequest,
  GetModificationsEngineCompletionsRequest,
  GetStyledCarImagesRequest,
  ConfiguratorDiscListResponse,
  ConfiguratorDiscListRequest,
  ResultConfigurationRequest,
  GetQrCodeRequest,
  GetConfigurationCarRequest, EditConfiguratorRequest, ResultCargoRequest,
} from './types';
import {NewCarDetailRequest, NewCarDetailResponse} from "../newCars/types";

const configuratorCarsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getValuesForConfiguratorCarsFilter: builder.query<
      GetFilterValuesRespone,
      { dealer_dealer_code: string }
    >({
      query: ({ dealer_dealer_code }) => ({
        url: `/api/v1/dealers/${dealer_dealer_code}/new-cars/filters`,
      }),
    }),
    getConfiguratorCarsModels: builder.query<
      BaseResponse<GetConfiguratorCarsModelsResponse>,
        GetConfiguratorCarModelsRequest
    >({
      query: ({
        dealer_dealer_code,
        sortBy,
        page = 1,
        perPage = 50,
        filter,
      }) => {
        let paramsString = new URLSearchParams();
        if (filter) {
          paramsString = new URLSearchParams({
            'number_of_seats[from]': filter?.number_of_seats.from.toString(),
            'number_of_seats[to]': filter?.number_of_seats.to.toString(),
            'power[from]': filter?.power.from.toString(),
            'power[to]': filter?.power.to.toString(),
            'fuel_consumption[from]': filter?.fuel_consumption.from.toString(),
            'fuel_consumption[to]': filter?.fuel_consumption.to.toString(),
          });

          filter.types.length &&
            filter.types.map((item) =>
              paramsString.append('type[]', item.value!.toString()),
            );
          filter.engines.length &&
            filter.engines.map((item) =>
              paramsString.append('engine[]', item.value.toString()),
            );
        }

        sortBy &&
          sortBy !== 'most_popular' &&
          paramsString.append('sortBy', sortBy);
          paramsString.append('perPage', perPage.toString());
        return {
          url: `/api/v1/dealers/${dealer_dealer_code}/configurator/models?${paramsString}`,
        };
      },
    }),

    getModelConfiguratorEngines: builder.query<
      GetModelEnginesResponse,
      GetModelEnginesRequest
    >({
      query: ({ dealer_dealer_code, vehicleModel_id }) => ({
        url: `api/v1/dealers/${dealer_dealer_code}/configurator/model/${vehicleModel_id}/engines`,
      }),
    }),
    getModelConfiguratorComplectations: builder.query<
      GetModelComplectationsResponse,
      GetModelComplectationsRequest
    >({
      query: ({
        dealer_dealer_code,
        vehicleModel_id,
        vehicleEngine_id,
        vehicleTransmission_id,
        vehicleWheelDrive_id
      }) => ({
        url: `api/v1/dealers/${dealer_dealer_code}/configurator/model/${vehicleModel_id}/completions/engine/${vehicleEngine_id}/transmission/${vehicleTransmission_id}/wheel-drive/${vehicleWheelDrive_id}`,
      }),
    }),
    getModelCargoConfiguratorComplectations: builder.query<
      GetModelComplectationsResponse,
      GetModelComplectationsRequest
    >({
      query: ({
        dealer_dealer_code,
        vehicleModel_id,
        vehicleEngine_id,
        vehicleBody_id,
      }) => ({
        url: `api/v1/dealers/${dealer_dealer_code}/configurator/model/${vehicleModel_id}/completions/body/${vehicleBody_id}/specification/${vehicleEngine_id}/`,
      }),
    }),
    getModelConfiguratorOptions: builder.query<
        GetModelOptionsResponse,
        GetModelOptionsRequest
        >({
      query: ({
                dealer_dealer_code,
                vehicleModel_id,
                vehicleModification_id
              }) => ({
        url: `/api/v1/dealers/${dealer_dealer_code}/configurator/model/${vehicleModel_id}/modifications/${vehicleModification_id}/packages`,
      }),
    }),
    getModificationsEngineCompletions: builder.query<
        any,
        GetModificationsEngineCompletionsRequest
        >({
      query: ({
                dealer_dealer_code,
                vehicleModel_id,
                vehicleEngine_id,
                vehicleTransmission_id,
                vehicleWheelDrive_id,
                vehicleCompletion_id
              }) => ({
        url: `api/v1/dealers/${dealer_dealer_code}/configurator/model/${vehicleModel_id}/${vehicleCompletion_id}/modifications/engine/${vehicleEngine_id}/transmission/${vehicleTransmission_id}/wheel-drive/${vehicleWheelDrive_id}`,
      }),
    }),
    getModelConfiguratorAccessories: builder.query<
        GetModelAccessoriesResponse,
        GetModelAccessoriesRequest
        >({
      query: ({
                dealer_dealer_code,
                vehicleModel_id,
                vehicleModification_id,
                page,
                perPage=4
              }) => ({
        url: `/api/v1/dealers/${dealer_dealer_code}/configurator/model/${vehicleModel_id}/modifications/${vehicleModification_id}/accessories`,
        method: `GET`,
        params: { page,perPage },
      }),
    }),

    getConfiguratorCarDetail: builder.query<ConfiguratorCarDetailResponse, ConfiguratorCarDetailRequest>({
      query: ({
        dealer_dealer_code,
        vehicleModel_id,
        vehicleCompletion_id,
      }) => ({
        url: `api/v1/dealers/${dealer_dealer_code}/configurator/model/${vehicleModel_id}/completion/${vehicleCompletion_id}`,
      }),
    }),
    getConfiguratorCarPrices: builder.query<
        GetConfiguratorCarPricesResponse,
        GetConfiguratorCarPricesRequest
    >({
      query: ({ dealer_dealer_code, vehicleModel_id }) => ({
        url: `api/v1/dealers/${dealer_dealer_code}/configurator/model/${vehicleModel_id}/prices`,
      }),
    }),
    saveConfiguratorResults: builder.mutation({
      query: ({data,dealer_dealer_code}) => ({
        url: `api/v1/dealers/${dealer_dealer_code}/configurator`,
        method: `POST`,
        body: { ...data },
      }),
    }),
    editConfiguratorResults: builder.mutation({
      query: ({data,dealer_dealer_code,configuration_id}) => ({
        url: `api/v1/dealers/${dealer_dealer_code}/configurator/${configuration_id}`,
        method: `PUT`,
        body: { ...data },
      }),
    }),
    getStyledCarImages: builder.mutation<any,GetStyledCarImagesRequest>({
      query: ({data,dealer_dealer_code,vehicleModel_id,vehicleModification_id}) => ({
        url: `api/v1/dealers/${dealer_dealer_code}/configurator/model/${vehicleModel_id}/modifications/${vehicleModification_id}/color/images`,
        method: `POST`,
        body: { ...data },
      }),
    }),
    getConfiguratorColor: builder.query<ConfiguratorColorsResponse, ConfiguratorColorsRequest>({
      query: ({
                dealer_dealer_code,
                vehicleModel_id,
                vehicleModification_id,
              }) => ({
        url: `api/v1/dealers/${dealer_dealer_code}/configurator/model/${vehicleModel_id}/modifications/${vehicleModification_id}/colors`,
      }),
    }),
    getDiscList: builder.query<ConfiguratorDiscListResponse, ConfiguratorDiscListRequest>({
      query: ({
                dealer_dealer_code,
                vehicleModel_id,
                vehicleModification_id
              }) => ({
        url: `api/v1/dealers/${dealer_dealer_code}/configurator/model/${vehicleModel_id}/modifications/${vehicleModification_id}/discs`,
      }),
    }),
    getMyConfigurations: builder.query<any, MyConfigurationsRequest>({
      query: ({
                dealer_dealer_code,
                userSessionId
              }) => ({
        url: `api/v1/dealers/${dealer_dealer_code}/configurator/list/${userSessionId}`,
      }),
    }),
    getResultConfiguration: builder.query<any, ResultConfigurationRequest>({
      query: ({
                dealer_dealer_code,
                vehicleModel_id,
                vehicleCompletion_id,
                vehicleModification_id
              }) => ({
        url: `api/v1/dealers/${dealer_dealer_code}/configurator/model/${vehicleModel_id}/${vehicleCompletion_id}/modifications/${vehicleModification_id}`,
      }),
    }),
    getCargoConfiguration: builder.query<any, ResultCargoRequest>({
      query: ({
                dealer_dealer_code,
                vehicleModel_id,
                vehicleBody_id,
                vehicleSpecification_id
              }) => ({
        url: `api/v1/dealers/${dealer_dealer_code}/configurator/model/${vehicleModel_id}/completions/body/${vehicleBody_id}/specification/${vehicleSpecification_id}`,
      }),
    }),
    getCargoBodyConfiguration: builder.query<any, ResultCargoRequest>({
      query: ({
                dealer_dealer_code,
                vehicleModel_id,
              }) => ({
        url: `api/v1/dealers/${dealer_dealer_code}/configurator/model/${vehicleModel_id}/bodies`,
      }),
    }),
    getQrCode: builder.query<any, GetQrCodeRequest>({
      query: ({dealer_dealer_code, configurator_id, qrCodeType}) => ({
        url: `api/v1/dealers/${dealer_dealer_code}/${qrCodeType}/qr-code/${configurator_id}`,
      }),
    }),
    getMyConfigurationCar: builder.query<any, GetConfigurationCarRequest>({
      query: ({dealer_dealer_code, configurator_id}) => ({
        url: `api/v1/dealers/${dealer_dealer_code}/configurator/${configurator_id}`,
      }),
    }),
  }),
});

export const {
  useGetValuesForConfiguratorCarsFilterQuery,
  useGetConfiguratorCarsModelsQuery,
  useGetModelConfiguratorEnginesQuery,
  useGetModelConfiguratorComplectationsQuery,
  useGetModelCargoConfiguratorComplectationsQuery,
  useGetConfiguratorCarDetailQuery,
  useGetConfiguratorCarPricesQuery,
  useGetModelConfiguratorOptionsQuery,
  useGetConfiguratorColorQuery,
  useGetModelConfiguratorAccessoriesQuery,
  useLazyGetModelConfiguratorAccessoriesQuery,
  useSaveConfiguratorResultsMutation,
  useEditConfiguratorResultsMutation,
  useGetMyConfigurationsQuery,
  useLazyGetMyConfigurationsQuery,
  useGetModificationsEngineCompletionsQuery,
  useGetStyledCarImagesMutation,
  useGetDiscListQuery,
  useGetResultConfigurationQuery,
  useGetQrCodeQuery,
  useGetMyConfigurationCarQuery,
  useGetCargoConfigurationQuery,
  useGetCargoBodyConfigurationQuery
} = configuratorCarsApi;

export default configuratorCarsApi;
