import * as React from 'react';

const Settings = (props) => (
  <svg
    width="40"
    height="40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 80 80"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M47.5 28.72a6.503 6.503 0 0 0 6.326-5H64a1.5 1.5 0 0 0 0-3H53.826a6.503 6.503 0 0 0-12.652 0H16a1.5 1.5 0 1 0 0 3h25.174a6.503 6.503 0 0 0 6.326 5Zm0-3a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7Z"
      fill="#282830"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 56.72a1.5 1.5 0 1 0 0 3h25.174a6.503 6.503 0 0 0 12.652 0H64a1.5 1.5 0 0 0 0-3H53.826a6.503 6.503 0 0 0-12.652 0H16Zm31.5 5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7Z"
      fill="#282830"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M64 41.72a1.5 1.5 0 0 0 0-3H38.826a6.503 6.503 0 0 0-12.652 0H16a1.5 1.5 0 1 0 0 3h10.174a6.503 6.503 0 0 0 12.652 0H64Zm-35-1.5a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0Z"
      fill="#282830"
    />
  </svg>
);

export default Settings;
