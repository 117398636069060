import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';

import FormInput from 'components/FormInput';
import { useForm } from 'react-hook-form';
import CheckBoxInput from 'components/CheckBoxInput';
import Select from 'components/Select';
import PhoneInput from '@components/PhoneInput';
import { ModalCloseButton } from 'components/common/Buttons';
import classNames from 'classnames/bind';

import styles from './style.module.scss';
import { sendServiceRequest } from 'api/service';
import {
  sendConsultationRequest,
  getConsultationTypes,
} from 'api/consultation';
import { useLocation, useParams } from 'react-router-dom';
import { useGetModalSettingsQuery } from 'store/modals/api';
import { useGetScreenDataQuery } from 'store/screens/api';
import { GetScreenDataResponse } from 'store/screens/types';
import { ScreenTitle } from 'components/common';
import { useDispatch, useSelector } from 'react-redux';
import {
  is2sDealer,
  selectedScreen,
  selectedZoneId,
  selectedZone,
} from '@store/dealers/slice';
import { translationsDataArr } from '@store/translate/slice';
import getTranslateText from '@utils/getTranslateText';
import { checkIs2sDealer } from '@utils/checkIs2sDealer';
import { dealerInfoData } from '@store/service/slice';
import { selectOpenedFrom } from '@store/modals/slice';
import { getConsultationAdditionalText } from '@api/modal';
import { checkPageForConsultationFor2s } from '@utils/checkPageForConsultationFor2s';
import CustomSelect from '@components/CustomSelect';
import { modificationId, setModificationId } from '@store/newCars/slice';
import { consultationModalDataObj } from '@store/consultationModal/slice';
import { initGTMforButtons } from '@utils/initGTMforButtons';

const cx = classNames.bind(styles);

const selectedDealerStorage = localStorage.getItem('dealerCode') || '';

type ConsultationModalProps = {
  closeModal: () => void;
  dealerCode?: string | void | null;
  modelId?: any;
  screenType?: string | null;
  vehicleModelLabel?: string | null;
  vehicleModificationLabel?: string | null;
  vehicleYear?: string | null;
};

const ConsultationModal = ({
  screenType = null,
  closeModal,
  dealerCode = selectedDealerStorage,
  modelId,
  vehicleModelLabel = null,
  vehicleModificationLabel = null,
  vehicleYear = null,
}: ConsultationModalProps) => {
  const [successfulStatus, setSuccessfulStatus] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showPrivacy, setShowPrivacy] = useState(false);
  const [showMarketingInfo, setShowMarketingInfo] = useState(false);
  const [additionalText, setAdditionalText] = useState('');
  const handleShowPrivacy = () => {
    setShowPrivacy(true);
  };

  const openedFrom = useSelector(selectOpenedFrom);
  const vehicle_modification_id = useSelector(modificationId);
  const dispatch = useDispatch();

  const [consultationOpt, setСonsultationOpt] = useState([]);

  const zoneId = useSelector(selectedZoneId);
  const screenId = useSelector(selectedScreen);
  const translateArr = useSelector(translationsDataArr);
  const consultationModalData = useSelector(consultationModalDataObj);
  const zona = useSelector(selectedZone);
  const url = useLocation();

  const showForDealer2s = checkPageForConsultationFor2s(url.pathname);

  const [selectedConsultationType, setSelectedConsultationType] =
    useState(null);

  const {
    handleSubmit,
    reset,
    control,
    setFocus,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useForm({
    reValidateMode: 'onChange',
  });

  const consultationType = watch('type');

  const onSubmit = (data: any) => {
    const checkboxes = Object.entries(data.checkboxes).map(([key, value]) => ({
      name: key,
      value: value,
    }));

    let sendData;
    if (consultationModalData.screenType === 'service') {
      sendData = {
        ...data,
        screenType: consultationModalData.screenType,
        vehicleModelLabel: consultationModalData.vehicleModelLabel,
        vehicleModificationLabel:
          consultationModalData.vehicleModificationLabel,
        vehicleYear: consultationModalData.vehicleYear,
        type: data?.type?.value,
        client_phone: data?.client_phone.replace(/\s+/g, '').trim(),
        zone_id: zoneId,
        screen_id: screenId,
      };
    } else if (
      openedFrom === null &&
      checkPageForConsultationFor2s(url.pathname)
    ) {
      sendData = {
        ...data,
        type: 'new_cars',
        client_phone: data?.client_phone.replace(/\s+/g, '').trim(),
        zone_id: zoneId,
        screen_id: screenId,
        checkboxes: checkboxes,
        vehicle_model_id: consultationModalData.modelId,
        vehicle_modification_id: vehicle_modification_id,
      };
    } else if (
      openedFrom === 'list' &&
      checkPageForConsultationFor2s(url.pathname)
    ) {
      sendData = {
        ...data,
        type: 'new_cars',
        client_phone: data?.client_phone.replace(/\s+/g, '').trim(),
        zone_id: zoneId,
        screen_id: screenId,
        is_from_model_view: true,
        vehicle_model_id: null,
        checkboxes: checkboxes,
        vehicle_modification_id: vehicle_modification_id,
      };
    } else if (
      openedFrom === 'model' &&
      checkPageForConsultationFor2s(url.pathname)
    ) {
      sendData = {
        ...data,
        type: 'new_cars',
        client_phone: data?.client_phone.replace(/\s+/g, '').trim(),
        zone_id: zoneId,
        screen_id: screenId,
        is_from_model_view: false,
        vehicle_model_id: consultationModalData.modelId,
        checkboxes: checkboxes,
        vehicle_modification_id: vehicle_modification_id,
      };
    } else {
      sendData = {
        ...data,
        type: data?.type?.value,
        client_phone: data?.client_phone.replace(/\s+/g, '').trim(),
        zone_id: zoneId,
        screen_id: screenId,
        checkboxes: checkboxes,
        vehicle_model_id: consultationModalData.modelId,
        vehicle_modification_id: vehicle_modification_id,
      };
    }

    setLoading(true);
    sendConsultationRequest(sendData, dealerCode)
      .then((res) => {
        if (res && res.status === 201) {
          setSuccessfulStatus(true);
          setLoading(false);
          initGTMforButtons({
            eventType: 'consultant_request_submit',
          });
          // dispatch(setModificationId(null));
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const fetchConsultationTypes = () => {
    getConsultationTypes(dealerCode)
      .then((res: any) => {
        if (res && res.status === 200) {
          setСonsultationOpt(res.data);
        }
      })
      .catch((error) => {
        console.log('error: ', error);
      });
  };
  const fetchAdditionalText = () => {
    getConsultationAdditionalText(dealerCode)
      .then((res) => {
        setAdditionalText(res.data?.data?.consultation_request);
      })
      .catch((error) => {
        console.log('error: ', error);
      });
  };
  const { isLoading: isLoadingModalData, data: modalDataResponce } =
    useGetModalSettingsQuery({
      dealer_code: dealerCode,
      form_name: 'consultation_request',
    });

  useEffect(() => {
    fetchConsultationTypes();
    checkIs2sDealer() && fetchAdditionalText();
  }, []);


  useEffect(() => {
    reset();
  }, [closeModal]);


  const { isLoading: isLoadingScreenData, data: screenDataResponse } =
    useGetScreenDataQuery({
      dealer_dealer_code: dealerCode,
      template: 'privacy_police',
      zona: zona,
    });

  const { isLoading: isMarketingInfo, data: marketingInfoDataResponse } =
    useGetScreenDataQuery({
      dealer_dealer_code: dealerCode,
      template: 'marketing_information',
      zona: zona,
    });

  const screenData: GetScreenDataResponse<{ description: string }, null> =
    React.useMemo(
      () => (screenDataResponse ? screenDataResponse.data : null),
      [screenDataResponse],
    );

  const isLoading = React.useMemo(
    () => isLoadingModalData || isLoadingScreenData,
    [isLoadingModalData, isLoadingScreenData],
  );
  // @ts-ignore
  // @ts-ignore
  return (
    <Box className={cx('modal')}>
      <ModalCloseButton onClick={() => closeModal()} />
      <div className={cx('modal__content')}>
        {!successfulStatus ? (
          <>
            <div className={cx('modal__title')}>
              {getTranslateText(
                translateArr,
                'translate.admin.modal.title.consultation',
              )}
            </div>
            {!isLoading && (
              <form className={cx('form')} onSubmit={handleSubmit(onSubmit)}>
                <div className={cx('form__line')}>
                  <FormInput
                    control={control}
                    label={getTranslateText(
                      translateArr,
                      'translate.admin.input.label.user.name',
                    )}
                    name="client_name"
                    rules={{
                      required: getTranslateText(
                        translateArr,
                        'validation.required',
                      ),
                    }}
                    errors={errors.client_name}
                  />
                </div>
                <div className={cx('form__line')}>
                  <PhoneInput
                    control={control}
                    label={getTranslateText(
                      translateArr,
                      'translate.admin.input.label.phone.number',
                    )}
                    name="client_phone"
                    rules={{
                      required: getTranslateText(
                        translateArr,
                        'validation.required',
                      ),
                      validate: {
                        valueLength: (value: any) => /\+380\d{9}$/.test(value.replace(/\s/g, '')) || getTranslateText(translateArr, 'translate.admin.validate.error.phone.length')
                      },
                    }}
                    errors={errors.client_phone}
                  />
                </div>
                {(!checkIs2sDealer() &&
                  !checkPageForConsultationFor2s(url.pathname)) ||
                (checkIs2sDealer() &&
                  !checkPageForConsultationFor2s(url.pathname)) ||
                !checkIs2sDealer() ? (
                  <div className={cx('form__line')}>
                    <Select
                      control={control}
                      options={consultationOpt}
                      label={getTranslateText(
                        translateArr,
                        'translate.admin.input.label.consultation.type',
                      )}
                      name="type"
                      isSearchable={false}
                      rules={{
                        required: getTranslateText(
                          translateArr,
                          'validation.required',
                        ),
                      }}
                      optValueKey="value"
                      optLabelKey="label"
                      selectedValueKey="value"
                      errors={errors.type}
                      required={true}
                    />
                  </div>
                ) : null}

                <div className={cx('form__line', 'borderType_1')}>
                  {modalDataResponce?.data.map((el, index) => (
                    <div style={{ marginBottom: 16 }}>
                      <CheckBoxInput
                        control={control}
                        label={`${el.text}`}
                        labelLinkText={`${el.link_text}`}
                        labelLinkAction={() => {
                          if (el.link_template === 'privacy_police') {
                            handleShowPrivacy();
                          } else if (
                            el.link_template === 'marketing_information'
                          ) {
                            setShowMarketingInfo(true);
                          }
                        }}
                        hasLink={el.has_link}
                        name={`checkboxes.${el.name}`}
                        rules={
                          el.required
                            ? {
                                required: getTranslateText(
                                  translateArr,
                                  'validation.required',
                                ),
                              }
                            : {}
                        }
                        //@ts-ignore
                        errors={errors.checkboxes?.[el.name]}
                      />
                    </div>
                  ))}
                </div>
                {(!checkIs2sDealer() &&
                  !checkPageForConsultationFor2s(url.pathname)) ||
                (checkIs2sDealer() &&
                  !checkPageForConsultationFor2s(url.pathname)) ? null : (
                  <div
                    className={cx('form__additionalText')}
                    dangerouslySetInnerHTML={{
                      __html: additionalText || '',
                    }}
                  />
                )}
                <div className={cx('form__line', 'buttonWrap')}>
                  <button
                    type="submit"
                    className={cx('submitButton')}
                    disabled={loading}
                  >
                    {getTranslateText(
                      translateArr,
                      'translate.admin.button.send.requisition',
                    )}
                  </button>
                </div>
              </form>
            )}
          </>
        ) : (
          <div className={cx('successful')}>
            {checkIs2sDealer() ? (
              <div
                className={cx('successful__text')}
                dangerouslySetInnerHTML={{
                  __html:
                    getTranslateText(
                      translateArr,
                      'modal.consultant.modal2s.successful.text',
                    ) || '',
                }}
              />
            ) : (
              <div
                className={cx('successful__text')}
                dangerouslySetInnerHTML={{
                  __html:
                    getTranslateText(
                      translateArr,
                      'modal.consultant.modal.successful.text',
                    ) || '',
                }}
              />
            )}

            <div className={cx('successful__actionBtn')}>
              {checkIs2sDealer() ? (
                <button
                  className={cx('btn')}
                  onClick={() => {
                    initGTMforButtons({
                      eventType: 'custom_click',
                      click_text:
                        getTranslateText(
                          translateArr,
                          'modal.consultant.modal2s.button.submit',
                        ) || 'Продовжити роботу з терміналом',
                    });
                    closeModal();
                  }}
                  dangerouslySetInnerHTML={{
                    __html:
                      getTranslateText(
                        translateArr,
                        'modal.consultant.modal2s.button.submit',
                      ) || '',
                  }}
                />
              ) : (
                <button
                  className={cx('btn')}
                  onClick={() => {
                    initGTMforButtons({
                      eventType: 'custom_click',
                      click_text:
                        getTranslateText(
                          translateArr,
                          'modal.consultant.modal.button.submit',
                        ) || 'Продовжити роботу з терміналом',
                    });
                    closeModal();
                  }}
                  dangerouslySetInnerHTML={{
                    __html:
                      getTranslateText(
                        translateArr,
                        'modal.consultant.modal.button.submit',
                      ) || '',
                  }}
                />
              )}
            </div>
          </div>
        )}
        {showPrivacy && (
          <div className={cx('privacyContent')}>
            <ScreenTitle
              blackText={isLoading ? '' : screenData?.title}
              onBack={() => setShowPrivacy(false)}
            />
            <div
              className={cx('privacyContent__text')}
              dangerouslySetInnerHTML={{
                __html: screenData?.content?.description || '',
              }}
            ></div>
          </div>
        )}
        {showMarketingInfo && (
          <div className={cx('privacyContent')}>
            <ScreenTitle
              blackText={isLoading ? '' : marketingInfoDataResponse?.data.title}
              onBack={() => setShowMarketingInfo(false)}
            />
            <div
              className={cx('privacyContent__text')}
              dangerouslySetInnerHTML={{
                __html:
                  marketingInfoDataResponse?.data.content?.description || '',
              }}
            ></div>
          </div>
        )}
      </div>
    </Box>
  );
};

export default ConsultationModal;
