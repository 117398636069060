import React, { Suspense, useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import './App.css';
import { Spinner } from './components/common';
import RootNavigator from './navigation/RootNavigator';
import { store } from './store';
import { Modal } from '@mui/material';
import { useIdleTimer } from 'react-idle-timer';
import { TimeoutModal } from './components/Modals/TimeoutModal';
import { getGlodalSettingsData, getTranslateData } from './api/modal';
import Filter from './assets/svg/Filter';
import { useGetTranslateDataQuery } from '@store/translate/api';

import TagManager from 'react-gtm-module';
import { initGTMforButtons } from '@utils/initGTMforButtons';

function App() {
  const [showTimeoutModal, setShowTimeoutModal] = useState(false);
  // const [remaining, setRemaining] = useState<number>(0);
  const [timeOutMinutes, setTimeOutMinutes] = useState(100000);
  const [use_rc_timer, setUseRcTimer] = useState(false);
  const [use_hidden_rc_timer, setUseHiddenRcTimer] = useState('0');

  const onCloseModal = () => {
    initGTMforButtons({
      eventType: 'session_end',
    });
    setShowTimeoutModal(false);
  };

  const onIdle = () => {
    if (use_rc_timer === true) {
      setShowTimeoutModal(true);
    }
  };

  const getTimeOutModalSettings = () => {
    getGlodalSettingsData().then((res) => {
      setUseHiddenRcTimer(res.data.data[0].timer_settings.use_hidden_rc_timer)
      setTimeOutMinutes((res.data.data[0].timer_settings.time - 1) * 60 * 1000);
      setUseRcTimer(
        Boolean(Number(res.data.data[0].timer_settings.use_rc_timer)),
      );
      // setTimeOutMinutes(3000);
    });
  };

  const { getRemainingTime } = useIdleTimer({
    onIdle,
    timeout: timeOutMinutes,
    throttle: 5000,
  });

  // useEffect(() => {
  //   console.log('123');
  //   const interval = setInterval(() => {
  //     setRemaining(Math.ceil(getRemainingTime() / 1000));
  //   }, 500);

  //   return () => {
  //     clearInterval(interval);
  //   };
  // });

  useEffect(() => {
    getTimeOutModalSettings();
  }, []);

  useEffect(() => {
    const user_session_id =  localStorage.getItem('user_session_id')

    let dataLayer

    if (user_session_id) {
      dataLayer = {
        user_session_id: localStorage.getItem('user_session_id'),
        dealer_name: localStorage.getItem('dealerName'),
        dealer_id: localStorage.getItem('dealerCode'),
        dealer_zone_name: localStorage.getItem('zoneCode'),
        page_name: 'Головна сторінка'
      }
    } else {
      dataLayer = {
        dealer_name: localStorage.getItem('dealerName'),
        dealer_id: localStorage.getItem('dealerCode'),
        dealer_zone_name: localStorage.getItem('zoneCode'),
        page_name: 'Головна сторінка'
      }
    }

    const tagManagerArgs = {
      gtmId: 'GTM-THWQ4F25',
      dataLayer: dataLayer,
    };
    TagManager.initialize(tagManagerArgs);
  }, []);

  return (
    <Provider store={store}>
      <div className="App">
        <Suspense fallback={<Spinner />}>
          <RootNavigator />
        </Suspense>
        <Modal
          open={showTimeoutModal}
          onClose={() => setShowTimeoutModal(false)}
          disableAutoFocus={true}
          className={use_hidden_rc_timer === '1' ? "modalHidden" : ''}
        >
          <TimeoutModal closeModal={() => onCloseModal()}/>
        </Modal>
      </div>
    </Provider>
  );
}

export default App;
