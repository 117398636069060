import React, { useEffect } from 'react';
import { useTimer } from 'react-timer-hook';

const MyTimer = ({ expiryTimestamp, onTimeout, runingTimer }) => {
  const {
    seconds,
    minutes,
    isRunning,
    start,
    pause,
    resume,
    restart,
    onExpire,
  } = useTimer({
    expiryTimestamp,
    onExpire: () => onTimeout(),
  });
  const padValue = (value) => {
    return value < 10 ? '0' + value : value;
  };

  useEffect(() => {
    runingTimer(isRunning);
  }, [isRunning, onExpire]);

  return (
    <div style={{ textAlign: 'center' }}>
      <span>{padValue(minutes)}</span>:<span>{padValue(seconds)}</span>
    </div>
  );
};

export default MyTimer;
