import React, { PropsWithChildren, useEffect } from 'react';
import Spinner from '../Spinner/Spinner';

type Props = {
  marginTop?: number;
  classes?: string;
  bgTransparent?: boolean;
  loading?: boolean;
  styles?: React.CSSProperties;
};

const ScreenContentLayout = ({
  children,
  classes,
  bgTransparent,
  loading,
  styles,
}: PropsWithChildren<Props>) => {
  // setInterval(() => {
  //   window.parent.window.postMessage(
  //     { type: 'dxp-screens-iframe-activity' },
  //     '*',
  //   );
  // }, 5000);

  useEffect(()=> {
    function updateActivity( ) {
      console.log('load new script')
      window.parent.postMessage(
          { type: "dxp-screens-iframe-activity" },
          "*"
      );
    }
    window.addEventListener("scroll", updateActivity);
    window.addEventListener("click", updateActivity);
  },[])

  return (
    <div
      className={`flex ${classes || ''} h-688`}
      style={{
        ...styles,
        backgroundColor: bgTransparent ? 'transparent' : 'white',
      }}
    >
      {loading ? <Spinner /> : children}
    </div>
  );
};

export default ScreenContentLayout;
