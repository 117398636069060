import { createSlice } from "@reduxjs/toolkit";
import promotionsApi from "./api";
import {RootState} from "../index";

const initialState: {
  screenTestList: any;
  screenTestEngineList: any;
  screenTestRoutes: any;
  choosedEngine: any;
  choosedModel: any;
  choosedRoute: any;
} = {
  screenTestList: null,
  screenTestEngineList: null,
  screenTestRoutes: null,
  choosedEngine: null,
  choosedRoute: null,
  choosedModel: null
};

const slice = createSlice({
  name: "testDrive",
  initialState,
  reducers: {
    setTestDriveEngine: (state, action) => {
      state.choosedEngine = action.payload;
    },
    setTestDriveModel: (state, action) => {
      state.choosedModel = action.payload;
    },
    setTestDriveRoute: (state, action) => {
      state.choosedRoute = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      promotionsApi.endpoints.getTestDriveCars.matchFulfilled,
      (state, action) => {
        state.screenTestList = action.payload.data;
      },
    ).addMatcher(
      promotionsApi.endpoints.getTestDriveEngine.matchFulfilled,
      (state, action) => {
        state.screenTestEngineList = action.payload.data;
      },
    ).addMatcher(
      promotionsApi.endpoints.getTestDriveRoute.matchFulfilled,
      (state, action) => {
        state.screenTestRoutes = action.payload.data;
      },
    );
  },
});

export const { setTestDriveEngine, setTestDriveModel, setTestDriveRoute } =
    slice.actions;

export const getChoosedTestDriveEngine = (state: RootState) =>
    state.testDrive.choosedEngine;
export const getChoosedTestDriveModel = (state: RootState) =>
    state.testDrive.choosedModel;
export const getChoosedTestDriveRoute = (state: RootState) =>
    state.testDrive.choosedRoute;

export default slice.reducer;
