import * as React from 'react';

const CompareCircle = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="40"
        height="40"
        fill="none"
        viewBox="0 0 40 40"
        {...props}
    >
      <circle
          cx="20"
          cy="20"
          r="9.4"
          stroke="#6C7073"
          strokeWidth="1.2"
      ></circle>
    </svg>
);

export default CompareCircle;
