import { api } from "../api";
import {GetPromotionsDataRequest} from "./types";

const promotionsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getTestDriveCars: builder.query<any, GetPromotionsDataRequest>({
      query: ({ dealer_dealer_code }) => ({
        url: `api/v1/dealers/${dealer_dealer_code}/test-drive/cars`,
      }),
    }),
    getTestDriveEngine: builder.query<any, GetPromotionsDataRequest>({
      query: ({ dealer_dealer_code, id }) => ({
        url: `api/v1/dealers/${dealer_dealer_code}/test-drive/${id}/engines`,
      }),
    }),
    getTestDriveRoute: builder.query<any, GetPromotionsDataRequest>({
      query: ({ dealer_dealer_code }) => ({
        url: `api/v1/dealers/${dealer_dealer_code}/test-drive/routes`,
      }),
    }),
  }),
});

export const { useGetTestDriveCarsQuery, useGetTestDriveEngineQuery, useGetTestDriveRouteQuery } = promotionsApi;

export default promotionsApi;
