import * as React from 'react';

const MostPopular = (props) => (
  <svg
    width={25}
    height={25}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 40 40"
    {...props}
  >
    <path
      d="M16 10.668h21.333M16 20.668h14.667M16 30.668h4.667"
      stroke={props.color ? 'currentColor' : '#585D5F'}
      strokeWidth={2}
      strokeLinecap="round"
    />
    <path
      d="m1.664 25 1.667 1.667L6.664 30l5-5M6.664 30V10"
      stroke={props.color ? 'currentColor' : '#585D5F'}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default MostPopular;
