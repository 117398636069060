import React, { useEffect, useState } from 'react';
import { BulletListItem } from '@components';
import { Option } from '../../../types';
import './styles.scss';
import { useSelector } from 'react-redux';
import { selectIsShowFullSizeMenu } from '@store/menu/slice';
import CompareApprove from '@assets/svg/CompareApprove';
import CompareCircle from '@assets/svg/CompareCircle';
import CompareLine from '@assets/svg/CompareLine';
import { EqualHeight, EqualHeightElement } from 'react-equal-height';

type Props = {
  options: any;
};

const ComparisonsList: React.FC<Props> = ({ options }) => {
  const [activeSection, setActiveSection] = React.useState('');
  const isShowFullSizeMenu = useSelector(selectIsShowFullSizeMenu);

  const sections = React.useMemo(() => {
    const set = new Set<any>([]);
    options.map((item: any) => set.add(item));
    const arr = Array.from(set);
    setActiveSection('overallInformation');

    return arr;
  }, [options]);

  const sectionName = (section: any) => {
    switch (section) {
      case 'overallInformation':
        return 'Загальна інформація';
      case 'specifications':
        return 'Специфікація';
      case 'options':
        return 'Обладнання';
    }
  };

  const sectionNameObj: any = [
    {
      name: 'overallInformation',
    },
    {
      name: 'specifications',
    },
    {
      name: 'options',
    },
  ];

  return (
    <div className="car-comparisons-list">
      <div className="list-of-sections">
        <ul className="section-list">
          {sectionNameObj.map((section: any) => {
            const isActive = section.name === activeSection;
            return (
              <li
                key={section.name}
                className={`section-list__item ${
                  isActive ? 'section-list__item_active' : ''
                }`}
                onClick={() => setActiveSection(section.name)}
              >
                {sectionName(section.name)}
              </li>
            );
          })}
        </ul>
      </div>
      <EqualHeight>
        <div
          className="comparisons"
          style={{ width: isShowFullSizeMenu ? 985 : 1300 }}
        >
          {sections?.map((section: any) => {
            const groupedSpecifications: any = [
              ...new Set(section?.tabs?.specifications),
            ].reduce((category: any, item: any) => {
              const groupKey = item.category;
              if (!category[groupKey]) {
                category[groupKey] = [];
              }
              category[groupKey].push(item);
              return category;
            }, {});

            const groupedOptions: any = [
              ...new Set(section?.tabs?.options),
            ].reduce((category: any, item: any) => {
              const groupKey = item.category;
              if (!category[groupKey]) {
                category[groupKey] = [];
              }
              category[groupKey].push(item);
              return category;
            }, {});

            const groupedOverallSpecifications: any = [
              ...new Set(
                section?.tabs?.overallInformation?.duplicateSpecifications,
              ),
            ].reduce((category: any, item: any) => {
              const groupKey = item.category;
              if (!category[groupKey]) {
                category[groupKey] = [];
              }
              category[groupKey].push(item);
              return category;
            }, {});

            const availability = (item: any) => {
              switch (item) {
                case 'Standard':
                  return <CompareApprove />;
                case 'Optional':
                  return <CompareCircle />;
                case 'Unavailable':
                  return <CompareLine />;
              }
            };
            switch (activeSection) {
              case 'options':
                return (
                  <div className="comparisons-list__content">
                    <>
                      {Object.entries(groupedOptions)?.map(
                        ([key, item]: [string, any], index: any) => (
                          <EqualHeightElement
                            name={`comparisonsListItemCategoryOption-${index}`}
                          >
                            <div key={key} className="comparisons-list__item">
                              <p className="comparisons-list__item-category">
                                {key}
                              </p>
                              <ul>
                                {item.map((item: any, index: number) => (
                                  <EqualHeightElement
                                    name={`optionsItem-${index}`}
                                  >
                                    <li
                                      className={`comparisons-list__item-list`}
                                    >
                                      <span className="gray-text">
                                        {item?.name}
                                      </span>
                                      {availability(item?.availability)}
                                    </li>
                                  </EqualHeightElement>
                                ))}
                              </ul>
                            </div>
                          </EqualHeightElement>
                        ),
                      )}
                    </>
                  </div>
                );
              case 'overallInformation':
                return (
                  <div className="comparisons-list__content">
                    {section?.tabs?.overallInformation?.vehicleEngine
                      ?.label && (
                      <div className="comparisons-list__item">
                        <span className="comparisons-list__item-title">
                          Двигун{' '}
                        </span>
                        {
                          section?.tabs?.overallInformation?.vehicleEngine
                            ?.label
                        }
                      </div>
                    )}
                    {section?.tabs?.overallInformation?.vehicleEngine
                      ?.fuel_type && (
                      <div className="comparisons-list__item">
                        <span className="comparisons-list__item-title">
                          Тип{' '}
                        </span>
                        {
                          section?.tabs?.overallInformation?.vehicleEngine
                            ?.fuel_type
                        }
                      </div>
                    )}
                    {section?.tabs?.overallInformation
                      ?.duplicateSpecifications &&
                      section?.tabs?.overallInformation?.duplicateSpecifications.map(
                        (item: any) => {
                          return (
                            <div
                              className="comparisons-list__item"
                              key={item.name}
                            >
                              <span className="comparisons-list__item-title">
                                {item?.label}{' '}
                              </span>
                              {item?.value + ` ` + item?.unit}
                            </div>
                          );
                        },
                      )}
                  </div>
                );
              case 'specifications':
                return (
                  <div className="comparisons-list__content">
                    {Object.entries(groupedSpecifications)?.map(
                      ([key, item]: [string, any], index: number) => (
                        <EqualHeightElement
                          name={`comparisonsListItemSpecifications-${index}`}
                        >
                          <div key={key} className="comparisons-list__item">
                            <p className="comparisons-list__item-category">
                              {key}
                            </p>
                            <ul>
                              {item.map((item: any, index: number) => (
                                <EqualHeightElement
                                  name={`specificationsItem-${index}`}
                                >
                                  <li className="comparisons-list__item-list">
                                    <span className="gray-text">
                                      {item?.label}
                                    </span>{' '}
                                    {item?.value} {item?.unit}
                                  </li>
                                </EqualHeightElement>
                              ))}
                            </ul>
                          </div>
                        </EqualHeightElement>
                      ),
                    )}
                  </div>
                );
            }
          })}
        </div>
      </EqualHeight>
    </div>
  );
};

export default ComparisonsList;
