import React from 'react';
import { Range } from '../types';

export const usePagination = (
  lengthOfData: number,
  numberOfShowedElements: number = 6,
  sortBy?: any,
): {
  currentPage: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  showedDataSlice: Range;
  nextShowedDataSlice: Range;
  countOfPages: number;
  onClickPrev: VoidFunction;
  onClickNext: VoidFunction;
} => {
  const [currentPage, setCurrentPage] = React.useState(1);
  const [lang, setLang] = React.useState(lengthOfData);
  const countOfPages = React.useMemo(
    () => Math.ceil(lang / numberOfShowedElements),
    [lang, numberOfShowedElements],
  );

  const showedDataSlice = React.useMemo(
    () => ({
      from:
        currentPage === 1
          ? 0
          : numberOfShowedElements * currentPage - numberOfShowedElements,
      to:
        currentPage === 1
          ? numberOfShowedElements
          : currentPage * numberOfShowedElements,
    }),
    [currentPage, numberOfShowedElements],
  );

  const onClickPrev = React.useCallback(
    () => (currentPage === 1 ? 1 : setCurrentPage((page) => page - 1)),
    [currentPage],
  );
  const onClickNext = React.useCallback(
    () =>
      currentPage === countOfPages
        ? countOfPages
        : setCurrentPage((page) => page + 1),
    [currentPage, countOfPages],
  );

  const nextShowedDataSlice = React.useMemo(
    () => ({
      from: showedDataSlice.from + numberOfShowedElements,
      to: showedDataSlice.to + numberOfShowedElements,
    }),
    [showedDataSlice, numberOfShowedElements],
  );

  React.useEffect(() => {
    setLang(lengthOfData);
    setCurrentPage(1);
  }, [lengthOfData, sortBy]);

  return {
    currentPage,
    setCurrentPage,
    showedDataSlice,
    countOfPages,
    onClickPrev,
    onClickNext,
    nextShowedDataSlice,
  };
};
