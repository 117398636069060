import * as React from 'react';

const CompareLine = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="40"
        height="40"
        fill="none"
        viewBox="0 0 40 40"
        {...props}
    >
      <path
          fill="#6C7073"
          fillRule="evenodd"
          d="M30.5 20c0 .414-.333.75-.744.75H10.244A.747.747 0 019.5 20c0-.414.333-.75.744-.75h19.512c.411 0 .744.336.744.75z"
          clipRule="evenodd"
      ></path>
    </svg>
);

export default CompareLine;
