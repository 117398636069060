import { Slider } from '@mui/material';
import React, {useEffect, useMemo, useState} from 'react';
import {ExteriorColorConfigurator, IDisc} from '../../../../../store/configuratorCars/types';
import { INewCarDetailConfigurator} from '../../../../../types';
import {Carousel} from "react-responsive-carousel";
import {SliderButton} from "../../../../../components/common";
import {useSelector} from "react-redux";
import {selectCarStyle} from "../../../../../store/configuratorCars/slice";
import Panorama from "../Panorama";
type Props = {
  photosByColorsExterior: INewCarDetailConfigurator['exterior_images_by_colors']|INewCarDetailConfigurator['interior_images_by_colors'];
  photosByColorsInterior: INewCarDetailConfigurator['interior_images_by_colors'];
  activeColorExterior:ExteriorColorConfigurator,
  selectedDisc:IDisc,
  activeView:number,
  activeColorInterior:ExteriorColorConfigurator,
  onlyRead?:boolean
};

const PhotoSlider: React.FC<Props> = ({ photosByColorsExterior, activeColorExterior,activeView,photosByColorsInterior,activeColorInterior,onlyRead }) => {
  const currentSelectCarStyle = useSelector(selectCarStyle);
  const [handlerPosition, setHandlerPosition] = React.useState(0);
  const photos =useMemo(()=>{
    if(activeView===2){
      return photosByColorsInterior[activeColorInterior.code]
    }
    return currentSelectCarStyle?currentSelectCarStyle: photosByColorsExterior[activeColorExterior.code];
  },[currentSelectCarStyle,photosByColorsExterior,activeColorExterior.code,activeView])
  const cubeMapExample =useMemo(()=>{
    if(photos){
      return {
        type: "cubemap",
        cubeMap: [
          ...photos,
        ],
      }
    }
  },[photos])
  return (
      <>
        {activeView===2 ? (
            <div className="content-gallery">
              {photos.length===6?
                  <Panorama cubeMapExample={cubeMapExample} onlyRead={onlyRead}/>
                  :
                  <Carousel
                      showIndicators={false}
                      showStatus={false}
                      emulateTouch
                      width={'100%'}
                      swipeable={true}
                      renderItem={(item) => <div className="big-image">{item}</div>}
                      showThumbs={false}
                      renderArrowPrev={(onClick, hasPrev) => (
                          <SliderButton
                              color={"whiteColor"}
                              width={64}
                              onClick={onClick}
                              disabled={!hasPrev}
                              variant="left"
                              left={48}
                              chenvroneWidth={20}
                          />
                      )}
                      renderArrowNext={(onClick, hasNext) => (
                          <SliderButton
                              color={"whiteColor"}
                              width={64}
                              onClick={onClick}
                              disabled={!hasNext}
                              variant="right"
                              right={48}
                              chenvroneWidth={20}
                          />
                      )}
                      infiniteLoop
                  >
                    {photos?.map((item:string) => (
                        <img key={item} src={item} alt="" />
                    ))}
                  </Carousel>
              }
            </div>
        ) : (
            <>
              <div className="image-block car">
                <div className="image-block__image">
                  {photos?.map((item:string, index:number) => (
                      <img
                          key={item}
                          style={{
                            display: index === handlerPosition ? 'block' : 'none',
                          }}
                          src={item}
                          alt=""
                      />
                  ))}
                </div>
                <div className="image-block__slider">
                  <div className="line left-side" />
                  <div className="line right-side" />
                  <Slider
                      track={false}
                      aria-labelledby="track-false-slider"
                      defaultValue={handlerPosition}
                      sx={{
                        width: '100%',
                        position: 'absolute',
                        color: 'transparent',
                      }}
                      value={handlerPosition}
                      step={1}
                      onChange={(_, value) =>
                          setHandlerPosition(
                              typeof value === 'object' ? value[0] : value,
                          )
                      }
                      max={34}
                  />
                </div>
              </div>
            </>
        )}
      </>
  );
};

export default PhotoSlider;
