import { Box } from '@mui/material';
import React, { useEffect } from 'react';
import { Button, ModalCloseButton } from '@components';
import { BrochurePdfModalProps } from './types';
import classNames from 'classnames/bind';
import styles from './style.module.scss';
import QRCode from 'react-qr-code';

const cx = classNames.bind(styles);

const BrochureBotsModal: React.FC<BrochurePdfModalProps> = ({
  closeModal,
  qrLink,
  data,
}) => {
  const link = qrLink;
  const script = 'triggerPDF';

  return (
    <Box className={cx('brochure-pdf-modal')}>
      <ModalCloseButton onClick={closeModal} />
      <div className={cx('brochure-pdf-modal__content')}>
        <p className={styles.title}>
          {data?.pdf_popup_data_title
            ? data?.pdf_popup_data_title
            : 'Отримати PDF'}
        </p>

        <div className={styles.wrapperQrCode}>
          <QRCode
            style={{ height: '100%', background: 'white' }}
            value={qrLink ? `${link}` : ''}
            size={140}
            viewBox={`0 0 140 140`}
          />
        </div>
        <div className={styles.description}>
          {data?.pdf_popup_data_title
            ? data?.pdf_popup_data_description
            : 'Відскануйте QR-код за допомогую вашого мобільного телефона та' +
              'отримайте посилання на чат-бот.'}
        </div>
        <Button
          variant="primary"
          withoutBorder
          textFontSize={16}
          onClick={closeModal}
        >
          {data?.pdf_popup_data_title
            ? data?.pdf_popup_data_label
            : 'Продовжити роботу з терміналом'}
        </Button>
      </div>
    </Box>
  );
};

export default BrochureBotsModal;
