import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "..";

const initialState: { isShowFullSizeMenu: boolean } = {
  isShowFullSizeMenu: true,
};

const slice = createSlice({
  name: "menu",
  initialState,
  reducers: {
    setIsShowFullSizeMenu: (state, action: PayloadAction<boolean>) => {
      state.isShowFullSizeMenu = action.payload;
    },
  },
});

export const selectIsShowFullSizeMenu = (state: RootState) =>
  state.menu.isShowFullSizeMenu;

export const { setIsShowFullSizeMenu } = slice.actions;

export default slice.reducer;
