import React from 'react';
import { useSwiper } from 'swiper/react';
import { Pressable } from '../../wrappers';
import './SliderButton.scss';

type Props = {
  width?: number;
  chenvroneWidth?: number;
  chenvroneHeight?: number;
  variant: 'left' | 'right';
  disabled: boolean;
  onClick?: VoidFunction;
  style?: React.CSSProperties;
  left?: number | string;
  right?: number | string;
  color?:string;
};

export const SliderButton = ({
  disabled,
  onClick,
  variant,
  width = 64,
  chenvroneWidth,
  left,
  right,
  color
}: Props) => {
  const swiper = useSwiper();

  return (
    <>
      <button
        className={color?`slider-button-wrapper ${color}`:`slider-button-wrapper`}
        style={{
          width,
          height: width,
          top: 'calc(50% - 32px)',
          left,
          right,
        }}
        onClick={() =>
          disabled
            ? undefined
            : onClick
            ? onClick()
            : left
            ? swiper.slidePrev()
            : swiper.slideNext()
        }
      >
        <div className={`background`} />
        <div
          className={`chevron chevron__${variant}`}
          style={{ width: chenvroneWidth, height: chenvroneWidth }}
        />
      </button>
    </>
  );
};
