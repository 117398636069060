import { api } from "../api";
import {GetPromotionsList, GetPromotionsDataRequest} from "./types";

const promotionsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getPromotions: builder.query<any, GetPromotionsDataRequest>({
      query: ({ dealer_dealer_code, section }) => ({
        url: `api/v1/dealers/${dealer_dealer_code}/promotions?section=${section}`,
      }),
    }),
    getPromotionsDetail: builder.query<any, GetPromotionsDataRequest>({
      query: ({ dealer_dealer_code, id }) => ({
        url: `api/v1/dealers/${dealer_dealer_code}/promotions/${id}`,
      }),
    }),
    getDemonstrationList: builder.query<any, GetPromotionsDataRequest>({
      query: ({ dealer_dealer_code }) => ({
        url: `api/v1/dealers/${dealer_dealer_code}/technology-demonstration`,
      }),
    }),
    getPromotionsTabs: builder.query<any, GetPromotionsDataRequest>({
      query: ({ dealer_dealer_code }) => ({
        url: `api/v1/dealers/${dealer_dealer_code}/promotions/categories`,
      }),
    }),
    getPromotionsList: builder.query<any, GetPromotionsList>({
      query: ({ dealer_dealer_code, category_id }) => ({
        url: `api/v1/dealers/${dealer_dealer_code}/promotions/list?categoryId=${category_id}`,
      }),
    }),
  }),
});

export const {useGetPromotionsListQuery, useGetPromotionsQuery, useGetPromotionsDetailQuery, useGetDemonstrationListQuery , useGetPromotionsTabsQuery } = promotionsApi;

export default promotionsApi;
