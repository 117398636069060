import React from "react";
import "./ArrowButton.scss";

type Props = {
  variant: "left" | "right";
  disabled: boolean;
  onClick?: VoidFunction;
  style?: React.CSSProperties;
};

export const ArrowButton = ({ disabled, onClick, variant, style }: Props) => {
  return (
    <>
      <button
        disabled={disabled}
        className={`slider-button button-${variant}`}
        onClick={onClick}
        style={style}
      />
    </>
  );
};
