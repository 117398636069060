import API from '../api';

export const sendConsultationRequest = (data, dealer_dealer_code) => {
  return API.post(
    `/api/v1/dealers/${dealer_dealer_code}/consultation-request`,
    data,
  );
};

export const getConsultationTypes = (dealer_dealer_code) => {
  return API.get(
    `/api/v1/dealers/${dealer_dealer_code}/consultation-request/types`,
  );
};
