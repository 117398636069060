import React, {SetStateAction, useEffect, useState} from "react";
import PlayIcon from "@assets/svg/PlayIcon";
import {TipsDataType} from "@components/Modals/TipsModal";

interface Props {
    item?: any;
    setCurrentRef:any
    setShowControls:()=>void,
    showControls:boolean,
}
export type Ref = any;
type PropsTypeVideo = {
    item: TipsDataType;
    setCurrentRef:any;
    setShowControls:any;
    showControls:boolean;
    videoRef:any;
};
const WrappedComponent = React.forwardRef<Ref, Props>((props, ref) => {
    return <VideoView setCurrentRef={props.setCurrentRef} item={props.item} videoRef={ref} setShowControls={props.setShowControls} showControls={props.showControls}/>;
});
const VideoView = ({item,videoRef,setCurrentRef,showControls,setShowControls}:PropsTypeVideo) => {
    const handlePlayVideo = () => {
        // @ts-ignore
        videoRef &&  videoRef?.current.play();
        setShowControls(true)
        videoRef &&  setCurrentRef(videoRef)
    }
    return(
        <div className={'videoWrapper'}>
            <div className={'shadowBg'}/>
            <button className={showControls?"toggleVideoBtn hidde":"toggleVideoBtn"} onClick={handlePlayVideo}>
                <PlayIcon/>
            </button>
            <video
                ref={videoRef}
                src={item.video}
                style={{
                    height: 616,
                    width: 996,
                }}
                controls={showControls}
                controlsList={'nodownload noplaybackrate'}
                disablePictureInPicture
            />
        </div>

    )
};
export default WrappedComponent
