import * as React from 'react';

const Info = (props) => (
  <svg
    width={props.width || 40}
    height={props.height || 41}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 40 40"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 6.606a13.48 13.48 0 0 0-13.5 13.5 13.48 13.48 0 0 0 13.5 13.5 13.48 13.48 0 0 0 13.5-13.5A13.48 13.48 0 0 0 20 6.606Zm-16.5 13.5c0-9.127 7.372-16.5 16.5-16.5s16.5 7.373 16.5 16.5c0 9.128-7.372 16.5-16.5 16.5s-16.5-7.372-16.5-16.5Z"
      fill={props.fill || '#282830'}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m20.816 17.966.483 10H18.5l.384-10h1.932Z"
      fill={props.fill || '#282830'}
    />
    <path
      d="M21.5 13.747a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z"
      fill={props.fill || '#282830'}
    />
  </svg>
);

export default Info;
