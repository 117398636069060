import React from 'react';
import Info from '../../../assets/svg/Info';
import './Tooltip.scss';

type Props = {
  size?: number;
  text?: string;
  position?: string;
};
const Tooltip = ({ size, text = '', position }: Props) => {
  const [isShowContent, setIsShowContent] = React.useState(false);

  const toggleShowContent = () => setIsShowContent((prev) => !prev);

  return (
    <>
      <div
        className="icon"
        onClick={toggleShowContent}
        style={{ width: size || 33, height: size || 33 }}
      >
        <Info width={size} height={size} />
      </div>
      {isShowContent && (
        <>
          <div className="background" onClick={toggleShowContent} />
          <div
            className={'tooltip-wrapper'}
            // className="tooltip-wrapper"
            style={{ display: isShowContent ? 'block' : 'none' }}
          >
            <div className={'content ' + position}>{text}</div>
          </div>
        </>
      )}
    </>
  );
};

export default Tooltip;
