import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "..";
import { SortByType } from "../../types";
import usedCarsApi from "./api";
import {
  UsedCarsFilterType,
  UsedCarModel,
  GetUsedCarsModelsResponse,
  GetFilterValuesResponse,
  UsedCarsSortVariants,
} from "./types";

export const sortVariants: {
  [name: string]: SortByType<UsedCarsSortVariants>;
} = {
  most_popular: {
    by: "most_popular",
    label: "Найпопулярніші",
  },
  price_asc: {
    by: "price_asc",
    label: "Від дешевих до дорогих",
  },
  price_desc: {
    by: "price_desc",
    label: "Від дорогих до дешевих",
  },
  year_asc: {
    by: "year_asc",
    label: "Рік випуску, по зростанню",
  },
  year_desc: {
    by: "year_desc",
    label: "Рік випуску, по спаданню",
  },
  mileage_asc: {
    by: "mileage_asc",
    label: "Пробіг, по зростанню",
  },
  mileage_desc: {
    by: "mileage_desc",
    label: "Пробіг, по спаданню",
  },
};

const initialState: {
  defaultFilterValues: Omit<
    UsedCarsFilterType,
    "toyotaPlus" | "hybrid" | "VAT"
  >;
  filter: UsedCarsFilterType;
  models: UsedCarModel[];
  countOfActiveFilters: number;
  sortBy: SortByType<UsedCarsSortVariants>;
  isResetFilter: boolean;
} = {
  defaultFilterValues: {
    make: [],
    fuel: [],
    price: { from: 0, to: 1 },
    mileage: { from: 0, to: 1 },
    year: { from: 0, to: 1 },
  },
  filter: {
    make: [],
    fuel: [],
    price: { from: 0, to: 1 },
    mileage: { from: 0, to: 1 },
    year: { from: 0, to: 1 },
    toyotaPlus: false,
    hybrid: false,
    VAT: false,
  },
  models: [],
  countOfActiveFilters: 0,
  sortBy: sortVariants["most_popular"],
  isResetFilter: true,
};

const slice = createSlice({
  name: "usedCars",
  initialState,
  reducers: {
    setFilter: (state, action) => {
      state.filter = action.payload;
      state.isResetFilter = false;
    },
    resetFilters: (state) => {
      state.filter = {
        ...state.defaultFilterValues,
        make: [],
        fuel: [],
        VAT: false,
        hybrid: false,
        toyotaPlus: false,
      };
      state.isResetFilter = true;
    },
    setSortBy: (state, action: PayloadAction<UsedCarsSortVariants>) => {
      state.sortBy = sortVariants[action.payload];
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        usedCarsApi.endpoints.getValuesForUsedCarsFilter.matchFulfilled,
        (state, action: PayloadAction<GetFilterValuesResponse>) => {
          const { mileage, price, year } = action.payload;

          state.defaultFilterValues = {
            ...action.payload,
          };
          state.filter = {
            ...state.filter,
            price: {
              from: price.from,
              to: price.to,
            },
            year: {
              from: year.from,
              to: year.to,
            },
            mileage: { from: mileage.from, to: mileage.to },
          };
        },
      )
      .addMatcher(
        usedCarsApi.endpoints.getUsedCarsModels.matchFulfilled,
        (state, action: PayloadAction<GetUsedCarsModelsResponse>) => {
          state.models = action.payload.data;
        },
      );
  },
});

export const { setFilter, resetFilters, setSortBy } = slice.actions;

export const selectCountOfActiveFilters = (state: RootState) => {
  const { make, fuel, price, mileage, year, toyotaPlus, VAT } =
    state.usedCars.filter;
  const { defaultFilterValues } = state.usedCars;
  const makeFilter = Number(!!make.length);
  const fuelFilter = Number(!!fuel.length);
  const priceFilter = Number(
    price.from !== defaultFilterValues.price.from ||
      price.to !== defaultFilterValues.price.to,
  );
  const yearFilter = Number(
    year.from !== defaultFilterValues.year.from ||
      year.to !== defaultFilterValues.year.to,
  );
  const mileageFilter = Number(
    mileage.from !== defaultFilterValues.mileage.from ||
      mileage.to !== defaultFilterValues.mileage.to,
  );
  const toyotaPlusFilter = Number(toyotaPlus);
  const vatFilter = Number(VAT);

  return (
    makeFilter +
    fuelFilter +
    priceFilter +
    yearFilter +
    mileageFilter +
    toyotaPlusFilter +
    vatFilter
  );
};
export const selectSortBy = (state: RootState) => state.usedCars.sortBy;
export const selectUsedCarsModels = (state: RootState) => state.usedCars.models;
export const selectdefaultFilterValues = (state: RootState) =>
  state.usedCars.defaultFilterValues;
export const selectUsedCarsFilter = (state: RootState) => state.usedCars.filter;
export const selectIsResetFilter = (state: RootState) =>
  state.usedCars.isResetFilter;

export default slice.reducer;
