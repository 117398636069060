import { api } from "../api";
import {GetPromotionsDataRequest} from "./types";

const creditInsuranceApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getCreditInsuranceList: builder.query<any, GetPromotionsDataRequest>({
      query: ({ dealer_dealer_code, params }) => ({
        url: `api/v1/dealers/${dealer_dealer_code}/lending-programs`,
        params: {section: params}
      }),
    }),
    getCreditInsuranceListItem: builder.query<any, GetPromotionsDataRequest>({
      query: ({ dealer_dealer_code, id }) => ({
        url: `api/v1/dealers/${dealer_dealer_code}/lending-programs/${id}`,
      }),
    }),
  }),
});

export const { useGetCreditInsuranceListQuery, useGetCreditInsuranceListItemQuery } = creditInsuranceApi;

export default creditInsuranceApi;
