import API from '../api';

export const sendComparisonCar = (data, dealer_dealer_code) => {
  return API.post(`/api/v1/dealers/${dealer_dealer_code}/comparisons`, data);
};
export const deleteComparisonCar = (comparison_id, dealer_dealer_code) => {
  return API.delete(
    `/api/v1/dealers/${dealer_dealer_code}/comparisons/${comparison_id}`,
  );
};
export const deleteAllComparisonCar = (userSessionId, dealer_dealer_code) => {
  return API.delete(
    `api/v1/dealers/${dealer_dealer_code}/comparisons/delete-all/${userSessionId}`,
  );
};

export const getColorForSelectedCompletation = (
  dealer_dealer_code,
  vehicleModel_id,
  vehicleCompletion_id,
) => {
  return API.get(
    `api/v1/dealers/${dealer_dealer_code}/new-cars/model/${vehicleModel_id}/completion/${vehicleCompletion_id}`,
  );
};

export const changeColorModification = (
  dealer_dealer_code,
  comparison_id,
  vehicle_modification_color_id,
) => {
  return API.patch(
    `/api/v1/dealers/${dealer_dealer_code}/comparisons/${comparison_id}`,
    vehicle_modification_color_id,
  );
};
