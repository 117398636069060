import * as React from 'react';

const NavigationSwiperIconPrev = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="496"
    height="11"
    fill="none"
    viewBox="0 0 496 11"
  >
    <path stroke="#282830" strokeWidth="2" d="M240 1l8 8 8-8"></path>
  </svg>
);

export default NavigationSwiperIconPrev;
