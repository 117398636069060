import React from "react";
import "./ListItem.scss";

const ListItem = ({
  classes,
  children,
}: {
  classes?: string;
  children: string;
}) => {
  return (
    <li className={`custom-list-item ${classes || ""}`}>
      <div className="circle-wrapper">
        <div className="circle" />
      </div>
      <span>{children}</span>
    </li>
  );
};

export default ListItem;
