import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "..";
import translateApi from "./api";

const initialState: { translationsData: any } = {
  translationsData: [],
};

const slice = createSlice({
  name: "translate",
  initialState,
  reducers: {
    setTranslationsData: (state, action: PayloadAction<any>) => {
      state.translationsData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      translateApi.endpoints.getTranslateData.matchFulfilled,
      (state, action) => {
        state.translationsData = action.payload.data;
      },
    )
  },
});

export const translationsDataArr = (state: RootState) =>
  state.translate.translationsData; 

export const { setTranslationsData } = slice.actions;

export default slice.reducer;
