import React, {useState} from "react";
// @ts-ignore
import ReactPannellum,{getConfig,isLoaded}  from "react-pannellum";
import PanoramaIcon from "@assets/svg/Panorama";
import './style.scss'
const Panorama=({cubeMapExample,onlyRead}:any)=>{
    const panImage=React.useRef(null);
    const [showIcon,setShowIcon]=useState(true)
    return(
        <div className={'panoramaViewWrapper'} onFocus={()=>{setShowIcon(false)}}>
            {
                showIcon &&  <div className={'iconWrapper'}>
                    <PanoramaIcon/>
                </div>
            }

            {

                <ReactPannellum
                    ref={panImage}
                    id={onlyRead?"panoramaIdOnlyRead":"panoramaId"}
                    sceneId={onlyRead?"panoramaSceneIdOnlyRead":"panoramaSceneId"}
                    {...cubeMapExample}
                    config={{
                        autoLoad:true,
                        autoRotate: -2,
                        destroy:true
                    }}
                    onPanoramaLoaded={()=>{console.log('loaded')}}
                    style={{
                        width: "908px",
                        height: "388px",
                        margin:'auto'
                    }}
                />
            }

        </div>

    )
}
export default Panorama
