import { NewCarDetailRequest } from 'store/newCars/types';
import { BaseResponse } from 'types';
import { api } from '../api';
import {
  GetFilterValuesRespone,
  GetStockCarModelsRequest,
  GetStockCarsModelsResponse,
  StockCarDetailRequest,
  StockCarDetailResponse,
} from './types';

const stockCarsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getValuesForStockCarsFilter: builder.query<
      GetFilterValuesRespone,
      { dealer_dealer_code: string }
    >({
      query: ({ dealer_dealer_code }) => ({
        url: `/api/v1/dealers/${dealer_dealer_code}/stocks/filters`,
      }),
    }),
    getStockCarsModels: builder.query<
      BaseResponse<GetStockCarsModelsResponse>,
      GetStockCarModelsRequest
    >({
      query: ({
        dealer_dealer_code,
        sortBy,
        page = 1,
        perPage = 100,
        filter,
      }) => {
        let paramsString = new URLSearchParams();
        if (filter) {
          paramsString = new URLSearchParams();

          filter.bodyType.length &&
            filter.bodyType.map((item) =>
              paramsString.append('bodyType[]', item.value!.toString()),
            );
          filter.engineType.length &&
            filter.engineType.map((item) =>
              paramsString.append('engineType[]', item.value.toString()),
            );
        }

        sortBy &&
          sortBy !== 'most_popular' &&
          paramsString.append('sortBy', sortBy);

        return {
          url: `/api/v1/dealers/${dealer_dealer_code}/stocks?${paramsString}`,
        };
      },
    }),
    getStockCarDetail: builder.query<
      StockCarDetailResponse,
      StockCarDetailRequest
    >({
      query: ({ dealer_dealer_code, vehicleModel_id }) => ({
        url: `api/v1/dealers/${dealer_dealer_code}/stocks/${vehicleModel_id}`,
      }),
    }),
    // getNewCarPrices: builder.query<
    //   GetStockCarPricesResponse,
    //   GetStockCarPricesRequest
    // >({
    //   query: ({ dealer_dealer_code, vehicleModel_id }) => ({
    //     url: `api/v1/dealers/${dealer_dealer_code}/new-cars/model/${vehicleModel_id}/prices`,
    //   }),
    // }),
  }),
});

export const {
  useGetStockCarsModelsQuery,
  useGetValuesForStockCarsFilterQuery,
  useGetStockCarDetailQuery,
} = stockCarsApi;

export default stockCarsApi;
