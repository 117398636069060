import './Header.scss';
import ToyotaLogo from '../../../assets/svg/ToyotaLogo';
import Comparison from '../../../assets/svg/Comparison';
import Configuration from '../../../assets/svg/Configuration';
import Shutdown from '../../../assets/svg/Shutdown';
import AskIcon from '../../../assets/svg/Ask';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ROUTES } from '../../../navigation/routes';
import { useDispatch, useSelector } from 'react-redux';
import { setIsShowFullSizeMenu } from '../../../store/menu/slice';
import { Button } from '../Buttons';
import { THEME } from '../../../utils/theme';
import { resetFilters } from '../../../store/usedCars/slice';
import { useGetMyConfigurationsQuery } from '../../../store/configuratorCars/api';
import TipsModal from '@components/Modals/TipsModal';
import { Modal } from '@mui/material';
import React, { useEffect, useState, useRef } from 'react';
import {
  menuList,
  selectedDealer,
  selectedZone,
  selectedZoneId,
} from '@store/dealers/slice';
import { deleteAllComparisonCar, deleteComparisonCar } from '@api/comparisons';
import { selectModelsCompare, setNewCarsModels } from '@store/newCars/slice';
import { translationsDataArr } from '@store/translate/slice';
import getTranslateText from '@utils/getTranslateText';
import { myConfigurationsList } from '@store/configuratorCars/slice';
import { checkIs2sDealer } from '@utils/checkIs2sDealer';
import { checkPageForConsultationFor2s } from '@utils/checkPageForConsultationFor2s';
import ConsultationModal from '@components/Modals/ConsultationModal/ConsultationModal';
import { initGTMforButtons } from '@utils/initGTMforButtons';

type Props = {
  showLogoText?: boolean;
  showLogo?: boolean;
  showConfigurationButton?: boolean;
  showComprassionsButton?: boolean;
  showAskButton?: boolean;
  showLogoutButton?: boolean;
  showConsultationButton?: boolean;
};

const Header = ({
  showLogoText = true,
  showLogo = false,
  showAskButton = false,
  showComprassionsButton = true,
  showConfigurationButton = true,
  showLogoutButton = true,
  showConsultationButton = true,
}: Props) => {
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const dealer = useSelector(selectedDealer);
  const params = useParams();
  const [isShowTipsModal, setIsShowTipsModal] = useState(false);
  const sessionId = useSelector(selectedZone);
  const compareCars = useSelector(selectModelsCompare);
  const translateArr = useSelector(translationsDataArr);
  const myConfigurationsListArr = useSelector(myConfigurationsList);
  const [startHoldTime, setStartHoldTime] = useState(0);
  const [endHoldTime, setEndHoldTime] = useState(0);
  const [isShowConsultationModal, setIsShowConsultationModal] =
    React.useState(false);
  const url = useLocation();

  const goToHome = () => {
    initGTMforButtons({
      eventType: 'session_end',
    });
    navigation(ROUTES.HOME.route);
    dispatch(setIsShowFullSizeMenu(true));
    dispatch(resetFilters());
    dispatch(setNewCarsModels(null));
    deleteAllComparisonCar(sessionId, dealer)
      .then((res: any) => {
        if (res && res.status === 200) {
          console.log('deleted item', res.data);
        }
      })
      .catch((error) => {
        console.log('error: ', error);
      });
  };
  const resetLocalStorage = () => {
    if (window.confirm('Reset screen?')) {
      localStorage.clear();
      navigation(`/`);
    }
  };

  const { isLoading, data: myConfigurationsCarList } =
    useGetMyConfigurationsQuery({
      dealer_dealer_code: dealer || 'AZ',
      userSessionId: localStorage.getItem('user_session_id'),
    });

  const goToMyConfigurations = () => {
    navigation(`/configurator/my-configurations`);
  };
  const goToComparisons = () => {
    navigation(`/comparisons`);
  };
  const menu = useSelector(menuList);
  const configuratorActive = menu?.data.some(
    (item: any) => item.template === 'configurator_main',
  );

  // @ts-ignore

  useEffect(() => {
    const timeHold = localStorage.getItem('time_hold_finish_button');
    // @ts-ignore
    const time_hold_finish_button = parseInt(timeHold) * 1000;
    let duration = endHoldTime - startHoldTime;
    if (endHoldTime !== 0) {
      if (duration > time_hold_finish_button) {
        resetLocalStorage();
        setStartHoldTime(0);
        setEndHoldTime(0);
      } else {
        goToHome();
      }
    }
  }, [endHoldTime]);

  return (
    <header className="header">
      {showLogo ? (
        <>
          <div className="header-logo">
            <ToyotaLogo />
          </div>
          {showLogoText ? <p className="header-title">Меню</p> : ''}
        </>
      ) : null}
      <div className="header-content">
        <div className="left-buttons">
          {showConfigurationButton &&
            configuratorActive &&
            !checkIs2sDealer() && (
              <Button
                type="rounded"
                width={295}
                height={64}
                notAddedGTM={true}
                onClick={() => {
                  initGTMforButtons({
                    eventType: 'custom_click',
                    click_text: getTranslateText(
                      translateArr,
                      'admin/templates.names.configurator_my_configurations',
                    ),
                  });
                  goToMyConfigurations();
                }}
                icon={(color) => <Configuration color={color} />}
                marginRight={24}
                backgroundColor={{
                  default: THEME.colors.white,
                  active: THEME.colors.white,
                  disabled: THEME.colors.white,
                }}
                textColor={{
                  default: THEME.colors.primary_black,
                  active: THEME.colors.primary_black,
                  disabled: THEME.colors.gray_4,
                }}
              >
                <div className={'text-with-count'}>
                  {getTranslateText(
                    translateArr,
                    'admin/templates.names.configurator_my_configurations',
                  )}
                  {/* Мої конфігурації */}
                  <div className={'count'}>
                    {/* <p> {myConfigurationsCarList?.data.length}</p> */}
                    <p> {myConfigurationsListArr?.data?.length}</p>
                  </div>
                </div>
              </Button>
            )}
          {showComprassionsButton && !checkIs2sDealer() && (
            <Button
              type="rounded"
              width={230}
              height={64}
              notAddedGTM={true}
              onClick={() => {
                initGTMforButtons({
                  eventType: 'custom_click',
                  click_text: getTranslateText(
                    translateArr,
                    'names.comparison',
                  ),
                });
                goToComparisons();
              }}
              icon={() => <Comparison color={THEME.colors.gray_7} />}
              backgroundColor={{
                default: THEME.colors.white,
                active: THEME.colors.white,
                disabled: THEME.colors.white,
              }}
              textColor={{
                default: THEME.colors.primary_black,
                active: THEME.colors.primary_black,
                disabled: THEME.colors.gray_4,
              }}
            >
              <div className={'text-with-count'}>
                {getTranslateText(translateArr, 'names.comparison')}
                <div className={'count'}>
                  <p> {compareCars?.data.length || 0}</p>
                </div>
              </div>
            </Button>
          )}
        </div>
        <div className="right-buttons">
          {showAskButton && (
            <Button
              type="rounded"
              width={'auto'}
              height={64}
              onClick={() => {
                setIsShowTipsModal(true);
              }}
              withoutBorder={true}
              backgroundColor="transparent"
              icon={(color) => <AskIcon color={color} />}
              textColor={{
                active: THEME.colors.primary_black,
                default: THEME.colors.primary_black,
                disabled: THEME.colors.gray_6,
              }}
              iconColor={{
                active: THEME.colors.primary_black,
                default: THEME.colors.gray_7,
                disabled: THEME.colors.gray_6,
              }}
            >
              {getTranslateText(translateArr, 'admin/templates.tips')}
            </Button>
          )}

          {showConsultationButton && (
            <Button
              classes={'consultatinHeaderBtn'}
              variant="primary"
              width={303}
              height={64}
              onClick={() => {
                setIsShowConsultationModal(true);
                initGTMforButtons({
                  eventType: 'consultant_request_click',
                });
              }}
            >
              {checkIs2sDealer() && checkPageForConsultationFor2s(url.pathname)
                ? getTranslateText(
                    translateArr,
                    'buttons.get.consultant.modal.btn',
                  )
                : getTranslateText(
                    translateArr,
                    'buttons.consultant.modal.btn',
                  )}
            </Button>
          )}

          {showLogoutButton && (
            <>
              <div className="header-shutDownBtn">
                <div
                  className="header-shutDownBtn__icon"
                  onTouchStart={() => setStartHoldTime(new Date().getTime())}
                  onTouchEnd={() => setEndHoldTime(new Date().getTime())}
                  onMouseDown={() => setStartHoldTime(new Date().getTime())}
                  onMouseUp={() => setEndHoldTime(new Date().getTime())}
                >
                  <Shutdown />
                </div>
                <div
                  className="header-shutDownBtn__text"
                  onClick={() => goToHome()}
                >
                  {getTranslateText(
                    translateArr,
                    'translate.admin.to_complete',
                  )}
                </div>
              </div>
              {/*<Button*/}
              {/*    type="rounded"*/}
              {/*    width={192}*/}
              {/*    height={64}*/}
              {/*    onClick={goToHome}*/}
              {/*    withoutBorder*/}
              {/*    icon={(color) => <Shutdown color={color} />}*/}
              {/*    backgroundColor={{*/}
              {/*      default: THEME.colors.gray_4,*/}
              {/*      active: THEME.colors.primary_black,*/}
              {/*      disabled: THEME.colors.gray_4,*/}
              {/*    }}*/}
              {/*    textColor={{*/}
              {/*      default: THEME.colors.primary_black,*/}
              {/*      active: THEME.colors.white,*/}
              {/*      disabled: THEME.colors.gray_4,*/}
              {/*    }}*/}
              {/*    iconColor={{*/}
              {/*      default: THEME.colors.gray_7,*/}
              {/*      active: THEME.colors.white,*/}
              {/*      disabled: THEME.colors.gray_4,*/}
              {/*    }}*/}
              {/*>*/}
              {/*  {getTranslateText(translateArr, 'translate.admin.to_complete')}*/}
              {/*</Button>*/}
            </>
          )}
        </div>
      </div>
      {/* <div className="resetLocal" onClick={() => resetLocalStorage()}></div> */}
      <Modal
        open={isShowTipsModal}
        onClose={() => setIsShowTipsModal(false)}
        disableAutoFocus={true}
      >
        <TipsModal
          closeModal={() => {
            setIsShowTipsModal(false);
          }}
        />
      </Modal>

      <Modal
        open={isShowConsultationModal}
        onClose={() => setIsShowConsultationModal(false)}
        disableAutoFocus={true}
      >
        <ConsultationModal
          closeModal={() =>
            setIsShowConsultationModal(!isShowConsultationModal)
          }
          dealerCode={dealer}
          // modelId={state.car.id}
        />
      </Modal>

      {/* <div className="resetLocal" onClick={() => localStorage.clear()}></div> */}
    </header>
  );
};

export default Header;
