import React from 'react';
import { THEME } from '../../../utils/theme';
import { Pressable } from '../../wrappers';
import './styles.scss';

type Props = {
  breadcrumbs: Breadcrumb[];
};

type Breadcrumb = {
  title: string;
  callback: VoidFunction;
  isActive?: boolean;
  hidden?: boolean;
};

const Breadcrumbs: React.FC<Props> = ({ breadcrumbs }) => {
  const activeElementIndex = breadcrumbs
    .filter((item) => !item.hidden)
    .findIndex((item) => item.isActive === true);

  React.useEffect(() => {
    sessionStorage.setItem(
      'configuratorActiveStep',
      JSON.stringify(activeElementIndex + 1),
    );
  }, [activeElementIndex]);

  return (
    <div className="breadcrumbs">
      {breadcrumbs
        .filter((item) => !item.hidden)
        .map((el, index) => {
          const isLast = breadcrumbs.length === index + 1;
          if (!el.hidden) {
            return (
              <Pressable
                activeElementIndex={activeElementIndex}
                key={el.title}
                classes={`breadcrumb ${el.isActive ? 'breadcrumb_active' : ''}`}
                style={(pressed) => ({
                  marginRight: isLast ? 0 : 32,
                  color: pressed ? THEME.colors.primary_black : undefined,
                })}
                onClick={el.callback}
              >
                <span>{`${index + 1}. ${el.title}`}</span>
              </Pressable>
            );
          }
        })}
    </div>
  );
};

export default Breadcrumbs;
