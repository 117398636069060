import React, { useState, useRef, useEffect } from 'react';
import classNames from 'classnames/bind';

import styles from './style.module.scss';
import ClearValueIcon from '@assets/svg/ClearValueIcon';
import DropdownIndicatorIcon from '@assets/svg/DropdownIndicatorIcon';
const cx = classNames.bind(styles);

const CustomSelect = ({
  options = [],
  selectedOpt,
  onChange,
  placeholder,
  optValueKey,
  optLabelKey,
  selectedValueKey,
  required = false,
  name,
  error = false,
  errorText = '',
  menuPlacement = 'bottom',
  isClerable = true,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef(null);

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleSelect = (option) => {
    onChange(option);
    setIsOpen(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div ref={ref} className={cx('select', error ? 'error' : '')}>
      {!selectedOpt && (
        <div className={cx('select__placeholder')}>
          {placeholder}
          {required ? <span className={cx('required')}>*</span> : ''}
        </div>
      )}
      {selectedOpt && isClerable ? (
        <div className={cx('select__clearBtn')}>
          <ClearValueIcon onClick={() => handleSelect(null)} />
        </div>
      ) : null}
      <div className={cx('select__curretIcon', isOpen ? 'open' : '')}>
        <DropdownIndicatorIcon />
      </div>
      <div className={cx('select__selectedValue')} onClick={toggleDropdown}>
        {selectedOpt
          ? options.find(
              (option) => option[selectedValueKey] === selectedOpt,
            )?.[optLabelKey]
          : ''}
      </div>
      {error && <div className={cx('errorText')}>{errorText}</div>}
      {isOpen && (
        <ul
          className={cx(
            'select__optList',
            menuPlacement ? cx(menuPlacement) : '',
          )}
        >
          {options && options.length ? (
            options.map((option, index) => (
              <li
                className={cx(
                  'select__optList-item',
                  selectedOpt && option[selectedValueKey] === selectedOpt
                    ? 'selected'
                    : '',
                )}
                key={index}
                onClick={() => handleSelect(option)}
              >
                {option[optLabelKey]}
              </li>
            ))
          ) : (
            <li className={cx('select__optList-item-noItems')}>
              Дані відсутні
            </li>
          )}
        </ul>
      )}
    </div>
  );
};

export default CustomSelect;
