import * as React from 'react';

const AccessoriesIcon = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="40"
        height="41"
        fill="none"
        viewBox="0 0 40 41"
    >
      <path
          fill="#282830"
          fillRule="evenodd"
          d="M18.163 8.518a.75.75 0 00-1.326-.702l-4.288 8.1H6a.75.75 0 000 1.5h1.281l2.34 14.039a1.75 1.75 0 001.726 1.462h17.306a1.75 1.75 0 001.726-1.462l2.34-14.038H34a.75.75 0 100-1.5h-6.549l-4.288-8.101a.75.75 0 00-1.326.702l3.917 7.399H14.246l3.917-7.4zm8.847 8.899H8.802l2.299 13.791a.25.25 0 00.246.209h17.306a.25.25 0 00.247-.209l2.298-13.791H27.01zm-12.267 3.15a.75.75 0 10-1.486.199l1 7.5a.75.75 0 101.486-.198l-1-7.5zm11.356-.644a.75.75 0 00-.843.645l-1 7.5a.75.75 0 101.487.198l1-7.5a.75.75 0 00-.644-.843zm-5.349.744a.75.75 0 00-1.5 0v7.5a.75.75 0 101.5 0v-7.5z"
          clipRule="evenodd"
      ></path>
    </svg>
);

export default AccessoriesIcon;
