import { BaseResponse } from 'types';
import { api } from '../api';
import {
  FormsNameRequest,
  FormsNameResponse,
  ModalRequest,
  ModalResponse,
} from './types';

const modalsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getFormNames: builder.query<
      BaseResponse<FormsNameResponse>,
      FormsNameRequest
    >({
      query: ({ dealer_code }) => ({
        url: `/api/v1/dealers/${dealer_code}/settings/forms-name`,
      }),
    }),
    getModalSettings: builder.query<BaseResponse<ModalResponse>, ModalRequest>({
      query: ({ dealer_code, form_name }) => ({
        url: `/api/v1/dealers/${dealer_code}/settings/checkboxes/${form_name}`,
      }),
    }),
    getAskModalInfo: builder.query({
      query: () => ({
        url: `api/v1/hints`,
      }),
    }),
    getModalData: builder.query({
      query: () => ({
        url: `api/v1/global-settings`,
      }),
    }),
  }),
});

export const { useGetModalSettingsQuery, useGetAskModalInfoQuery, useGetModalDataQuery } = modalsApi;

export default modalsApi;
