import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "..";

const initialState = {
    dealerCode: null,
    modelId: null,
    screenType: null,
    vehicleModelLabel: null,
    vehicleModificationLabel: null,
    vehicleYear: null
};

const slice = createSlice({
  name: "consultationModal",
  initialState,
  reducers: {
    setConsultationModelId: (state, action: PayloadAction<any>) => {
      state.modelId = action.payload;
    },
    setConsultationScreenType: (state, action: PayloadAction<any>) => {
      state.screenType = action.payload;
    },
    setConsultationVehicleModelLabel: (state, action: PayloadAction<any>) => {
      state.vehicleModelLabel = action.payload;
    },
    setConsultationVehicleModificationLabel: (state, action: PayloadAction<any>) => {
      state.vehicleModificationLabel = action.payload;
    },
    setConsultationVehicleYear: (state, action: PayloadAction<any>) => {
      state.vehicleYear = action.payload;
    },
    setClearConsultationModalData: (state) => {
      state.dealerCode = null;
      state.modelId = null;
      state.screenType = null;
      state.vehicleModelLabel = null;
      state.vehicleModificationLabel = null;
      state.vehicleYear = null;
    },
  },
  
});

export const consultationModalDataObj = (state: RootState) =>
  state.consultationModal; 

export const { 
  setConsultationModelId, 
  setConsultationScreenType, 
  setConsultationVehicleModelLabel, 
  setConsultationVehicleModificationLabel,
  setConsultationVehicleYear, 
  setClearConsultationModalData 
} = slice.actions;

export default slice.reducer;
