import * as React from 'react';

const QRCode = (props) => (
  <svg
    width={20}
    height={22}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 40 44"
    {...props}
  >
    <path d="M11 2H1v10h10V2Z"   stroke={props.color ? 'currentColor' : '#282830'}  />
    <path d="M7 6H5v2h2V6ZM11 30H1v10h10V30Z" stroke={props.color ? 'currentColor' : '#282830'} />
    <path
      d="M7 34H5v2h2v-2ZM21 6h-2v2h2V6ZM15 22v-2h-2v2h2ZM27 20v-2h-2v2h2ZM35 20v-2h-2v2h2ZM21 14h-2v2h2v-2ZM7 22v-2H5v2h2ZM23 22h-2v2h2v-2ZM27 26h-2v2h2v-2ZM19 26h-2v2h2v-2ZM31 22h-2v2h2v-2ZM23 30h-2v2h2v-2ZM23 38h-2v2h2v-2ZM31 30h-2v2h2v-2ZM27 34h-2v2h2v-2ZM19 34h-2v2h2v-2ZM35 26h-2v2h2v-2ZM39 22h-2v2h2v-2ZM31 38h-2v2h2v-2ZM35 34h-2v2h2v-2ZM39 30h-2v2h2v-2ZM39 2H29v10h10V2Z"
      stroke={props.color ? 'currentColor' : '#282830'}
    />
    <path d="M35 6h-2v2h2V6Z" stroke={props.color ? 'currentColor' : '#282830'}/>
  </svg>
);

export default QRCode;
