import { createSlice } from "@reduxjs/toolkit";
import messagesApi from "./api";
import {RootState} from "@store/index";

const initialState: {
  messengerData: any;
} = {
  messengerData: null,
};

const slice = createSlice({
  name: "message",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      messagesApi.endpoints.getMessengersData.matchFulfilled,
      (state, action) => {
        state.messengerData = action.payload.data;
      },
    );
  },
});
export const getMessages = (state: RootState) =>
  state.messages.messengerData;

export default slice.reducer;
