import * as React from 'react';

const Telegram = (props) => (
  <svg
    width={48}
    height={49}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 96 97"
    {...props}
  >
    <path
      d="M48 88.275c22.091 0 40-17.908 40-40 0-22.091-17.909-40-40-40s-40 17.909-40 40c0 22.092 17.909 40 40 40Z"
      fill="url(#a)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.106 47.853c11.661-5.08 19.437-8.43 23.327-10.048 11.109-4.62 13.417-5.423 14.922-5.45.33-.005 1.07.077 1.55.466.404.328.516.772.569 1.083.053.311.12 1.02.067 1.575-.602 6.325-3.207 21.674-4.532 28.758-.56 2.997-1.665 4.002-2.734 4.1-2.323.214-4.087-1.535-6.336-3.01-3.52-2.307-5.51-3.744-8.927-5.995-3.95-2.603-1.39-4.033.862-6.371.589-.612 10.823-9.921 11.021-10.765.025-.106.048-.5-.186-.708-.234-.208-.579-.136-.828-.08-.353.08-5.977 3.797-16.872 11.152-1.597 1.096-3.043 1.63-4.338 1.602-1.428-.03-4.176-.807-6.218-1.471-2.505-.814-4.496-1.245-4.322-2.628.09-.72 1.082-1.457 2.975-2.21Z"
      fill="#fff"
    />
    <defs>
      <linearGradient
        id="a"
        x1={48}
        y1={8.275}
        x2={48}
        y2={87.682}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#2AABEE" />
        <stop offset={1} stopColor="#229ED9" />
      </linearGradient>
    </defs>
  </svg>
);

export default Telegram;
