import { BaseResponse } from '../../types';
import { api } from '../api';
import {
  GetComparisonsModels,
  GetFilterValuesRespone,
  GetModelComplectationsRequest,
  GetModelComplectationsResponse,
  GetModelEnginesRequest,
  GetModelEnginesResponse,
  GetNewCarModelsRequest,
  GetNewCarPricesRequest,
  GetNewCarPricesResponse,
  GetNewCarsModelsResponse, GetQrCodeComparisonsRequest, NewCarDetailQrCodeRequest,
  NewCarDetailRequest,
  NewCarDetailResponse,
} from './types';

const newCarsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getValuesForNewCarsFilter: builder.query<
      GetFilterValuesRespone,
      { dealer_dealer_code: string }
    >({
      query: ({ dealer_dealer_code }) => ({
        url: `/api/v1/dealers/${dealer_dealer_code}/new-cars/filters`,
      }),
    }),
    getNewCarsModels: builder.query<
      BaseResponse<GetNewCarsModelsResponse>,
      GetNewCarModelsRequest
    >({
      query: ({
        dealer_dealer_code,
        sortBy,
        page = 1,
        perPage = 50,
        filter,
      }) => {
        let paramsString = new URLSearchParams();
        if (filter) {
          paramsString = new URLSearchParams({
            'number_of_seats[from]': filter?.number_of_seats.from.toString(),
            'number_of_seats[to]': filter?.number_of_seats.to.toString(),
            'power[from]': filter?.power.from.toString(),
            'power[to]': filter?.power.to.toString(),
            'fuel_consumption[from]': filter?.fuel_consumption.from.toString(),
            'fuel_consumption[to]': filter?.fuel_consumption.to.toString(),
          });

          filter.types.length &&
            filter.types.map((item) =>
              paramsString.append('type[]', item.value!.toString()),
            );
          filter.engines.length &&
            filter.engines.map((item) =>
              paramsString.append('engine[]', item.value.toString()),
            );
        }

        sortBy &&
          sortBy !== 'most_popular' &&
          paramsString.append('sortBy', sortBy);
          paramsString.append('perPage', perPage.toString());

        return {
          url: `/api/v1/dealers/${dealer_dealer_code}/new-cars/models?${paramsString}`,
        };
      },
    }),

    getComparisonNewCarsModels: builder.query<
      BaseResponse<GetNewCarsModelsResponse>,
      GetNewCarModelsRequest
    >({
      query: ({
        dealer_dealer_code,
        sortBy,
        page = 1,
        perPage = 50,
        filter,
      }) => {
        let paramsString = new URLSearchParams();
        if (filter) {
          paramsString = new URLSearchParams({
            'number_of_seats[from]': filter?.number_of_seats.from.toString(),
            'number_of_seats[to]': filter?.number_of_seats.to.toString(),
            'power[from]': filter?.power.from.toString(),
            'power[to]': filter?.power.to.toString(),
            'fuel_consumption[from]': filter?.fuel_consumption.from.toString(),
            'fuel_consumption[to]': filter?.fuel_consumption.to.toString(),
          });

          filter.types.length &&
            filter.types.map((item) =>
              paramsString.append('type[]', item.value!.toString()),
            );
          filter.engines.length &&
            filter.engines.map((item) =>
              paramsString.append('engine[]', item.value.toString()),
            );
        }

        sortBy &&
          sortBy !== 'most_popular' &&
          paramsString.append('sortBy', sortBy);
          paramsString.append('perPage', perPage.toString());

        return {
          url: `/api/v1/dealers/${dealer_dealer_code}/comparisons/models?${paramsString}`,
        };
      },
    }),

    getModelEngines: builder.query<
      GetModelEnginesResponse,
      GetModelEnginesRequest
    >({
      query: ({ dealer_dealer_code, vehicleModel_id }) => ({
        url: `/api/v1/dealers/${dealer_dealer_code}/new-cars/model/${vehicleModel_id}/engines`,
      }),
    }),

    getComparisonModelEngines: builder.query<
      GetModelEnginesResponse,
      GetModelEnginesRequest
    >({
      query: ({ dealer_dealer_code, vehicleModel_id }) => ({
        url: `/api/v1/dealers/${dealer_dealer_code}/new-cars/model/${vehicleModel_id}/engines`,
      }),
    }),

    getComparisonModels: builder.query<
      any,
      GetComparisonsModels
    >({
      query: ({
        dealer_dealer_code,
        userSessionId,
        compare,
        deleteItem
      }) => {
        let paramsString = new URLSearchParams();
        if(compare) {
          paramsString.append('unique', compare);
        }
        if(deleteItem) {
          paramsString.append('delete', deleteItem);
        }
        return {url: `/api/v1/dealers/${dealer_dealer_code}/comparisons/${userSessionId}?${paramsString}`,}
      },
    }),
    getModelComplectations: builder.query<
      GetModelComplectationsResponse,
      GetModelComplectationsRequest
    >({
      query: ({
        dealer_dealer_code,
        vehicleModel_id,
        vehicleEngine_id,
        vehicleTransmission_id,
        vehicleWheelDrive_id,
        is_hybrid,
      }) => ({
        url: `/api/v1/dealers/${dealer_dealer_code}/new-cars/model/${vehicleModel_id}/completions/engine/${vehicleEngine_id}/transmission/${vehicleTransmission_id}/wheel-drive/${vehicleWheelDrive_id}?is_hybrid=${is_hybrid}`,
      }),
    }),
    getComparisonModelComplectations: builder.query<
      GetModelComplectationsResponse,
      GetModelComplectationsRequest
    >({
      query: ({
        dealer_dealer_code,
        vehicleModel_id,
        vehicleEngine_id,
        vehicleTransmission_id,
        vehicleWheelDrive_id,
        is_hybrid,
      }) => {
        return {url: `/api/v1/dealers/${dealer_dealer_code}/new-cars/model/${vehicleModel_id}/completions/engine/${vehicleEngine_id}/transmission/${vehicleTransmission_id}/wheel-drive/${vehicleWheelDrive_id}?is_hybrid=${is_hybrid}`,}
      },
    }),

    getNewCarDetail: builder.query<NewCarDetailResponse, NewCarDetailRequest>({
      query: ({
        dealer_dealer_code,
        vehicleModel_id,
        vehicleCompletion_id,
      }) => ({
        url: `api/v1/dealers/${dealer_dealer_code}/new-cars/model/${vehicleModel_id}/completion/${vehicleCompletion_id}`,
      }),
    }),
    getNewCarPrices: builder.query<
      GetNewCarPricesResponse,
      GetNewCarPricesRequest
    >({
      query: ({ dealer_dealer_code, vehicleModel_id }) => ({
        url: `api/v1/dealers/${dealer_dealer_code}/new-cars/model/${vehicleModel_id}/prices`,
      }),
    }),

    getQrCodeComparisons: builder.query<any, GetQrCodeComparisonsRequest>({
      query: ({dealer_dealer_code, userSessionId}) => ({
        url: `api/v1/dealers/${dealer_dealer_code}/comparisons/qr-code/${userSessionId}`,
      }),
    }),

    getNewCarBrochureDetail: builder.query<any, NewCarDetailQrCodeRequest>({
      query: ({
                dealer_dealer_code,
                vehicleModel_id,
                brochure_id
              }) => ({
        url: `api/v1/dealers/${dealer_dealer_code}/new-cars/model/${vehicleModel_id}/qr-code/brochures/${brochure_id}/file`,
      }),
    }),
  }),
});

export const {
  useGetValuesForNewCarsFilterQuery,
  useGetNewCarsModelsQuery,
  useGetModelEnginesQuery,
  useGetModelComplectationsQuery,
  useGetNewCarDetailQuery,
  useGetNewCarPricesQuery,
  useGetComparisonNewCarsModelsQuery,
  useGetComparisonModelEnginesQuery,
  useGetComparisonModelComplectationsQuery,
  useGetComparisonModelsQuery,
  useGetQrCodeComparisonsQuery,
  useGetNewCarBrochureDetailQuery
} = newCarsApi;

export default newCarsApi;
