import { api } from "../api";
// import {GetTranslateDataRequest} from "./types";

const translateApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getTranslateData: builder.query({
      query: () => ({
        url: `api/v1/translate`,
      }),
    }),
  }),
});

export const { useGetTranslateDataQuery  } = translateApi;

export default translateApi;
