import React from 'react';
import Filter from '../../../assets/svg/Filter';
import { SortByType } from '../../../types';
import { useSelector } from 'react-redux';

import { THEME } from '../../../utils/theme';
import { Button } from '../Buttons';
import SortDropdown from '../SortDropdown';
import getTranslateText from '@utils/getTranslateText';
import { translationsDataArr } from '@store/translate/slice';

type Props<T> = {
  handleOpenFilter: VoidFunction;
  badge: number;
  handleClickSort: (sort: T) => void;
  currentSort: string;
  variants?: SortByType<T>[];
};

function RightContent<T>({
  handleOpenFilter,
  badge,
  handleClickSort,
  currentSort,
  variants,
}: Props<T>) {
  const translateArr = useSelector(translationsDataArr);

  return (
    <>
      {variants?.length ? (
        <SortDropdown<T>
          variants={variants}
          onClickItem={handleClickSort}
          currentItem={currentSort}
        />
      ) : null}
      <Button
        type="rounded"
        width={140}
        height={48}
        onClick={handleOpenFilter}
        icon={(color) => <Filter color={color} />}
        badge={!!badge ? badge : undefined}
        small={true}
        border={'1px solid var(--gray-4)'}
        marginLeft={16}
        backgroundColor={{
          default: THEME.colors.white,
          active: THEME.colors.white,
          disabled: THEME.colors.gray_4,
        }}
        textColor={{
          default: THEME.colors.gray_7,
          active: THEME.colors.primary_black,
          disabled: THEME.colors.white,
        }}
        iconColor={{
          default: THEME.colors.gray_7,
          active: THEME.colors.primary_black,
          disabled: THEME.colors.gray_4,
        }}
        badgeColor={{
          active: 'rgb(60, 60, 65)',
          default: THEME.colors.primary_black,
          disabled: THEME.colors.gray_4,
        }}
        borderColor={{
          active: THEME.colors.primary_black,
          default: THEME.colors.gray_6,
          disabled: THEME.colors.gray_4,
        }}
      >
        {getTranslateText(translateArr, 'translate.admin.title.filter')}
      </Button>
    </>
  );
}

export default RightContent;
