import { api } from "../api";
import {GetAccessoriesDataRequest} from "./types";

const accessoriesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAccessories: builder.query<any, GetAccessoriesDataRequest>({
      query: ({ dealer_dealer_code }) => {
        return {url: `api/v1/dealers/${dealer_dealer_code}/accessories/models`,}
      },
    }),
    getAccessoriesModelList: builder.query<any, GetAccessoriesDataRequest>({
      query: ({ dealer_dealer_code, vehicleModel_id, sortBy = 'populars.asc', filter, perPage }) => {
        let paramsString = new URLSearchParams();
        if (filter) {
          filter.types.length &&
          filter.types.map((item:any) =>
            paramsString.append(item.name, item.value),
          );
        }
        sortBy &&
        sortBy !== 'populars.asc' &&
        paramsString.append('sortBy', sortBy);
        return {url: `api/v1/dealers/${dealer_dealer_code}/accessories/model/${vehicleModel_id}?${paramsString}`,}
      },
    }),
  }),
});

export const { useGetAccessoriesQuery, useGetAccessoriesModelListQuery } = accessoriesApi;

export default accessoriesApi;
