import * as React from 'react';

const Comparison = (props) => (
  <svg
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 80 80"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M40 19.943c-9.113 0-16.5 7.387-16.5 16.5v16a1.5 1.5 0 1 1-3 0v-16c0-10.77 8.73-19.5 19.5-19.5s19.5 8.73 19.5 19.5v16a1.5 1.5 0 1 1-3 0v-16c0-9.113-7.387-16.5-16.5-16.5Z"
      fill="#282830"
    />
    <path
      d="M12.5 40.443a9.5 9.5 0 0 1 9.5-9.5v3a6.5 6.5 0 0 0-6.5 6.5v4a6.5 6.5 0 0 0 6.5 6.5v3a9.5 9.5 0 0 1-9.5-9.5v-4Z"
      fill="#282830"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M67.5 40.443a9.5 9.5 0 0 0-9.5-9.5v3a6.5 6.5 0 0 1 6.5 6.5v4a6.502 6.502 0 0 1-5.18 6.365c.119-.77.18-1.561.18-2.365v-5h-3v5c0 6.52-4.992 11.873-11.362 12.449A3.5 3.5 0 0 0 42 58.941h-4a3.5 3.5 0 1 0 0 7h4a3.5 3.5 0 0 0 3.184-2.044c6.112-.462 11.234-4.468 13.317-9.968 5.014-.26 8.999-4.409 8.999-9.487v-4Zm-30 22a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5Z"
      fill="#282830"
    />
  </svg>
);

export default Comparison;
