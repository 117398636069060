import * as React from 'react';

const OldCar = (props) => (
  <svg
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 80 80"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.5 44.943c0-13.911 12.083-25.5 26.5-25.5 5.639 0 10.784 1.78 14.953 4.788l-3.287 3.288a1.5 1.5 0 1 0 2.121 2.121l3.502-3.501c5.079 4.678 8.211 11.395 8.211 18.804 0 3.294-.81 8.034-2.098 11.858-.647 1.923-2.612 3.142-4.956 3.142H57.5v-3a3.5 3.5 0 0 0-3.5-3.5H27a3.5 3.5 0 0 0-3.5 3.5v3h-1.946c-2.344 0-4.309-1.22-4.956-3.142-1.288-3.824-2.098-8.564-2.098-11.858Zm9 18h-1.946c-3.303 0-6.645-1.757-7.8-5.185-1.376-4.088-2.254-9.152-2.254-12.815 0-15.68 13.541-28.5 29.5-28.5 16.03 0 28.5 12.89 28.5 28.5 0 3.663-.878 8.727-2.255 12.815-1.154 3.428-4.496 5.185-7.799 5.185H57.5v3a3.5 3.5 0 0 1-3.5 3.5H27a3.5 3.5 0 0 1-3.5-3.5v-3Zm3-6a.5.5 0 0 1 .5-.5h5v10h-5a.5.5 0 0 1-.5-.5v-9Zm8.5 9.5h4.5v-10H35v10Zm7.5 0v-10H47v10h-4.5Zm7.5 0v-10h4a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-4Zm-13.5-24a4 4 0 1 1 8 0 4 4 0 0 1-8 0Zm4-7a7 7 0 1 0 5.64 2.853l4.906-4.779a1.5 1.5 0 0 0-2.093-2.149l-5.074 4.943a6.968 6.968 0 0 0-3.379-.868Z"
      fill="#282830"
    />
  </svg>
);

export default OldCar;
