import { api } from '../api';
import { GetScreenDataRequest } from './types';

const screensApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getScreenData: builder.query<any, GetScreenDataRequest>({
      query: ({ dealer_dealer_code, template, screenId, zona }) => ({
        url: `api/v1/dealers/${dealer_dealer_code}/pages/${template}${
          screenId ? '/' + screenId : ''
        }${zona ? '/' + zona : ''}`,
      }),
    }),
  }),
});

export const { useGetScreenDataQuery } = screensApi;

export default screensApi;
