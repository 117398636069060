import React from 'react';
import SmallArrow from '../../../assets/svg/SmallArrow';
import { THEME } from '../../../utils/theme';
import { Button } from './Button';

type Props = {
  text: string;
  onClick: VoidFunction;
};

export const ListItemButton: React.FC<Props> = ({ text, onClick }) => {
  return (
    <Button
      style={{ justifyContent: 'flex-start' }}
      type="rounded"
      withoutBorder
      backgroundColor={'transparent'}
      onClick={onClick}
      iconPosition="right"
      icon={(color) => <SmallArrow color={color} />}
      textFontSize={16}
      width="auto"
      textFontFamily="Toyota-Type Book"
      textColor={{
        default: THEME.colors.primary_black,
        active: THEME.colors.gray_7,
        disabled: THEME.colors.gray_6,
      }}
      small
    >
      {text}
    </Button>
  );
};
