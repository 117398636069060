import * as React from 'react';

const AskIcon = (props) => (
  <svg
    width={40}
    height={40}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 80 80"
    {...props}
  >
    <path
      d="M40 68.352c15.74 0 28.5-12.449 28.5-27.805S55.74 12.742 40 12.742 11.5 25.191 11.5 40.547 24.26 68.352 40 68.352Z"
      stroke="currentColor"
      strokeWidth={3}
    />
    <path
      d="M39.938 52.315v-3.454c0-2.073 1.805-3.036 3.135-3.431a11.98 11.98 0 0 0 6.478-4.69 11.469 11.469 0 0 0 1.967-7.629 11.519 11.519 0 0 0-3.436-7.102 12.053 12.053 0 0 0-7.28-3.352 12.21 12.21 0 0 0-7.818 1.92 11.735 11.735 0 0 0-4.808 6.319 11.398 11.398 0 0 0 .385 7.851"
      stroke="currentColor"
      strokeWidth={3}
      strokeLinecap="round"
    />
    <path
      d="M40 62.01c1.105 0 2-.873 2-1.95 0-1.079-.895-1.952-2-1.952s-2 .873-2 1.951.895 1.951 2 1.951Z"
      fill="currentColor"
    />
  </svg>
);

export default AskIcon;
