import { createSlice } from "@reduxjs/toolkit";
import screensApi from "./api";

const initialState: {
  screenData: any;
} = {
  screenData: null,
};

const slice = createSlice({
  name: "screens",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      screensApi.endpoints.getScreenData.matchFulfilled,
      (state, action) => {
        state.screenData = action.payload.data;
      },
    );
  },
});

export default slice.reducer;
