import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '..';
import { SortByType } from '../../types';
import {
  InitialState,
  StockCarsFilterType,
  StockCarsSortVariants,
} from './types';
import stockCarsApi from './api';

export const sortVariants: {
  [name: string]: SortByType<StockCarsSortVariants>;
} = {
  popular_asc: {
    by: 'popular_asc',
    label: 'Найпопулярніші',
  },
  price_asc: {
    by: 'price_asc',
    label: 'Ціна: за зростанням',
  },
  price_desc: {
    by: 'price_desc',
    label: 'Ціна: за спаданням',
  },
};

const initialState: InitialState = {
  models: null,
  defaultFilterValues: {
    bodyType: [],
    engineType: [],
  },
filter: {
    bodyType: [],
    engineType: [],
  },
  isResetFilter: true,
  sortBy: sortVariants['popular_asc'],
};

const slice = createSlice({
  name: 'stockCars',
  initialState,
  reducers: {
    setFilter: (state, action: PayloadAction<StockCarsFilterType>) => {
      state.filter = action.payload;
      state.isResetFilter = false;
    },
    resetFilters: (state) => {
      state.filter = {
        ...state.defaultFilterValues,
        bodyType: [],
        engineType: [],
      };
      state.isResetFilter = true;
    },
    setSortBy: (state, action: PayloadAction<StockCarsSortVariants>) => {
      state.sortBy = sortVariants[action.payload];
    },
  },
  extraReducers: (buider) => {
    buider
      .addMatcher(
        stockCarsApi.endpoints.getValuesForStockCarsFilter.matchFulfilled,
        (state, action) => {
          state.defaultFilterValues = action.payload;
          state.filter = {
            ...action.payload,
            bodyType: [],
            engineType: [],
          };
        },
      )
      .addMatcher(
        stockCarsApi.endpoints.getStockCarsModels.matchFulfilled,
        (state, action) => {
          state.models = action.payload;
        },
      );
  },
});

export const { setFilter, resetFilters, setSortBy } = slice.actions;

export const stockCarModelsList = (state: RootState) => state.stockCars.models;
export const stockCarModelsSortBy = (state: RootState) =>
  state.stockCars.sortBy;
export const selectStockCarsFilter = (state: RootState) =>
  state.stockCars.filter;
export const selectIsResetFilterFilter = (state: RootState) =>
  state.stockCars.isResetFilter;
export const selectDefaultFilterValues = (state: RootState) =>
  state.stockCars.defaultFilterValues;

export const selectCountOfActiveFilters = (state: RootState) => {
  const { engineType, bodyType } = state.stockCars.filter;

  const typesNumber = Number(!!bodyType.length);
  const enginesNumber = Number(!!engineType.length);

  return typesNumber + enginesNumber;
};

export default slice.reducer;
