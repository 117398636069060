type Route = {
  path?: string;
  route: string;
};

type BasePages = {
  HOME: Route;
  NEW_CARS: Route;
  USED_CARS: Route;
  SERVICE: Route;
  CREDIT_AND_INSURANCE: Route;
  ACCESSORIES: Route;
  MY_CONFIGURATIONS: Route;
  COMPARISONS: Route;
  CONFIGURATOR: Route;
  TRADE_IN: Route;
  TEST_DRIVE: Route;
  PAGE_NOT_FOUND: Route;
  PRIVACY_POLICY: Route;
  PROMOTIONS: Route;
};

type NewCarsPagesType = {
  NEW_CARS_LIST: Route;
  NEW_CARS_CHOOSE_ENGINE: Route;
  NEW_CARS_CHOOSE_PACKAGE: Route;
  NEW_CAR: Route;
  NEW_CAR_DESCRIPTION_OF_MODEL: Route;
  NEW_CAR_ACCESSORIES: Route;
  NEW_CAR_WARRANTY_AND_SERVICE: Route;
  NEW_CAR_CHARACTERISTICS: Route;
  NEW_CAR_EQUIPMENT: Route;
  NEW_CARS_PRICES_AND_PACKAGES: Route;
  NEW_CAR_PHOTO_LIBRARY: Route;
  NEW_CARS_AT_THE_DEALER: Route;
  NEW_CARS_AT_THE_STOCK: Route;
  //STOCK CAR PAGES
  STOCK_CAR: Route;
  STOCK_CAR_DESCRIPTION_OF_MODEL: Route;
  STOCK_CAR_CHARACTERISTICS: Route;
  STOCK_CAR_ACCESSORIES: Route;
  STOCK_CAR_PHOTO_LIBRARY: Route;
  STOCK_CAR_EQUIPMENT: Route;
};
type ConfiguratorPagesType = {
  CONFIGURATOR_LIST: Route;
  CONFIGURATOR_CHOOSE_ENGINE: Route;
  CONFIGURATOR_CHOOSE_CAR_BODY: Route;
  CONFIGURATOR_CHOOSE_PACKAGE: Route;
  CONFIGURATOR_CHOOSE_OPTIONS: Route;
  CONFIGURATOR_CHOOSE_COLOR: Route;
  CONFIGURATOR_CHOOSE_ACCESSORIES: Route;
  CONFIGURATOR_CHOOSE_RESULTS: Route;
  CONFIGURATOR_MY_CONFIGURATIONS: Route;
  CONFIGURATOR_MY_CONFIGURATIONS_CAR: Route;
};

type ServicePagesTypes = {
  SERVICE_INFO: Route;
  MAINTENANCE: Route;
  SERVICE_CHOOSE_YEAR: Route;
  SERVICE_CHOOSE_ENGINE: Route;
  SERVICE_MAINTENANCE_INFO: Route;
};

type UsedCarsPagesType = {
  USED_CARS_LIST: Route;
  USED_CAR_INFO: Route;
  USED_CAR_KIT: Route;
  USED_CAR_FEATURE_OF_PROGRAM: Route;
  USED_CAR_DESCRIPTION_OF_CAR_MODEL: Route;
  USED_CAR_CHARACRETISTICS: Route;
};

type PromotionsPageTypes = {
  PROMOTIONS_LIST: Route;
  PROMOTIONS_LIST_ITEM: Route;
  VIEW_TECHNOLOGIES: Route;
  DEMONSTRATE_TECHNOLOGIES: Route;
  CAR_LIST: Route;
  CHAT_BOT_INFO: Route;
};

type TestDriveTypes = {
  TEST_DRIVE_CHOOSE_ENGINE: Route;
  TEST_DRIVE_ROUTE: Route;
  TEST_DRIVE_REQUEST: Route;
};
type TradeInTypes = {
  TRADE_IN: Route;
};
type ComparisonTypes = {
  COMPARISON: Route;
  COMPARISON_CHOOSE_CAR: Route;
  COMPARISON_CHOOSE_ENGINE: Route;
  COMPARISON_CHOOSE_PACKAGE: Route;
};
type AccessoriesTypes = {
  ACCESSORIES_LIST: Route;
};

type CreditInsuranceTypes = {
  INSURANCE: Route;
  INSURANCE_PROGRAM: Route;
  CREDIT: Route;
  CREDIT_PROGRAM: Route;
};

type AllPages = BasePages &
  NewCarsPagesType &
  UsedCarsPagesType &
  ServicePagesTypes &
  PromotionsPageTypes &
  TestDriveTypes &
  CreditInsuranceTypes &
  ConfiguratorPagesType &
  AccessoriesTypes &
  ComparisonTypes & {};

export const BASE_PAGES: BasePages = {
  HOME: { route: '/', path: '/' },
  PAGE_NOT_FOUND: { route: '*', path: '' },
  NEW_CARS: { route: '/new-cars', path: 'new-cars' },
  USED_CARS: { route: '/used-cars', path: 'used-cars' },
  SERVICE: { route: '/service', path: 'service' },
  CREDIT_AND_INSURANCE: {
    route: '/credit-and-insurance',
    path: 'credit-and-insurance',
  },
  ACCESSORIES: { route: '/accessory', path: 'accessory' },
  PROMOTIONS: {
    route: '/promotions',
    path: 'promotions',
  },
  MY_CONFIGURATIONS: { route: '/my-configurations', path: 'my-configurations' },
  COMPARISONS: { route: '/comparisons', path: 'comparisons' },
  CONFIGURATOR: { route: '/configurator', path: 'configurator' },
  TRADE_IN: { route: '/trade-in', path: 'trade-in' },
  TEST_DRIVE: { route: '/test-drive', path: 'test-drive' },
  PRIVACY_POLICY: { route: '/privacy-policy', path: 'privacy-policy' },
};

const NewCarsPages: NewCarsPagesType = {
  NEW_CARS_LIST: {
    route: BASE_PAGES.NEW_CARS.route + '/list',
    path: BASE_PAGES.NEW_CARS.path + '/list',
  },
  NEW_CARS_CHOOSE_ENGINE: {
    route: BASE_PAGES.NEW_CARS.route + '/choose-engine',
    path: BASE_PAGES.NEW_CARS.path + '/choose-engine',
  },
  NEW_CARS_CHOOSE_PACKAGE: {
    route: BASE_PAGES.NEW_CARS.route + '/choose-package',
    path: BASE_PAGES.NEW_CARS.path + '/choose-package',
  },
  NEW_CAR: {
    route: BASE_PAGES.NEW_CARS.route + '/new-car',
    path: BASE_PAGES.NEW_CARS.path + '/new-car',
  },
  NEW_CAR_DESCRIPTION_OF_MODEL: {
    route: BASE_PAGES.NEW_CARS.route + '/descriptions',
    path: BASE_PAGES.NEW_CARS.path + '/descriptions',
  },
  NEW_CAR_ACCESSORIES: {
    route: BASE_PAGES.NEW_CARS.route + '/accessories',
    path: BASE_PAGES.NEW_CARS.path + '/accessories',
  },
  NEW_CAR_WARRANTY_AND_SERVICE: {
    route: BASE_PAGES.NEW_CARS.route + '/warranty_service',
    path: BASE_PAGES.NEW_CARS.path + '/warranty_service',
  },
  NEW_CARS_AT_THE_DEALER: {
    route: BASE_PAGES.NEW_CARS.route + '/dealer-cars',
    path: BASE_PAGES.NEW_CARS.path + '/dealer-cars',
  },
  NEW_CAR_CHARACTERISTICS: {
    route: BASE_PAGES.NEW_CARS.route + '/specifications',
    path: BASE_PAGES.NEW_CARS.path + '/specifications',
  },
  NEW_CAR_EQUIPMENT: {
    route: BASE_PAGES.NEW_CARS.route + '/equipment',
    path: BASE_PAGES.NEW_CARS.path + '/equipment',
  },
  NEW_CAR_PHOTO_LIBRARY: {
    route: BASE_PAGES.NEW_CARS.route + '/photo_selection',
    path: BASE_PAGES.NEW_CARS.path + '/photo_selection',
  },
  NEW_CARS_PRICES_AND_PACKAGES: {
    route: BASE_PAGES.NEW_CARS.route + '/prices',
    path: BASE_PAGES.NEW_CARS.path + '/prices',
  },
  //STOK CAR
  NEW_CARS_AT_THE_STOCK: {
    route: BASE_PAGES.NEW_CARS.route + '/stock-cars',
    path: BASE_PAGES.NEW_CARS.path + '/stock-cars',
  },
  STOCK_CAR: {
    route: BASE_PAGES.NEW_CARS.route + '/stock-car',
    path: BASE_PAGES.NEW_CARS.path + '/stock-car',
  },
  STOCK_CAR_DESCRIPTION_OF_MODEL: {
    route: BASE_PAGES.NEW_CARS.route + '/stock-car/descriptions',
    path: BASE_PAGES.NEW_CARS.path + '/stock-car/descriptions',
  },
  STOCK_CAR_CHARACTERISTICS: {
    route: BASE_PAGES.NEW_CARS.route + '/stock-car/specifications',
    path: BASE_PAGES.NEW_CARS.path + '/stock-car/specifications',
  },
  STOCK_CAR_ACCESSORIES: {
    route: BASE_PAGES.NEW_CARS.route + '/stock-car/accessories',
    path: BASE_PAGES.NEW_CARS.path + '/stock-car/accessories',
  },
  STOCK_CAR_PHOTO_LIBRARY: {
    route: BASE_PAGES.NEW_CARS.route + '/stock-car/photo_selection',
    path: BASE_PAGES.NEW_CARS.path + '/stock-car/photo_selection',
  },
  STOCK_CAR_EQUIPMENT: {
    route: BASE_PAGES.NEW_CARS.route + '/stock-car/equipment',
    path: BASE_PAGES.NEW_CARS.path + '/stock-car/equipment',
  },
};

const UsedCarsPages: UsedCarsPagesType = {
  USED_CARS_LIST: {
    route: BASE_PAGES.USED_CARS.route,
    path: BASE_PAGES.USED_CARS.path,
  },
  USED_CAR_INFO: {
    route: BASE_PAGES.USED_CARS.route + '/car-info',
    path: BASE_PAGES.USED_CARS.path + '/car-info',
  },
  USED_CAR_KIT: {
    route: BASE_PAGES.USED_CARS.route + '/car-kit',
    path: BASE_PAGES.USED_CARS.path + '/car-kit',
  },
  USED_CAR_FEATURE_OF_PROGRAM: {
    route: BASE_PAGES.USED_CARS.route + '/feature-of-program',
    path: BASE_PAGES.USED_CARS.path + 'feature-of-program',
  },
  USED_CAR_DESCRIPTION_OF_CAR_MODEL: {
    route: BASE_PAGES.USED_CARS.route + '/description-of-model',
    path: BASE_PAGES.USED_CARS.path + 'description-of-model',
  },
  USED_CAR_CHARACRETISTICS: {
    route: BASE_PAGES.USED_CARS.route + '/characteristics',
    path: BASE_PAGES.USED_CARS.path + 'characteristics',
  },
};

const ServicePages: ServicePagesTypes = {
  SERVICE_INFO: {
    route: BASE_PAGES.SERVICE.route + '/service-info',
    path: BASE_PAGES.SERVICE.path + 'service-info',
  },
  MAINTENANCE: {
    route: BASE_PAGES.SERVICE.route + '/maintenance',
    path: BASE_PAGES.SERVICE.path + 'maintenance',
  },
  SERVICE_CHOOSE_YEAR: {
    route: BASE_PAGES.SERVICE.route + '/choose-year',
    path: BASE_PAGES.SERVICE.path + 'choose-year',
  },
  SERVICE_CHOOSE_ENGINE: {
    route: BASE_PAGES.SERVICE.route + '/choose-engine',
    path: BASE_PAGES.SERVICE.path + 'choose-engine',
  },
  SERVICE_MAINTENANCE_INFO: {
    route: BASE_PAGES.SERVICE.route + '/maintenance-info',
    path: BASE_PAGES.SERVICE.path + 'maintenance-info',
  },
};
const PromotionsPages: PromotionsPageTypes = {
  PROMOTIONS_LIST: {
    route: BASE_PAGES.PROMOTIONS.route + '/list',
    path: BASE_PAGES.PROMOTIONS.path + 'list',
  },
  PROMOTIONS_LIST_ITEM: {
    route: BASE_PAGES.PROMOTIONS.route + '/list/:id',
    path: BASE_PAGES.PROMOTIONS.path + 'list/:id',
  },
  VIEW_TECHNOLOGIES: {
    route: BASE_PAGES.PROMOTIONS.route + '/view-technologies',
    path: BASE_PAGES.PROMOTIONS.path + 'view-technologies',
  },
  CHAT_BOT_INFO: {
    route: BASE_PAGES.PROMOTIONS.route + '/chat-bot-info',
    path: BASE_PAGES.PROMOTIONS.path + 'chat-bot-info',
  },
  DEMONSTRATE_TECHNOLOGIES: {
    route: BASE_PAGES.PROMOTIONS.route + '/demonstrate-technologies',
    path: BASE_PAGES.PROMOTIONS.path + 'demonstrate-technologies',
  },
  CAR_LIST: {
    route: BASE_PAGES.PROMOTIONS.route + '/car-list',
    path: BASE_PAGES.PROMOTIONS.path + 'car-list',
  }
};
const TradeInPages: TradeInTypes = {
  TRADE_IN: {
    route: BASE_PAGES.TRADE_IN.route,
    path: BASE_PAGES.TRADE_IN.path,
  },
};
const ComparisonPages: ComparisonTypes = {
  COMPARISON: {
    route: BASE_PAGES.COMPARISONS.route,
    path: BASE_PAGES.COMPARISONS.path,
  },
  COMPARISON_CHOOSE_CAR: {
    route: BASE_PAGES.COMPARISONS.route + '/list',
    path: BASE_PAGES.COMPARISONS.path + 'list',
  },
  COMPARISON_CHOOSE_ENGINE: {
    route: BASE_PAGES.COMPARISONS.route + '/choose-engine',
    path: BASE_PAGES.COMPARISONS.path + 'choose-engine',
  },
  COMPARISON_CHOOSE_PACKAGE: {
    route: BASE_PAGES.COMPARISONS.route + '/choose-package',
    path: BASE_PAGES.COMPARISONS.path + 'choose-engine',
  },
};
const AccessoriesPages: AccessoriesTypes = {
  ACCESSORIES_LIST: {
    route: BASE_PAGES.ACCESSORIES.route + '/list',
    path: BASE_PAGES.ACCESSORIES.path + 'list',
  },
};
const TestDrivePages: TestDriveTypes = {
  TEST_DRIVE_CHOOSE_ENGINE: {
    route: BASE_PAGES.TEST_DRIVE.route + '/choose-engine',
    path: BASE_PAGES.TEST_DRIVE.path + 'choose-engine',
  },
  TEST_DRIVE_ROUTE: {
    route: BASE_PAGES.TEST_DRIVE.route + '/route',
    path: BASE_PAGES.TEST_DRIVE.path + 'route',
  },
  TEST_DRIVE_REQUEST: {
    route: BASE_PAGES.TEST_DRIVE.route + '/request',
    path: BASE_PAGES.TEST_DRIVE.path + 'request',
  },
};
const ConfiguratorPages: ConfiguratorPagesType = {
  CONFIGURATOR_LIST: {
    route: BASE_PAGES.CONFIGURATOR.route,
    path: BASE_PAGES.CONFIGURATOR.path,
  },
  CONFIGURATOR_CHOOSE_ENGINE: {
    route: BASE_PAGES.CONFIGURATOR.route + '/choose-engine',
    path: BASE_PAGES.CONFIGURATOR.path + '/choose-engine',
  },
  CONFIGURATOR_CHOOSE_CAR_BODY: {
    route: BASE_PAGES.CONFIGURATOR.route + '/choose-car-body',
    path: BASE_PAGES.CONFIGURATOR.path + '/choose-car-body',
  },
  CONFIGURATOR_CHOOSE_PACKAGE: {
    route: BASE_PAGES.CONFIGURATOR.route + '/choose-package',
    path: BASE_PAGES.CONFIGURATOR.path + '/choose-package',
  },
  CONFIGURATOR_CHOOSE_OPTIONS: {
    route: BASE_PAGES.CONFIGURATOR.route + '/choose-options',
    path: BASE_PAGES.CONFIGURATOR.path + '/choose-options',
  },
  CONFIGURATOR_CHOOSE_COLOR: {
    route: BASE_PAGES.CONFIGURATOR.route + '/choose-color',
    path: BASE_PAGES.CONFIGURATOR.path + '/choose-color',
  },
  CONFIGURATOR_CHOOSE_ACCESSORIES: {
    route: BASE_PAGES.CONFIGURATOR.route + '/choose-accessories',
    path: BASE_PAGES.CONFIGURATOR.path + '/choose-accessories',
  },
  CONFIGURATOR_CHOOSE_RESULTS: {
    route: BASE_PAGES.CONFIGURATOR.route + '/results',
    path: BASE_PAGES.CONFIGURATOR.path + '/results',
  },
  CONFIGURATOR_MY_CONFIGURATIONS: {
    route: BASE_PAGES.CONFIGURATOR.route + '/my-configurations',
    path: BASE_PAGES.CONFIGURATOR.path + '/my-configurations',
  },
  CONFIGURATOR_MY_CONFIGURATIONS_CAR: {
    route: BASE_PAGES.CONFIGURATOR.route + '/my-configurations-car',
    path: BASE_PAGES.CONFIGURATOR.path + '/my-configurations-car',
  },
};

const CreditInsurancePages: CreditInsuranceTypes = {
  CREDIT: {
    route: BASE_PAGES.CREDIT_AND_INSURANCE.route + '/credit',
  },
  CREDIT_PROGRAM: {
    route: BASE_PAGES.CREDIT_AND_INSURANCE.route + '/credit-program/:id',
  },
  INSURANCE: {
    route: BASE_PAGES.CREDIT_AND_INSURANCE.route + '/insurance',
  },
  INSURANCE_PROGRAM: {
    route: BASE_PAGES.CREDIT_AND_INSURANCE.route + '/insurance-program/:id',
  },
};

export const ROUTES: AllPages = {
  ...BASE_PAGES,

  // NEW CARS
  ...NewCarsPages,

  // USED CARS
  ...UsedCarsPages,

  // SERVICE
  ...ServicePages,

  // PROMOTION
  ...PromotionsPages,

  // TEST_DRIVE
  ...TestDrivePages,

  // CREDIT_INSURANCE
  ...CreditInsurancePages,

  //CONFIGURATOR
  ...ConfiguratorPages,

  //TRADE_IN
  ...TradeInPages,

  //ACCESSORIES
  ...AccessoriesPages,

  //COMPARISON
  ...ComparisonPages,
};
