import React from 'react';
import { ArrowBackButton } from '../Buttons';
import './ScreenTitle.scss';
import Info from '@assets/svg/Info';
import {checkIs2sDealer} from "@utils/checkIs2sDealer";

type Props = {
  backButton?: boolean;
  grayText?: string;
  blackText?: string;
  rightContent?: React.ReactNode;
  marginBottom?: number;
  onBack?: VoidFunction;
  prefix?: string | null | undefined;
  suffix?: string | null | undefined;
  infoIconFc?: any;
};

const ScreenTitle = ({
  grayText,
  blackText,
  rightContent,
  backButton = true,
  marginBottom,
  onBack,
  prefix,
  suffix,
  infoIconFc,
}: Props) => {
  return (
    <div className="screen-title" style={{ marginBottom }}>
      {backButton ? <ArrowBackButton onClick={onBack} /> : null}
      {/*<div className="title-container">*/}
      <div
        className={`title-container ${backButton && 'title-container__margin'}`}
      >
        {prefix && (
          <span
            className={`screen-title__text ${
              grayText ? 'gray-text' : 'black-text'
            }`}
          >
            {prefix}&nbsp;
          </span>
        )}
        {grayText && (
          <span className="screen-title__text gray-text">{grayText}&nbsp;</span>
        )}
        {blackText && (
          <span className="screen-title__text black-text">
            {blackText}&nbsp;
            {infoIconFc && checkIs2sDealer() && (
              <Info
                fill="#6c7073"
                onClick={() => infoIconFc()}
                width="30px"
                height="30px"
              />
            )}
          </span>
        )}
        {suffix && (
          <span
            className={`screen-title__text ${
              grayText ? 'gray-text' : 'black-text'
            }`}
          >
            {suffix}&nbsp;
          </span>
        )}
      </div>

      {rightContent && (
        <div className="screen-title__right">{rightContent}</div>
      )}
    </div>
  );
};

export default ScreenTitle;
