import * as React from 'react';

const Viber = (props) => (
  <svg
    width={48}
    height={49}
    viewBox="0 0 96 97"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M48 8.275c22.091 0 40 17.909 40 40 0 22.092-17.909 40-40 40s-40-17.908-40-40c0-22.091 17.909-40 40-40Z"
      fill="#7360F2"
    />
    <path
      d="M64.775 31.323c-1.135-1.07-5.724-4.472-15.944-4.518 0 0-12.053-.742-17.929 4.762-3.27 3.34-4.42 8.229-4.542 14.29-.121 6.06-.278 17.418 10.442 20.498h.01l-.007 4.7s-.068 1.902 1.159 2.29c1.483.47 2.354-.975 3.77-2.534.778-.856 1.852-2.113 2.661-3.074 7.332.63 12.97-.81 13.611-1.023 1.48-.49 9.857-1.587 11.22-12.943 1.406-11.706-.68-19.11-4.451-22.448Zm1.242 21.608c-1.15 9.483-7.944 10.079-9.196 10.49-.533.174-5.486 1.433-11.714 1.017 0 0-4.64 5.718-6.09 7.205-.226.232-.492.326-.67.28-.25-.063-.318-.364-.315-.806l.04-7.809s-.006 0 0 0c-9.069-2.571-8.54-12.238-8.437-17.3.102-5.062 1.034-9.21 3.8-11.998 4.97-4.597 15.207-3.91 15.207-3.91 8.646.038 12.788 2.697 13.749 3.588 3.19 2.789 4.815 9.463 3.626 19.24v.003Z"
      fill="#fff"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M49.006 41.913a.583.583 0 0 1 .608-.559c1.193.064 2.185.433 2.892 1.179.704.743 1.049 1.782 1.11 3.03a.586.586 0 0 1-.55.618.583.583 0 0 1-.605-.56c-.052-1.068-.339-1.795-.786-2.267-.445-.47-1.124-.767-2.121-.82a.586.586 0 0 1-.548-.62Z"
      fill="#fff"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M48.13 38.733c.024-.325.3-.57.62-.546 2.426.182 4.38 1.029 5.803 2.615 1.408 1.571 2.077 3.525 2.029 5.818a.584.584 0 0 1-.59.577.585.585 0 0 1-.566-.603c.043-2.021-.54-3.671-1.725-4.995-1.183-1.318-2.836-2.07-5.036-2.234a.586.586 0 0 1-.535-.632Z"
      fill="#fff"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M47.11 35.71a.584.584 0 0 1 .582-.587c3.354.024 6.19 1.164 8.466 3.43 2.296 2.286 3.43 5.398 3.46 9.262a.584.584 0 0 1-.574.595.584.584 0 0 1-.582-.586c-.027-3.621-1.082-6.405-3.11-8.425-2.049-2.038-4.591-3.074-7.668-3.095a.584.584 0 0 1-.574-.595Z"
      fill="#fff"
    />
    <path
      d="M49.699 53.498s.812.07 1.25-.48l.852-1.096c.412-.544 1.405-.89 2.377-.337.729.424 1.436.886 2.12 1.382.645.485 1.966 1.61 1.97 1.61.63.543.775 1.34.346 2.18l-.003.018a9.057 9.057 0 0 1-1.775 2.233c-.009.004-.009.008-.016.012-.612.522-1.213.819-1.803.89a1.168 1.168 0 0 1-.263.017c-.26.002-.52-.038-.767-.12l-.019-.03c-.91-.261-2.427-.917-4.955-2.34a29.46 29.46 0 0 1-4.163-2.81 21.964 21.964 0 0 1-1.873-1.696l-.063-.064-.063-.064-.063-.065c-.022-.02-.042-.043-.063-.064a22.151 22.151 0 0 1-1.661-1.913 30.224 30.224 0 0 1-2.75-4.25c-1.395-2.583-2.037-4.132-2.293-5.062l-.028-.02a2.502 2.502 0 0 1-.117-.783c-.005-.09 0-.18.015-.269.074-.601.365-1.216.873-1.842.004-.008.008-.008.012-.016a8.921 8.921 0 0 1 2.186-1.812c.005 0 .013-.005.017-.005.823-.437 1.604-.289 2.134.35.004.005 1.105 1.354 1.577 2.013.487.699.938 1.422 1.354 2.167.542.992.202 2.008-.33 2.427l-1.073.87c-.542.448-.47 1.277-.47 1.277s1.589 6.144 7.528 7.692Z"
      fill="#fff"
    />
  </svg>
);

export default Viber;
