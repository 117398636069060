import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  useGetDealerListQuery,
  useGetScreensForDealerQuery,
  useGetZonesForDealerQuery,
} from 'store/dealers/api';
import { Footer, Header, ScreenContentLayout } from '../../components/common';
import { Pressable } from '../../components/wrappers';
import { useGetNewCarsModelsQuery } from '../../store/newCars/api';
// import './MainScreen.scss';
import { Option } from 'types';
import { useForm } from 'react-hook-form';
import Select from 'components/Select';
import { useDispatch, useSelector } from 'react-redux';
import {
  setDealer,
  setDealerName,
  setZone,
  selectedDealer,
  selectedZone,
  setScreen,
  setZoneId,
  setIs2sDealer,
} from 'store/dealers/slice';
import uuid from 'react-uuid';
import { getUrl } from 'utils/getUrl';
import { Modal } from '@mui/material';
import { ConsultationModal } from '@components/Modals';
import { usePagination } from 'hooks/usePagination';
import { AbsoluteCardsContainer } from 'components/layout';
import { useGetScreenDataQuery } from 'store/screens/api';
import { GetScreenDataResponse } from 'store/screens/types';
import classNames from 'classnames/bind';
import styles from './MainScreen.module.scss';
import CustomSelect from '@components/CustomSelect';
import getTranslateText from '@utils/getTranslateText';
import { translationsDataArr } from '@store/translate/slice';
import { getGlodalSettingsData } from '@api/modal';
import PaginationWithSwipe from '@components/PaginationWithSwipe';
import { useSwipePagination } from 'hooks/useSwipePagination';
import TagManager from 'react-gtm-module';
import {addSessionId} from "@utils/addUserSessionId";
const cx = classNames.bind(styles);

type ScreenDataSection = {
  alert_message: string;
  alert_pop_up: string;
  alert_published: string;
  alert_label: string;
  displayed: string;
  image: string;
  tab_text: string;
};

export const MainScreen = () => {
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const state = location.state;
  const [isShowConsultationModal, setIsShowConsultationModal] =
    React.useState(false);
  // @ts-ignore
  const selectedDealerStorage = localStorage.getItem('dealerCode') || '';

  const translateArr = useSelector(translationsDataArr);
  const [haveBanner, setHaveBanner] = useState(false);
  const [countBanner, setCountBanner] = useState(0);
  const [countCard, setCountCard] = useState(0);
  const [showPage, setShowPage] = useState(1);
  const [screenId, setScreenId] = useState(null);
  // const [dealerValue, setDealerValue] = useState(null);
  // const [zoneValue, setZoneValue] = useState(null);
  // const [formData, setFormData] = useState({});
  // const [fieldErrors, setFieldErrors] = useState({});

  const dealer = useSelector(selectedDealer);
  const zona = useSelector(selectedZone);

  const { data } = useGetNewCarsModelsQuery({
    dealer_dealer_code: 'AZ',
    filter: undefined,
    sortBy: 'most_popular',
  });

  const {
    resetField,
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    reValidateMode: 'onChange',
  });

  const getTimeOutModalSettings = () => {
    getGlodalSettingsData().then((res) => {
      localStorage.setItem(
        'time_hold_finish_button',
        res.data.data[0].timer_settings.time_hold_finish_button,
      );
    });
  };

  useEffect(() => {
    getTimeOutModalSettings();
    localStorage.removeItem('user_session_id');
  }, []);

  // const handleDealer = (e: any) => {
  //   if (e === null) {
  //     setDealerValue(null);
  //     setZoneValue(null);
  //     setScreenId(null);
  //     setFormData({});
  //   } else {
  //     setDealerValue(e?.code);
  //     dispatch(setDealer(e?.code));
  //     dispatch(setDealerName(e?.name));
  //     dispatch(setIs2sDealer(e?.is_2s_dealer));
  //     setFormData({
  //       ...formData,
  //       dealerCode: {
  //         code: e?.code,
  //         name: e?.name,
  //         is_2s_dealer: e?.is_2s_dealer,
  //       },
  //     });
  //   }
  // };
  // const handleZone = (e: any) => {
  //   if (e === null) {
  //     // @ts-ignore
  //     const { zoneCode, screenId, ...newState } = formData;
  //     setZoneValue(null);
  //     setScreenId(null);
  //     setFormData(newState);
  //   } else {
  //     setZoneValue(e?.slug);
  //     dispatch(setZone(e?.slug));
  //     setFormData({
  //       ...formData,
  //       zoneCode: {
  //         code: e?.slug,
  //         id: e?.id,
  //         slug: e?.slug,
  //       },
  //     });
  //   }
  // };

  // const handleScreenId = (id: any) => {
  //   if (id === null) {
  //     // @ts-ignore
  //     const { screenId, ...newState } = formData;
  //     setScreenId(null);
  //     setFormData(newState);
  //   } else {
  //     setScreenId(id);
  //     setFormData({
  //       ...formData,
  //       screenId: {
  //         id: id.id,
  //       },
  //     });
  //   }
  // };

  // const validateForm = (validateSchema: any, validateData: any) => {
  //   const schemaKeys = Object.keys(validateSchema);
  //   const errorList = {};
  //   for (let key of schemaKeys) {
  //     if (
  //       !validateData.hasOwnProperty(key) ||
  //       validateData[key] === undefined ||
  //       validateData[key] === null
  //     ) {
  //       // @ts-ignore
  //       errorList[key] = true;
  //     }
  //   }
  //   if (Object.keys(errorList).length === 0) {
  //     setFieldErrors({});
  //     return true;
  //   } else {
  //     setFieldErrors(errorList);
  //     return false;
  //   }
  // };

  // const onSubmit = (data: any) => {
  //   const validateSchema = {
  //     dealerCode: true,
  //     zoneCode: true,
  //     screenId: true,
  //   };

  //   if (validateForm(validateSchema, data)) {
  //     dispatch(setDealer(data.dealerCode?.code));
  //     dispatch(setDealerName(data.dealerCode?.name));
  //     dispatch(setZone(data.zoneCode?.slug));
  //     dispatch(setZoneId(data.zoneCode?.id));
  //     dispatch(setScreen(data.screenId?.id));
  //     dispatch(setIs2sDealer(data.dealerCode?.is_2s_dealer));
  //     localStorage.setItem('dealerCode', data.dealerCode.code);
  //     localStorage.setItem('is2sDealer', data.dealerCode.is_2s_dealer);
  //     localStorage.setItem('dealerName', data.dealerCode.name);
  //     data.zoneCode?.slug !== undefined &&
  //       localStorage.setItem('zoneCode', data.zoneCode?.slug);
  //     data.zoneCode?.slug !== undefined &&
  //       localStorage.setItem('zoneId', data.zoneCode?.id);
  //     data.screenId?.id !== undefined &&
  //       localStorage.setItem('screenDataCode', data.screenId?.id);
  //     if (state?.navigateTo) {
  //       navigation(`${state.navigateTo}`);
  //     }
  //     // @ts-ignore
  //     // window.location.reload(true);
  //   }
  // };

  const { isLoading: isLoadingDealerList, data: dealerListResponse } =
    useGetDealerListQuery({});

  const { isLoading: isLoadingZoneList, data: zoneListResponse } =
    useGetZonesForDealerQuery({ dealer_code: dealer }, { skip: !dealer });

  const { isLoading: isLoadingScreenList, data: screenListResponse } =
    useGetScreensForDealerQuery(
      { dealer_code: dealer, zone: zona },
      { skip: !dealer },
    );

  const dealerList = React.useMemo(
    () => dealerListResponse?.data,
    [dealerListResponse],
  );

  const zoneList = React.useMemo(
    () => zoneListResponse?.data,
    [zoneListResponse],
  );

  const screenList = React.useMemo(
    () => screenListResponse?.data,
    [screenListResponse],
  );

  const { isLoading: isLoadingScreenData, data: screenDataResponse } =
    useGetScreenDataQuery({
      dealer_dealer_code: dealer || 'AZ',
      template: 'main_page',
      zona: zona,
    });

  const screenData: GetScreenDataResponse<
    {
      additional_alert_label: string;
      additional_alert_pop_up: string;
      additional_alert_message: string;
      additional_alert_published: string;
    },
    { title_prefix: string; title_suffix: string | null },
    ScreenDataSection
  > = React.useMemo(() => screenDataResponse?.data, [screenDataResponse]);

  const goToPage = (url: string) => {
    // localStorage.setItem('user_session_id', uuid());
    navigation(`${url}`);
  };

  const isLoading = React.useMemo(
    () => isLoadingDealerList || isLoadingScreenData,
    [isLoadingDealerList, isLoadingScreenData],
  );

  const countBannerFc = () => {
    let count = screenData?.sections?.reduce((acc: any, el: any) => {
      let counted =
        el.type === 'newCars' || el.type === 'banner' ? acc + 1 : acc + 0;
      return counted;
    }, 0);
    setCountBanner(count);
  };
  const countCardFc = () => {
    let count = screenData?.sections?.reduce((acc: any, el: any) => {
      let counted = el.type === 'default' ? acc + 1 : acc + 0;
      return counted;
    }, 0);
    setCountCard(count);
  };

  const CardList = ({ cards }: any) => {
    return (
      <>
        {cards?.map((card: any, i: any) => (
          <li
            className={
              card.type === 'newCars' || card.type === 'banner'
                ? cx('car-list__item', 'banner')
                : cx('car-list__item')
            }
            key={i}
          >
            <Pressable
              classes={
                card.type === 'newCars'
                  ? cx('main-content__car', 'main-content__cars-new')
                  : card.type === 'banner'
                  ? cx('main-content__car', 'main-content__banner')
                  : cx('default')
              }
              onClick={() => goToPage(getUrl(card.template))}
              style={(pressed) => ({
                opacity: pressed ? 0.7 : 1,
              })}
            >
              <div
                className={
                  card.type === 'newCars' || card.type === 'banner'
                    ? cx('main-content__cars-img')
                    : cx('main-content__default-img')
                }
              >
                <img src={card.image} alt={card?.type} />
              </div>
              <div
                className={
                  card.type === 'newCars' || card.type === 'banner'
                    ? cx('main-content__cars-content')
                    : cx('main-content__cars-content', 'default-content')
                }
              >
                <p
                  className={
                    card.type === 'newCars' || card.type === 'banner'
                      ? cx('main-content__cars-title')
                      : cx('main-content__cars-title', 'default')
                  }
                >
                  {card.label}
                </p>
                <p
                  className={
                    card.type === 'default'
                      ? cx('main-content__cars-description', 'default')
                      : cx('main-content__cars-description')
                  }
                >
                  {card.type === 'newCars'
                    ? `${data?.data.length || 0} моделей`
                    : card?.short_description?.length > 150
                    ? `${card?.short_description.substring(0, 150)}...`
                    : card?.short_description}
                </p>
                {card.type === 'newCars' || card.type === 'banner' ? (
                  <div className={cx('main-content__cars-button')}>
                    <p className={cx('main-content__cars-description')}>
                      Переглянути
                    </p>
                  </div>
                ) : (
                  ''
                )}
              </div>
            </Pressable>
          </li>
        ))}
      </>
    );
  };

  useEffect(() => {
    countBannerFc();
    countCardFc();
  }, [screenData]);

  const {
    countOfPages,
    onClickNext,
    onClickPrev,
    currentPage,
    showedDataSlice,
    nextShowedDataSlice,
  } = usePagination(
    (screenData && screenData?.sections?.length) || 1,
    countBanner === 1 && countCard >= 6 ? 7 : 6,
  );

  const { onSlidePrev, onSlideNext, setSwiper, setIndex, index } =
    useSwipePagination(countOfPages);

  useEffect(() => {
    setShowPage(currentPage);
  }, [currentPage, haveBanner, countBanner, countCard, showPage, screenData]);

  const getPagination: any =
    selectedDealerStorage === ''
      ? {}
      : {
          countOfPages: countOfPages,
          currentPage,
          onClickNextButton: onClickNext,
          onClickPrevButton: onClickPrev,
        };

  const handleDealer = (e: any) => {
    if (e === null) {
      reset();
    } else {
      dispatch(setDealer(e?.code));
      dispatch(setDealerName(e?.name));
      dispatch(setIs2sDealer(e?.is_2s_dealer));
    }
  };
  const handleZone = (e: any) => {
    if (e === null) {
      setValue('zoneCode', null);
      setValue('screenId', null);
    } else {
      dispatch(setZone(e?.slug));
    }
  };

  const onSubmit = (data: any) => {
    TagManager.dataLayer({
      dataLayer: addSessionId({
        dealer_name: data.dealerCode.name,
        dealer_id: data.dealerCode.code,
        dealer_zone_name: data.zoneCode?.slug,
        page_name: 'Головна сторінка',
      }),
    });
    dispatch(setDealer(data.dealerCode.code));
    dispatch(setDealerName(data.dealerCode.name));
    dispatch(setZone(data.zoneCode?.slug));
    dispatch(setZoneId(data.zoneCode?.id));
    dispatch(setScreen(data.screenId?.id));
    dispatch(setIs2sDealer(data.dealerCode.is_2s_dealer));
    localStorage.setItem('dealerCode', data.dealerCode.code);
    localStorage.setItem('is2sDealer', data.dealerCode.is_2s_dealer);
    localStorage.setItem('dealerName', data.dealerCode.name);
    data.zoneCode?.slug !== undefined &&
      localStorage.setItem('zoneCode', data.zoneCode?.slug);
    data.zoneCode?.slug !== undefined &&
      localStorage.setItem('zoneId', data.zoneCode?.id);
    data.screenId?.id !== undefined &&
      localStorage.setItem('screenDataCode', data.screenId?.id);
    if (state?.navigateTo) {
      navigation(`${state.navigateTo}`);
    }
  };

  React.useEffect(() => {
    if (localStorage.getItem('user_session_id') === null) {
      localStorage.setItem('user_session_id', uuid());
    }
    TagManager.dataLayer({
      dataLayer: addSessionId({
        page_name: 'Головна сторінка',
        dealer_name: localStorage.getItem('dealerName'),
        dealer_id: localStorage.getItem('dealerCode'),
        dealer_zone_name: localStorage.getItem('zoneCode'),
      }),
    });
  }, []);

  return (
    <div className={cx('main-container')}>
      <Header
        showLogoText={false}
        showLogo={true}
        showComprassionsButton={false}
        showConfigurationButton={false}
        showAskButton={false}
        showLogoutButton={false}
        showConsultationButton={selectedDealerStorage === '' ? false : true}
      />
      {selectedDealerStorage === '' ? (
        <div className={cx('main-content')}>
          <div className={cx('main-content__title')}>
            <p>Спочатку оберіть дилера і зону</p>
          </div>
          <form className={cx('form')} onSubmit={handleSubmit(onSubmit)}>
            <div className={cx('form__line')}>
              <Select
                control={control}
                options={dealerList}
                label={getTranslateText(
                  translateArr,
                  'formField.placeholder.select.dealer',
                )}
                name="dealerCode"
                isSearchable={false}
                getOptionValue={(option: any) => option.code}
                getOptionLabel={(option: any) => option.name}
                onChange={(e: any) => handleDealer(e)}
                isClearable
                rules={{
                  required: getTranslateText(
                    translateArr,
                    'validation.required',
                  ),
                }}
                errors={errors.dealerCode}
                required={true}
                optValueKey="code"
                optLabelKey="name"
                selectedValueKey="code"
              />
            </div>

            <div className={cx('form__line')}>
              <Select
                control={control}
                options={zoneList}
                label={getTranslateText(
                  translateArr,
                  'formField.placeholder.select.zone',
                )}
                rules={{
                  required: getTranslateText(
                    translateArr,
                    'validation.required',
                  ),
                }}
                name="zoneCode"
                isSearchable={false}
                getOptionValue={(option: any) => option.id}
                getOptionLabel={(option: any) => option.label}
                onChange={(e: any) => handleZone(e)}
                isClearable
                errors={errors.zoneCode}
                required={true}
                optValueKey="id"
                optLabelKey="label"
                selectedValueKey="id"
              />
            </div>

            <div className={cx('form__line')}>
              <Select
                control={control}
                options={screenList}
                label={getTranslateText(
                  translateArr,
                  'formField.placeholder.select.screen.id',
                )}
                rules={{
                  required: getTranslateText(
                    translateArr,
                    'validation.required',
                  ),
                }}
                name="screenId"
                isSearchable={false}
                getOptionValue={(option: any) => option.id}
                getOptionLabel={(option: any) => option.mac_address}
                isClearable
                errors={errors.screenId}
                required={true}
                optValueKey="id"
                optLabelKey="mac_address"
                selectedValueKey="id"
              />
            </div>
            <div className={cx('form__line', 'buttonWrap')}>
              <button
                type="submit"
                className={cx('submitButton')}
                disabled={isLoading}
              >
                Зберегти
              </button>
            </div>
          </form>
        </div>
      ) : (
        <div className={cx('main-content')}>
          <div className={cx('main-content__title')}>
            {/*<p>Доброго дня, що вас цікавить?</p>*/}
            <p>{isLoading ? '' : screenData?.title}</p>
          </div>
          <div className={cx('main-content__cars')}>
            <ScreenContentLayout
              loading={isLoading}
              classes={
                currentPage === 1 && countBanner >= 1 && countCard >= 3
                  ? cx('cards', 'row-4')
                  : currentPage === 1 && countBanner === 1 && countCard === 0
                  ? cx('cards', 'row-1')
                  : (currentPage === 1 &&
                      countBanner === 2 &&
                      countCard === 0) ||
                    (currentPage === 1 && countBanner === 1 && countCard <= 2)
                  ? cx('cards', 'row-2')
                  : cx('cards', 'row-3')
              }
              bgTransparent
            >
              {/* <PaginationWithSwipe
                countOfPages={countOfPages}
                fullList={screenData?.sections}
                setSwiper={setSwiper}
                handleClickCar={() => console.log('Click')}
                setIndex={setIndex}
                onRenderList={(list) => {
                  return (
                    <>
                      <CardList cards={list} />

                    </>
                  );
                }}
              /> */}
              <CardList
                cards={screenData?.sections?.slice(
                  showedDataSlice.from,
                  showedDataSlice.to,
                )}
              />
              <AbsoluteCardsContainer left={2000}>
                <CardList
                  cards={screenData?.sections?.slice(
                    nextShowedDataSlice.from,
                    nextShowedDataSlice.to,
                  )}
                />
              </AbsoluteCardsContainer>
            </ScreenContentLayout>
          </div>
        </div>
      )}
      <Modal
        open={isShowConsultationModal}
        onClose={() => setIsShowConsultationModal(false)}
        disableAutoFocus={true}
      >
        <ConsultationModal
          closeModal={() =>
            setIsShowConsultationModal(!isShowConsultationModal)
          }
          //@ts-ignore
          dealerCode={dealer}
        />
      </Modal>

      {selectedDealerStorage === '' ? null : (
        <Footer
          marginLeft={783}
          showCallConsultant
          callConsultationClick={() => setIsShowConsultationModal(true)}
          pagination={getPagination}
        />
      )}
    </div>
  );
};
