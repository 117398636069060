import { api } from '../api';
import { GetScreenDataRequest } from './types';

const messagesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getMessengersData: builder.query<any, GetScreenDataRequest>({
      query: ({ }) => {
        return {url: `api/v1/messengers/`,}
      },
    }),
  }),
});

export const { useGetMessengersDataQuery } = messagesApi;

export default messagesApi;
