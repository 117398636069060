
const getTranslateText = (translateArr: any, alias: string ) => {

    if (translateArr.length > 0) {
        const text = translateArr.filter(
            (el: any) => el.alias === alias,
            )[0]?.text?.uk
    
          return(text)
    }


}

export default getTranslateText;