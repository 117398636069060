import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '..';
import dealerApi from './api';
import { InitialState } from './types';

const initialState: InitialState = {
  menuList: null,
  selectedDealer: localStorage.getItem('dealerCode'),
  selectedDealerName: localStorage.getItem('dealerName'),
  selectedZone: localStorage.getItem('zoneCode'),
  selectedZoneId: localStorage.getItem('zoneId'),
  selectedScreen: localStorage.getItem('screenDataCode'),
  is2sDealer: localStorage.getItem('is2sDealer'),
};

const slice = createSlice({
  name: 'dealer',
  initialState,
  reducers: {
    setDealer: (state, action) => {
      state.selectedDealer = action.payload;
    },
    setDealerName: (state, action) => {
      state.selectedDealerName = action.payload;
    },
    setZone: (state, action) => {
      state.selectedZone = action.payload;
    },
    setZoneId: (state, action) => {
      state.selectedZoneId = action.payload;
    },
    setScreen: (state, action) => {
      state.selectedScreen = action.payload;
    },
    setIs2sDealer: (state, action) => {
      state.is2sDealer = action.payload;
    },
  },
  extraReducers: (buider) => {
    buider.addMatcher(
      dealerApi.endpoints.getMenu.matchFulfilled,
      (state, action) => {
        state.menuList = action.payload;
      },
    );
  },
});

export const { setDealer, setZone, setScreen, setZoneId, setDealerName, setIs2sDealer } = slice.actions;

export const menuList = (state: RootState) => state.dealer.menuList;
export const selectedDealer = (state: RootState) => state.dealer.selectedDealer;
export const selectedDealerName = (state: RootState) => state.dealer.selectedDealerName;
export const selectedZone = (state: RootState) => state.dealer.selectedZone;
export const selectedZoneId = (state: RootState) => state.dealer.selectedZoneId;
export const selectedScreen = (state: RootState) => state.dealer.selectedScreen;
export const is2sDealer = (state: RootState) => state.dealer.is2sDealer;

export default slice.reducer;
