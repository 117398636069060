import * as React from 'react';

const Configuration = (props) => (
  <svg
    width={25}
    height={25}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 50 50"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32.353 13.265c3.013 0 5.538-2.177 6.202-5.102h9.974c.813 0 1.471-.685 1.471-1.53 0-.846-.658-1.531-1.47-1.531h-9.975C37.891 2.177 35.366 0 32.353 0s-5.538 2.177-6.202 5.102H1.47C.658 5.102 0 5.787 0 6.632c0 .846.658 1.531 1.47 1.531h24.68c.665 2.925 3.19 5.102 6.203 5.102Zm0-3.06c1.895 0 3.431-1.6 3.431-3.572 0-1.973-1.536-3.572-3.431-3.572s-3.431 1.6-3.431 3.572 1.536 3.571 3.43 3.571ZM1.47 41.837c-.812 0-1.47.685-1.47 1.53 0 .846.658 1.531 1.47 1.531h24.68C26.816 47.823 29.34 50 32.354 50c3.013 0 5.538-2.177 6.202-5.102h9.974c.813 0 1.471-.685 1.471-1.53 0-.846-.658-1.531-1.47-1.531h-9.975c-.664-2.926-3.189-5.102-6.202-5.102s-5.538 2.176-6.202 5.102H1.47Zm30.883 5.102c1.895 0 3.431-1.6 3.431-3.572s-1.536-3.571-3.431-3.571-3.431 1.599-3.431 3.571c0 1.973 1.536 3.572 3.43 3.572ZM48.53 26.53c.812 0 1.47-.685 1.47-1.53s-.658-1.53-1.47-1.53H23.85c-.665-2.926-3.19-5.103-6.203-5.103-3.013 0-5.538 2.177-6.202 5.102H1.471C.658 23.47 0 24.155 0 25c0 .845.658 1.53 1.47 1.53h9.975c.664 2.926 3.189 5.103 6.202 5.103s5.538-2.177 6.202-5.102h24.68ZM14.215 25c0-1.972 1.536-3.571 3.431-3.571s3.431 1.599 3.431 3.571c0 1.972-1.536 3.571-3.43 3.571-1.896 0-3.432-1.599-3.432-3.571Z"
      fill="currentColor"
    />
  </svg>
);

export default Configuration;
