import React, { useEffect, useMemo, useRef, useState } from 'react';
import styles from './style.module.scss';
import { IDisc } from '../../../../store/configuratorCars/types';
import RadioButton from '../../../../components/common/RadioButton';
import Tooltip from '../../../../components/common/Tooltip';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css/navigation';
import { Navigation } from 'swiper';
import formatNumber from '@utils/formatNumbers';
type Props = {
  discList: IDisc[];
  onSelectDisc: (item: any) => void;
  activeDisc: IDisc;
};
const DiscPanel: React.FC<Props> = ({ discList, onSelectDisc, activeDisc }) => {
  const specialList = [...discList, { id: 'empty' }];
  const sliderRef = React.useRef<any>(null);
  const renderPriceBlock = (item: any) => {
    return (
      <div className={'wrapperPriceBlock'}>
        {item?.price && parseInt(item?.price) > 0 ? (
          <span className="price">
            {item?.discount_price && parseInt(item?.discount_price) > 0
              ? formatNumber(parseInt(item?.discount_price))
              : formatNumber(item?.price)}{' '}
            грн
          </span>
        ) : (
          ''
        )}
        {item?.discount_price && parseInt(item?.discount_price) > 0 && (
          <div className={'wrapperDiscount'}>
            <span className="package__discount">
              {formatNumber(item?.price)} грн
            </span>
            {item?.discount_price_disclaimer && (
              <Tooltip
                text={item?.discount_price_disclaimer}
                size={25}
                position="top-right"
              />
            )}
            {/*<Tooltip*/}
            {/*    text={'some text'}*/}
            {/*    size={25}*/}
            {/*    position="right"*/}
            {/*/>*/}
          </div>
        )}
      </div>
    );
  };
  const renderColItem = (item: any) => {
    if (item) {
      if (item.id === 'empty') {
        return <div className={styles.empty}></div>;
      }
      return (
        <div className={styles.wrapperItem}>
          <RadioButton
            bigSize={true}
            active={activeDisc?.id === item.id}
            handleClick={() => {
              onSelectDisc(item);
            }}
            label={
              <div className={styles.wrapperLabel}>
                <div className={styles.wrapperImg}>
                  <img src={item.image} alt={item.name} />
                </div>
                <p className={styles.name}>{item.name}</p>
                {renderPriceBlock(item)}
              </div>
            }
          />
        </div>
      );
    }
  };
  return (
    <div
      ref={sliderRef}
      className={`${styles.wrapperCarouselWithShadow} disabledPrev`}
    >
      <Swiper
        slidesPerView={1.7}
        spaceBetween={0}
        navigation={true}
        modules={[Navigation]}
        className="configuratorDiscSwiper"
        onSlideChange={(e) => {
          if (sliderRef && sliderRef.current) {
            if (e.realIndex === specialList.length - 2)
              sliderRef.current.className = `${sliderRef.current.className} disabledNext`;
            else if (e.realIndex === 0)
              sliderRef.current.className = `${sliderRef.current.className} disabledPrev`;
            else {
              let allClass = sliderRef.current.className;
              const split_string = allClass.split(' ');
              let remove = split_string.filter((item: string) => {
                return item !== 'disabledNext' && item !== 'disabledPrev';
              });
              sliderRef.current.className = `${remove.join(' ')}`;
            }
          }
        }}
      >
        <div className={styles.shadow}></div>
        {specialList.map((item: any, index: number) => (
          <SwiperSlide key={index}>
            {({ isNext, isActive, isPrev }) => {
              return (
                <div className={styles.wrapperList}>{renderColItem(item)}</div>
              );
            }}
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};
export default DiscPanel;
