import React from 'react';
import { useNavigate } from 'react-router-dom';

const PageNotFound = () => {
  const navigate = useNavigate();

  const goBack = () => navigate(-1);

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}>
      <h1 style={{ marginBottom: 40 }}>Сторінку не знайдено</h1>
      <button
        onClick={goBack}
        style={{
          fontSize: 50,
          backgroundColor: 'transparent',
          border: 'none',
        }}>
        Назад
      </button>
    </div>
  );
};

export default PageNotFound;
