import { combineReducers } from '@reduxjs/toolkit';
import { api } from './api';
import menuReducer from './menu/slice';
import usedCarsReducer from './usedCars/slice';
import newCarsReducer from './newCars/slice';
import configuratorCarsReducer from './configuratorCars/slice';
import screensReducer from './screens/slice';
import carsListForService from './service/slice';
import stockCarsReducer from './stockCars/slice';
import dealerReducer from './dealers/slice';
import promotionsReducer from './promotions/slice';
import testDriveReducer from './testDrive/slice';
import modalsReducer from './modals/slice';
import creditInsurance from "./creditInsurance/slice";
import messagesReducer from './messengers/slice';
import accessoriesReducer from './accessories/slice';
import translationsReducer from './translate/slice';
import consultationModal from './consultationModal/slice';

const rootReducer = combineReducers({
  [api.reducerPath]: api.reducer,
  menu: menuReducer,
  usedCars: usedCarsReducer,
  newCars: newCarsReducer,
  configuratorCars: configuratorCarsReducer,
  screens: screensReducer,
  carsForService: carsListForService,
  stockCars: stockCarsReducer,
  dealer: dealerReducer,
  promotions: promotionsReducer,
  testDrive: testDriveReducer,
  modals: modalsReducer,
  creditInsurance: creditInsurance,
  messages: messagesReducer,
  accessories: accessoriesReducer,
  translate: translationsReducer,
  consultationModal: consultationModal

});

export default rootReducer;
