import {createSlice} from "@reduxjs/toolkit";
import promotionsApi from "./api";
import {RootState} from "@store/index";

const initialState: {
  screenData: any;
  screenElementData: any;
  demonstrationList: any;
  demonstrationItem: any;
} = {
  screenData: null,
  screenElementData: null,
  demonstrationList: null,
  demonstrationItem: null
};

const slice = createSlice({
  name: "screens",
  initialState,
  reducers: {
    setCurrentDescription: (state, action) => {
      state.demonstrationItem = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      promotionsApi.endpoints.getPromotions.matchFulfilled,
      (state, action) => {
        state.screenData = action.payload.data;
      },
    ).addMatcher(
      promotionsApi.endpoints.getPromotionsDetail.matchFulfilled,
      (state, action) => {
        state.screenElementData = action.payload.data;
      },
    ).addMatcher(
      promotionsApi.endpoints.getDemonstrationList.matchFulfilled,
      (state, action) => {
        state.demonstrationList = action.payload.data;
      },
    );
  },
});

export const {
  setCurrentDescription,
} =
  slice.actions;
export const selectDemonstration = (state: RootState) =>
  state.promotions.demonstrationList;
export const selectCurrentDemonstration = (state: RootState) =>
  state.promotions.demonstrationItem;

export default slice.reducer;
