import * as React from 'react';
import { SVGProps } from 'react';

const SmallArrow = (
  props: SVGProps<SVGSVGElement> & {
    direction?: 'left' | 'right' | 'down' | 'up';
  },
) => (
  <div
    style={{
      transform: `rotate(${
        props.direction === 'down'
          ? '90deg'
          : props.direction === 'up'
          ? '-90deg'
          : props.direction === 'left'
          ? '180deg'
          : '0'
      })`,
    }}
  >
    <svg
      width={16}
      height={11}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 11"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m9.587 1.99 1.06-1.06L15.5 5.78l-4.851 4.85-1.06-1.06 3.027-3.027H.502v-1.5H12.64L9.587 1.99Z"
        fill="#282830"
      />
    </svg>
  </div>
);

export default SmallArrow;
